import { Stack, SwipeableDrawerProps } from "@mui/material";
import {
  Box,
  Button,
  ChevronLeft,
  SwipeableDrawer,
  Typography,
} from "../ui-library";

const CustomDrawer = (props: CustomDrawerProps) => {
  const { isInvoiceDrawer, children, ...restProps } = props;

  return (
    <SwipeableDrawer {...restProps}>
      <Box
        role="presentation"
        sx={{
          bgcolor: "#F7F7F7",
          width: 450,
          overflowY: "auto",
          px: 2.5,
          py: 3.75,
          height: "100%",
        }}
        className="drawer_root_container"
        aria-label="drawer-root-content-container"
      >
        <Stack spacing={"10px"} height="100%">
          <Box
            aria-label="drawer-control-section"
            display={"flex"}
            alignItems={"center"}
          >
            <Button
              variant="outlined"
              color="info"
              sx={{ width: "max-content" }}
              onClick={restProps.onClose}
              startIcon={<ChevronLeft />}
            >
              返回
            </Button>

            {isInvoiceDrawer && (
              <Typography
                sx={{ position: "absolute", right: "44%" }}
                textAlign={"center"}
              >
                銷售單
              </Typography>
            )}
          </Box>

          <Box
            aria-label="drawer-content-section"
            flexGrow={1}
            overflow="scroll"
          >
            {children}
          </Box>
        </Stack>
      </Box>
    </SwipeableDrawer>
  );
};

export default CustomDrawer;

// TYPE DECLERATION...
interface CustomDrawerProps extends SwipeableDrawerProps {
  isInvoiceDrawer?: boolean;
}
