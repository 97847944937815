import countryCodes from "country-codes-list";
import { ENV_CONFIG } from "src/config/env.config";
import { imageExtensionRegex } from "src/constants/regex";
import { POInvoiceStatusType } from "src/interfaces/common.interfaces";

export const isValidValue = (val: any) => {
  if (val) return true;
  else return false;
};

// THIS HELP TO VALIDATE DATE AGAINST NOT (NULL, UNDEFINED, EXPTY)...
export const validateData = (data: any) => {
  if (data !== undefined && data !== null && data !== "") return true;
  else return false;
};

export const getPhoneCodeList = () => {
  const phoneObj = countryCodes.all();
  const list = phoneObj.map((obj: any) => {
    return {
      label: `+${obj["countryCallingCode"]}`,
      value: `${obj["countryCallingCode"]}`,
    };
    // return { label: obj["currencyCode"], value: obj["countryCallingCode"] };
  });

  // return list;
  return list.filter((obj) => obj.label === "+852");
};

export const buildFullPhoneNumber = (
  phoneNumber: string,
  countryCode = "852",
) => {
  return `+${countryCode} ${phoneNumber}`;
};

export function getRepeatedValuesFromArray(arr: Array<string>) {
  const count: { [key: string]: number } = {};

  arr.forEach((value) => {
    count[value] = (count[value] || 0) + 1;
  });

  const repeatedValues = Object.keys(count)
    .filter((key) => count[key] > 1)
    .map((key) => key); // convert keys back to numbers if needed

  return repeatedValues;
}

export function getMostRepeatedValueFromArray(arr: Array<string>) {
  const count: { [key: string]: number } = {};
  let mostRepeatedValue = "";
  let maxCount = 0;

  arr.forEach((value) => {
    count[value] = (count[value] || 0) + 1;

    if (count[value] > maxCount) {
      maxCount = count[value];
      mostRepeatedValue = value;
    }
  });

  return mostRepeatedValue;
}

export const getValidObject = (obj: { [key: string]: any }) => {
  const list = Object.keys(obj);
  const resObj = {} as any;

  list.forEach((val) => {
    if (isValidValue(obj[val])) {
      resObj[val] = obj[val];
    }
  });

  return resObj;
};

// HANDLE LOCAL STORAGE...
export const handleLocalStorage = {
  getValue: (key: string) => {
    const val = localStorage.getItem(key) ?? "";

    if (typeof val === "object") {
      return JSON.parse(localStorage.getItem(key) ?? "");
    } else return String(val);
  },

  setValue: (key: string, value: any) => {
    let v = value;
    if (typeof value === "object") {
      JSON.stringify(value);
    }
    localStorage.setItem(key, v);
  },
  removeValue: (key: string) => localStorage.removeItem(key),
};

// MAKE IMAGE URL...
export const generateRemoteMediaUrl = (url?: string | null) => {
  if (url) return `${ENV_CONFIG.API_BASE_URL}${url}`;
  else return "";
};

// IT HELPS TO BUILD QUERY FOR API CALL...
export const buildQuery = (queryObj: { [key: string]: any }) => {
  const keys = Object.keys(queryObj);
  const totalLength = keys.length;
  let query = "?";

  keys.forEach((key: string, ind) => {
    const nextInd = ind + 1;
    const presentObjVal = queryObj[key];
    const nextObjVal = queryObj[keys[nextInd]];

    if (nextInd < totalLength && validateData(presentObjVal)) {
      const q = query + key + "=" + presentObjVal;
      if (validateData(nextObjVal)) query = q + "&";
      else query = q;
    } else if (validateData(presentObjVal)) {
      query = query + key + "=" + presentObjVal;
    }
  });

  return query;
};

export function getFormattedInvoiceDate(dateStr?: string) {
  if (dateStr) {
    const date = new Date(dateStr);
    const montsh = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${year}-${montsh}-${day}`;
  } else {
    return "";
  }
}

export const checkInvoiceStatus = (status?: number): POInvoiceStatusType => {
  if (status === 0) return "Draft";
  else if (status === 1) return "Submitted";
  else if (status === 2) return "Returned";
  else if (status === 3) return "Hold";
  else return "PO Created";
};

//
export interface getDimensionParams {
  width: number;
  height: number;
  length: number;
}
export const getDimension = (params: getDimensionParams) => {
  let d = "";
  if (params.width) d += `${params.width}cm(W)`;
  if (params.length) d += ` x ${params.length}cm(D)`;
  if (params.height) d += ` x ${params.height}cm(H)`;

  return d;
};

export const handleWordingOfAbandonFurniture = (val?: number) => {
  if (Number(val) === 1) return "是";
  else if (Number(val) === 0) return "否";
  else return "-";
};

/**
 * This Function help to build valid object from object by eleminitating null, undefined, blanck value of key from object.
 * @param obj Optional Object type.
 * @returns Valid Object or undefined
 */
export const buildValidObject = (obj?: { [key: string]: any }) => {
  const isValid = (data: any) => {
    return (
      data !== undefined &&
      data !== null &&
      !(Array.isArray(data) && data.length === 0) &&
      !(typeof data === "object" && Object.keys(data).length === 0) &&
      !(typeof data === "string" && data.trim() === "")
    );
  };

  if (!obj || typeof obj !== "object") {
    return undefined; // Return undefined if input is empty or not an object
  }

  const newObject = {} as any;

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      const value = obj[key];

      if (isValid(value)) {
        if (Array.isArray(value)) {
          // Process each element in the array
          const validElements = value
            .filter((elem) => isValid(elem))
            .map(buildValidObject)
            .filter((elem) => isValid(elem));

          if (validElements.length) {
            newObject[key] = validElements;
          }
        } else if (typeof value === "object") {
          // Recursively process nested objects
          const nestedValid = buildValidObject(value);

          if (isValid(nestedValid)) {
            newObject[key] = nestedValid;
          }
        } else {
          newObject[key] = value;
        }
      }
    }
  }

  return Object.keys(newObject).length ? newObject : undefined;
};

// IT HELPS TO GET IMAGE URL WITH API BASE IMAGE URL...
export const getRemoteImageUrl = (url: string) => {
  if (url.includes("http://") || url.includes("https://")) return url;
  return `${ENV_CONFIG.API_BASE_URL}/${url}`;
};

export const getValidImageUrl = (url?: string | null) => {
  if (url && imageExtensionRegex.test(url)) {
    return getRemoteImageUrl(url);
  } else {
    return "/images/NoImage.svg";
  }
};
