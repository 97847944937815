import CreateMember from "src/pages/member/create-member";
import MemberList from "src/pages/member/member-list";

import InvoiceListPage from "src/pages/invoice/Invoice-list";
import InvoiceDetailPage from "src/pages/invoice/invoice-detail";
import PrivateRoute from "./privateRoute";
import EditMember from "src/pages/member/edit-member";
import InvoiceReturnDetailPage from "src/pages/invoice/return/invoice-return-detail";
import SelectProductStep from "src/pages/member/member-product-selection-steps/step2-select-product";
import ProductCouponInfoStep from "src/pages/member/member-product-selection-steps/step3-coupon-info";
import ShippingStep from "src/pages/member/member-product-selection-steps/step4-shipping-info";
import PaymentInfoStep from "src/pages/member/member-product-selection-steps/step5-payment-info";
import CheckInfoStep from "src/pages/member/member-product-selection-steps/step6-check-info";
import SelectSupplierStep from "src/pages/member/member-product-selection-steps/step1-supplier-selection";
import ReportIndexPage from "src/pages/report";

// Desktop Routes...
const appRoutes = [
  {
    path: "/",
    element: <PrivateRoute children={undefined} />,
    children: [
      {
        path: "",
        element: <MemberList />,
      },
      {
        path: "member",
        children: [
          {
            path: "create",
            element: <CreateMember />,
          },
          {
            path: ":id/edit",
            element: <EditMember />,
          },

          {
            path: "product-selection-steps",
            children: [
              {
                path: "select-supplier",
                element: <SelectSupplierStep />,
              },
              {
                path: "select-product",
                element: <SelectProductStep />,
              },
              {
                path: "coupon-info",
                element: <ProductCouponInfoStep />,
              },
              {
                path: "shipping-info",
                element: <ShippingStep />,
              },
              {
                path: "payment-info",
                element: <PaymentInfoStep />,
              },
              {
                path: "check-info",
                element: <CheckInfoStep />,
              },
            ],
          },
        ],
      },
    ],
  },

  {
    path: "/invoice",
    element: <PrivateRoute children={undefined} />,
    children: [
      {
        path: "",
        element: <InvoiceListPage />,
      },
      {
        path: ":invoiceId/detail",
        element: <InvoiceDetailPage />,
      },
      {
        path: ":invoiceId/return-detail",
        element: <InvoiceReturnDetailPage />,
      },
    ],
  },

  {
    path: "/report",
    element: <PrivateRoute children={undefined} />,
    children: [
      {
        path: "",
        element: <ReportIndexPage />,
      },
    ],
  },
];

export default appRoutes;
