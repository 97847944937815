import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "src/config/env.config";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { IAPIResponse } from "src/interfaces/api-response.interfaces";
import { IUserBranch } from "src/interfaces/member";
import { handleLocalStorage } from "src/utils/common";
import { setUser, setUserBranch } from "../features/userSlice";

// USER APIS...
export const userApi = createApi({
  reducerPath: "userApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_CONFIG.API_URL}/`,
    credentials: "include",
    prepareHeaders: async (headers) => {
      const AuthKey = handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY);
      if (AuthKey) {
        headers.set("Authorization", AuthKey);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getMe: builder.query<IAPIResponse<any>, any>({
      query: (b) => {
        return {
          url: "method/frappe.auth.get_logged_user",
        };
      },

      transformResponse: (result: any) => result.data.user,
      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setUser(data));
        } catch (error) {}
      },
    }),

    getApiKey: builder.mutation({
      query: (params) => {
        return {
          url: "method/get_api_key",
          credentials: "include",
          params,
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          handleLocalStorage.setValue(
            USER_RELATED_KEYS.AUTH_KEY,
            // JSON.stringify(`token ${data?.api_key}:${data?.secret}`),
            `token ${data?.api_key}:${data?.secret}`,
          );

          window.location.href = "/";
        } catch (error) {}
      },
    }),

    getBranchName: builder.mutation<IAPIResponse<IUserBranch>, any>({
      query: (b) => {
        return {
          url: "method/get_branch_name",
        };
      },

      async onQueryStarted(args, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          dispatch(setUserBranch({ branchName: data.data.name }));
          handleLocalStorage.setValue(
            USER_RELATED_KEYS.BRANCH_NAME,
            data.data.name,
          );
        } catch (error) {}
      },
    }),
  }),
});

export const { useGetMeQuery, useGetApiKeyMutation, useGetBranchNameMutation } =
  userApi;

// TYPE DECLERATION...
