import { Button, ButtonProps } from "@mui/material";
import React from "react";

const CustLoadingButton = (props: CustLoadingButtonProps) => {
  const { children, loading, loadingIndicator, disabled, ...restProps } = props;

  if (loading) {
    return (
      <Button {...restProps} disabled={true}>
        {loadingIndicator}
      </Button>
    );
  } else {
    return <Button {...restProps}>{children}</Button>;
  }
};

export default CustLoadingButton;

export interface CustLoadingButtonProps extends ButtonProps {
  loading: boolean;
  loadingIndicator: string | React.ReactElement;
}
