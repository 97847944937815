import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CustTextField } from "src/components/form-components/FormInput";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { IPaymentMode } from "src/interfaces/payment-method.interfaces";
import {
  ICreatePaymentEntryPayload,
  IICreatePaymentEntryModeOfPayment,
} from "src/redux/api/saleAndInvoiceApi";
import InvoicePaymentBoxForRefund from "./InvoicePaymentBoxForRefund";

const RefundPaymentModal = (props: AddPaymentModalProps) => {
  const { handleClose, isOpen, handleSubmit, invoiceDetail } = props;

  const stateDefaultData = { paymentMethodList: [], remarks: "" };

  // LOCAL STATES...
  const [paymentData, setPaymentData] = useState<{
    paymentMethodList: Array<IPaymentMode>;
    remarks: string;
  }>(stateDefaultData);
  const [error, setError] = useState("");
  const [isPaymentBTNOn, setIsPaymentBTNOn] = useState(true);
  const [remarksInput, setRemarksInput] = useState("");

  const handleModalSubmit = () => {
    // RE SETTING ERROR TO EMPTY STATE...
    setError("");

    if (
      paymentData.paymentMethodList.length === 0 ||
      paymentData.paymentMethodList[0].mode_of_payment === ""
    ) {
      setError("必须至少选择一项付款");
      return;
    }

    const mode_of_payments: Array<IICreatePaymentEntryModeOfPayment> = [];
    let paid_amount = 0;
    paymentData.paymentMethodList.forEach((item) => {
      paid_amount += item.amount;
      mode_of_payments.push({
        account: "1110 - Cash - FS",
        doctype: "Sales Invoice Payment",
        amount: item.amount,
        mode_of_payment: item.mode_of_payment,
        type: item.type,
      });
    });

    const createPaymentEntryObj = {
      memberId: String(invoiceDetail?.customer.name ?? ""),
      paid_amount: paid_amount,
      invoiceId: String(invoiceDetail?.name ?? ""),
      mode_of_payments: mode_of_payments,
      payment_remarks: remarksInput,
    } as ICreatePaymentEntryPayload;

    handleSubmit(createPaymentEntryObj);
  };

  const handleModalClose = () => {
    setPaymentData(stateDefaultData);
    setError("");
    handleClose();
  };

  return (
    <Dialog open={isOpen} aria-labelledby="add-payment-dialog" maxWidth="lg">
      <Stack spacing={"25px"} p={2.5} width={950}>
        <Stack
          aria-label="header-section"
          direction="row"
          spacing={2.5}
          alignItems={"center"}
          borderBottom={(theme) => `1px solid ${theme.palette.grey[500]}`}
          pb={2.5}
        >
          <Box color={"gray.500"}>
            <Button
              variant="outlined"
              disableElevation
              color="inherit"
              sx={{ p: "14px", borderRadius: "6px" }}
              onClick={handleClose}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Stack direction="row" spacing={"12.8px"} mb={2.5}>
            <Box
              component="img"
              src="/icons/payment-black.svg"
              width={40}
              height={40}
              alt="payment-icon"
            />
            <Typography fontWeight={700} fontSize={28.8}>
              退款
            </Typography>
          </Stack>
        </Stack>

        <DialogContent sx={{ p: 0 }}>
          <InvoicePaymentBoxForRefund
            invoiceDetail={invoiceDetail}
            handleChange={(pMode) => setPaymentData(pMode)}
            // handleChange={(pMode) => console.log("on payment change: ", pMode)}
            isPaymentBTNShowing={setIsPaymentBTNOn}
            border={"none"}
            p={0}
          />
        </DialogContent>

        {/* REMARKS SECTION... */}
        <Box
          aria-labelledby="remarks-section"
          sx={{
            display: isPaymentBTNOn ? "box" : "none",
            p: 2.5,
            borderRadius: "5px",
            border: "1.13px solid #E0E0E0",
          }}
        >
          <CustTextField
            name="remarks"
            label={<Box fontWeight={700}>退款備註</Box>}
            placeholder="退款備註"
            value={remarksInput}
            onChange={(e) => setRemarksInput(e.target.value)}
            // onBlur={() => {
            //   handleChange({
            //     paymentMethodList: selectedPaymentObjList,
            //     remarks: remarksInput,
            //   });
            // }}
            sx={{
              "& .MuiOutlinedInput-input": {
                padding: "10px 120px 10px 20px",
                height: 25,
              },
            }}
            fullWidth
          />
        </Box>

        {/* SHOW PAYMENT METHOD NOT SELECTED ERROR... */}
        {error ? (
          <Typography fontWeight={700} fontSize={18} color="#DC2427">
            {error}
          </Typography>
        ) : null}

        {isPaymentBTNOn ? (
          <Box aria-labelledby="footer-action-section">
            <Divider />

            <DialogActions sx={{ gap: "20px", p: 0, mt: 2.5 }}>
              <Button
                onClick={handleModalClose}
                variant="outlined"
                color="inherit"
                sx={{
                  color: "#5A6070",
                  height: 60,
                  py: "14px",
                  px: "25px",
                  borderRadius: "5px",
                  minWidth: 185,
                }}
              >
                取消
              </Button>

              <Button
                onClick={handleModalSubmit}
                variant="contained"
                sx={{ height: 60, borderRadius: "5px", minWidth: 185 }}
              >
                完成
              </Button>
            </DialogActions>
          </Box>
        ) : null}
      </Stack>
    </Dialog>
  );
};

export default RefundPaymentModal;

// TYPE DECLERATION...
export interface AddPaymentModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (d: ICreatePaymentEntryPayload) => void;
  invoiceDetail: IInvoiceDetail | null;
}
