import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Stack,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { IPaymentMode } from "src/interfaces/payment-method.interfaces";
import InvoicePaymentBox from "./InvoicePaymentBox";
import {
  ICreatePaymentEntryPayload,
  IICreatePaymentEntryModeOfPayment,
} from "src/redux/api/saleAndInvoiceApi";

const AddPaymentModal = (props: AddPaymentModalProps) => {
  const { handleClose, isOpen, handleSubmit, invoiceDetail } = props;

  const stateDefaultData = { paymentMethodList: [], remarks: "" };

  // LOCAL STATES...
  const [paymentData, setPaymentData] = useState<{
    paymentMethodList: Array<IPaymentMode>;
    remarks: string;
  }>(stateDefaultData);
  const [error, setError] = useState("");
  const [isPaymentBTNOn, setIsPaymentBTNOn] = useState(true);

  const handleModalSubmit = () => {
    // RE SETTING ERROR TO EMPTY STATE...
    setError("");

    if (
      paymentData.paymentMethodList.length === 0 ||
      paymentData.paymentMethodList[0].mode_of_payment === ""
    ) {
      setError("必须至少选择一项付款");
      return;
    }

    const mode_of_payments: Array<IICreatePaymentEntryModeOfPayment> = [];
    let paid_amount = 0;
    paymentData.paymentMethodList.forEach((item) => {
      paid_amount += item.amount;
      mode_of_payments.push({
        account: "1110 - Cash - FS",
        doctype: "Sales Invoice Payment",
        amount: item.amount,
        mode_of_payment: item.mode_of_payment,
        type: item.type,
      });
    });

    const createPaymentEntryObj = {
      memberId: String(invoiceDetail?.customer.name ?? ""),
      paid_amount: paid_amount,
      invoiceId: String(invoiceDetail?.name ?? ""),
      mode_of_payments: mode_of_payments,
      payment_remarks: paymentData.remarks,
    } as ICreatePaymentEntryPayload;

    handleSubmit(createPaymentEntryObj);
  };

  const handleModalClose = () => {
    setPaymentData(stateDefaultData);
    setError("");
    handleClose();
  };

  return (
    <Dialog open={isOpen} aria-labelledby="add-payment-dialog" maxWidth="lg">
      <Stack spacing={2.5} p={2.5} width={950}>
        <Box>
          <Stack direction="row" spacing={"12.8px"} mb={2.5}>
            <Box
              component="img"
              src="/icons/payment-black.svg"
              width={40}
              height={40}
              alt="payment-icon"
            />
            <Typography fontWeight={700} fontSize={28.8}>
              新增後加付款
            </Typography>
          </Stack>

          <Divider />
        </Box>

        <DialogContent sx={{ p: 0 }}>
          <InvoicePaymentBox
            invoiceDetail={invoiceDetail}
            handleChange={(pMode) => setPaymentData(pMode)}
            isPaymentBTNShowing={setIsPaymentBTNOn}
            border={"none"}
            p={0}
          />
        </DialogContent>

        {/* SHOW PAYMENT METHOD NOT SELECTED ERROR... */}
        {error ? (
          <Typography fontWeight={700} fontSize={18} color="#DC2427">
            {error}
          </Typography>
        ) : null}

        {isPaymentBTNOn ? (
          <Box aria-labelledby="footer-action-section">
            <Divider />

            <DialogActions
              sx={{ justifyContent: "flex-start", gap: "20px", p: 0, mt: 2.5 }}
            >
              <Button
                onClick={handleModalClose}
                variant="outlined"
                color="inherit"
                sx={{
                  color: "#5A6070",
                  height: 60,
                  py: "14px",
                  px: "25px",
                  borderRadius: "5px",
                }}
              >
                取消
              </Button>

              <Box flexGrow={1}>
                <Button
                  onClick={handleModalSubmit}
                  variant="contained"
                  fullWidth
                  sx={{ height: 60, borderRadius: "5px" }}
                >
                  完成
                </Button>
              </Box>
            </DialogActions>
          </Box>
        ) : null}
      </Stack>
    </Dialog>
  );
};

export default AddPaymentModal;

// TYPE DECLERATION...
export interface AddPaymentModalProps {
  isOpen: boolean;
  handleClose: () => void;
  handleSubmit: (d: ICreatePaymentEntryPayload) => void;
  invoiceDetail: IInvoiceDetail | null;
}
