import { Box, Stack, Typography } from "../../ui-library";

interface ProductSelectionStepBreadcrumbProps {
  active: number;
}

const breadCrumbData = [
  { step: 1, btnText: "供應商" },
  { step: 2, btnText: "產品" },
  { step: 3, btnText: "優惠卷" },
  { step: 4, btnText: "送貨" },
  { step: 5, btnText: "付款" },
];

const ProductSelectionStepBreadcrumb = (
  props: ProductSelectionStepBreadcrumbProps,
) => {
  const { active } = props;

  const getStyle = (step: number) => {
    const style = {
      btnStepStyle: { background: "#bdbdbd" },
      btnTextStyle: { color: "#bdbdbd", fontWeight: 500 },
      strokeStyle: { background: "#bdbdbd" },
    };

    if (step < active) {
      style.btnStepStyle.background = "#5A6070";
      style.btnTextStyle.color = "#5A6070";
      style.btnTextStyle.fontWeight = 700;
      style.strokeStyle.background = "#5A6070";
    }

    if (step === active) {
      style.btnStepStyle.background = "#45A492";
      style.btnTextStyle.color = "#45A492";
      style.btnTextStyle.fontWeight = 700;
      style.strokeStyle.background = "#45A492";
    }

    return style;
  };

  return (
    <Stack
      direction="row"
      spacing="10px"
      aria-label="create-invoice-breadcrumb"
    >
      {breadCrumbData.map((bc) => {
        return (
          <Box
            key={bc.step}
            display="flex"
            alignItems="center"
            aria-label={`create-invoice-breadcrumb-step-${bc.step}`}
            // className={`invoice__breadcrumb-item ${active === bc.step ? "active" : ""}`}
          >
            {bc.step > 1 && (
              <Box
                component="span"
                className="stroke-line"
                sx={{
                  width: "40px",
                  height: "2.223px",
                  ...getStyle(bc.step).strokeStyle,
                }}
              />
            )}

            <Stack spacing="10px" direction="row" alignItems="center">
              <Typography
                component="button"
                sx={{
                  display: "flex",
                  width: "40px",
                  height: "40px",
                  padding: "14px 25px",
                  justifyContent: "center",
                  alignItems: "center",
                  borderRadius: "5px",

                  border: "none",
                  color: "#fff",
                  fontFamily: "Jost",
                  fontSize: "20px",
                  fontWeight: 700,
                  ...getStyle(bc.step).btnStepStyle,
                }}
              >
                {bc.step}
              </Typography>

              <Typography
                variant="h4"
                sx={{
                  fontSize: "18px",
                  lineHeight: "55.578px",
                  ...getStyle(bc.step).btnTextStyle,
                }}
              >
                {bc.btnText}
              </Typography>
            </Stack>
          </Box>
        );
      })}
    </Stack>
  );
};

export default ProductSelectionStepBreadcrumb;
