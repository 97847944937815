import { ENV_CONFIG } from "src/config/env.config";
import { ISupplierProductListItem } from "src/interfaces/suppliers/product.interfaces";
import { getDimension, getValidImageUrl } from "src/utils/common";
import { Box, Stack, Typography } from "../../ui-library";

export interface ProductItemProps {
  product: ISupplierProductListItem;
  // setIsEditItem: (value: boolean) => void;
  handleSelectProduct: (value: ISupplierProductListItem) => void;
}

const ProductItem = (props: ProductItemProps) => {
  // PROPS DESTRUCTURE....
  const {
    product,
    // setIsEditItem,
    handleSelectProduct,
  } = props;

  const handleProductClick = () => {
    // setIsEditItem(false);
    handleSelectProduct(product);
  };

  const getMinValMaxVal = () => {
    const minPrice = Math.trunc(product.min_price || 0);
    const maxPrice = Math.trunc(product.max_price || 0);
    if (minPrice === maxPrice) return `HK$ ${minPrice}`;
    else return `HK$ ${minPrice}-${maxPrice}`;
  };

  return (
    <Stack
      spacing={"12.67px"}
      height={314.1}
      p={"12.67px"}
      bgcolor="#fff"
      borderRadius={"5.94px"}
      border="1.19px solid #BDBDBD"
      onClick={handleProductClick}
      sx={{ cursor: "pointer" }}
    >
      <Box maxWidth={"100%"} maxHeight={167.44} overflow={"hidden"}>
        <img
          style={{ cursor: "pointer", objectFit: "cover" }}
          src={getValidImageUrl(product.custom_template_thumbnail)}
          alt={product.item_name ?? `Product-${Math.random()}`}
          height={"100%"}
          width={"100%"}
          // onError={handleImageError}
        />
      </Box>

      <Box flexGrow={1}>
        <Typography fontSize={14}>{product.name}</Typography>
        <Typography fontWeight={700}>{product.item_name}</Typography>
      </Box>

      <Box>
        <Typography fontSize={11}>
          {getDimension({
            height: product.custom_height,
            length: product.custom_depth,
            width: product.custom_width,
          })}
        </Typography>

        <Typography
          fontWeight={700}
          sx={{
            whiteSpace: "nowrap",
            overflow: "hidden",
            fontSize: "clamp(12px, 3vw, 24px)",
          }}
        >
          {getMinValMaxVal()}
        </Typography>
      </Box>
    </Stack>
  );
};

export default ProductItem;
