import { Divider, Stack } from "@mui/material";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { BagIcon, DocumentIcon, ReportIcon } from "src/assets/icons";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import invoiceRoutes from "src/constants/routes/invoice.routes";
import { handleLocalStorage } from "src/utils/common";
import { useLogoutUserMutation } from "../redux/api/authApi";
import { Box, Typography } from "../ui-library";

const Sidebar = () => {
  const navigate = useNavigate();
  const [logoutUser, { isSuccess }] = useLogoutUserMutation();

  const branchName = handleLocalStorage.getValue(USER_RELATED_KEYS.BRANCH_NAME);

  useEffect(() => {
    if (isSuccess) {
      navigate("/login");
    }
  });

  const handleLogout = () => {
    // removeCookie(COOKIES_KEYS.IS_LOGGED_IN);
    handleLocalStorage.removeValue(USER_RELATED_KEYS.AUTH_KEY);
    logoutUser({});
  };

  return (
    <Box
      display={"flex"}
      flexDirection="column"
      justifyContent={"space-between"}
      alignItems={"center"}
      height={"100%"}
      width={85}
      sx={{ overflow: "hidden", overflowY: "auto" }}
      py="30px"
      px="12px"
      bgcolor="rgba(247, 247, 247, 0.97)"
    >
      <Stack
        spacing={5}
        width={"100%"}
        justifyContent="center"
        alignItems="center"
      >
        <img
          // src={logo}
          src={"/icons/logo.svg"}
          alt="furniture-station-logo"
          width={"43px"}
          height={"36.159px"}
          onClick={() => navigate("/")}
        />

        <Stack
          spacing={"15px"}
          justifyContent="center"
          alignItems="center"
          width="100%"
        >
          <Stack spacing="15px" width="100%">
            <NavLink
              to="/"
              className={({ isActive }) =>
                `sidebar_menu_btn ${isActive ? "sidebar_menu_btn_active" : ""}`
              }
            >
              <BagIcon sx={{ color: "#e26f71" }} />
            </NavLink>

            <Divider variant="fullWidth" />
          </Stack>

          <Stack spacing="15px" width="100%">
            <NavLink
              to={invoiceRoutes.base}
              className={({ isActive }) =>
                `sidebar_menu_btn ${isActive ? "sidebar_menu_btn_active" : ""}`
              }
            >
              <DocumentIcon sx={{ color: "#e26f71" }} />
            </NavLink>

            <Divider variant="fullWidth" />
          </Stack>

          <Stack spacing="15px" width="100%">
            <NavLink
              to="/report"
              className={({ isActive }) =>
                `sidebar_menu_btn ${isActive ? "sidebar_menu_btn_active" : ""}`
              }
            >
              <ReportIcon sx={{ color: "#e26f71", fontSize: "1.8rem" }} />
            </NavLink>

            <Divider variant="fullWidth" />
          </Stack>

          <Box textAlign="center">
            <Box
              onClick={handleLogout}
              textAlign="center"
              p="13px"
              sx={{ cursor: "pointer" }}
            >
              <img
                src="/icons/sidebar-logout.svg"
                alt="logout"
                width={20.9}
                style={{ objectFit: "contain" }}
              />
            </Box>
          </Box>
        </Stack>
      </Stack>

      <Typography className="sidebar__branch-name">{branchName}</Typography>
    </Box>
  );
};

export default Sidebar;
