/* eslint-disable react/jsx-props-no-spreading */
import {
  TextFieldProps as MuiTextFieldProps,
  Stack,
  StackOwnProps,
} from "@mui/material";
import React, { FC } from "react";
import { useController } from "react-hook-form";
import {
  Box,
  FormHelperText,
  InputAdornment,
  InputLabel,
  MuiTextField,
  Visibility,
  VisibilityOff,
} from "../../ui-library";

type InputProps = {
  control: any;
  label: string;
  type?: string;
  name: string;
  placeholder?: string;
  error?: { message?: string };
  rules?: {};
  isPasswordVisible?: boolean;
  handlePasswordVisibility?: () => void;
};

const FormInput = (props: InputProps) => {
  const {
    label,
    type,
    placeholder,
    error,
    isPasswordVisible,
    handlePasswordVisibility,
  } = props;
  const { field, fieldState } = useController(props);

  return (
    <Box className="form-input-group">
      <InputLabel>{label}</InputLabel>
      <MuiTextField
        fullWidth
        {...field}
        placeholder={placeholder}
        type={type || "text"}
        InputProps={
          field.name === "pwd"
            ? {
                endAdornment: (
                  <InputAdornment
                    sx={{ cursor: "pointer" }}
                    position="end"
                    onClick={handlePasswordVisibility}
                  >
                    {isPasswordVisible ? <VisibilityOff /> : <Visibility />}
                  </InputAdornment>
                ),
              }
            : undefined
        }
      />
      {fieldState.invalid && error && (
        <FormHelperText sx={{ marginTop: 0, color: "red" }}>
          {error?.message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default FormInput;

interface CustTextFieldCustProps {
  wrapperStackProps?: StackOwnProps;
}
type CustTextFieldProps = MuiTextFieldProps & CustTextFieldCustProps;

export const CustTextField: FC<CustTextFieldProps> = ({
  wrapperStackProps,
  label,
  error,
  helperText,
  ...rest
}) => {
  return (
    <Stack
      spacing={wrapperStackProps?.spacing ?? "8.83px"}
      {...wrapperStackProps}
    >
      {label ? <InputLabel>{label}</InputLabel> : null}

      <MuiTextField {...rest} error={error} />

      {error && helperText ? (
        <FormHelperText sx={{ marginTop: 0, color: "red" }}>
          {helperText}
        </FormHelperText>
      ) : null}
    </Stack>
  );
};

export const PasswordTextField: FC<CustTextFieldProps> = ({
  wrapperStackProps,
  label,
  error,
  helperText,
  ...rest
}) => {
  const [showPassword, setShowPassword] = React.useState(false);

  return (
    <Stack
      spacing={wrapperStackProps?.spacing ?? "8.83px"}
      {...wrapperStackProps}
    >
      {label ? <InputLabel>{label}</InputLabel> : null}

      <MuiTextField
        {...rest}
        error={error}
        type={showPassword ? "text" : "password"}
        InputProps={{
          endAdornment: (
            <InputAdornment
              sx={{ cursor: "pointer" }}
              position="end"
              onClick={() => setShowPassword(!showPassword)}
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </InputAdornment>
          ),
        }}
      />

      {error && helperText ? (
        <FormHelperText sx={{ marginTop: 0, color: "red" }}>
          {helperText}
        </FormHelperText>
      ) : null}
    </Stack>
  );
};
