import React from "react";
import Sidebar from "./Sidebar";
import { Box } from "../ui-library";

const RootLayout = ({ children }: RootLayoutProps) => {
  return (
    <Box aria-label="root-layout" display="flex" height="100vh" width="100%">
      <Box aria-label="root-sidebar-section" height={"100%"}>
        <Sidebar />
      </Box>

      <Box
        aria-label="root-content-section"
        flexGrow={1}
        p={"30px"}
        minHeight={"100%"}
        sx={{ overflowY: "auto" }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RootLayout;

// TYPE DECLERATION
interface RootLayoutProps {
  children: React.ReactElement | React.ReactElement[];
}
