import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddPaymentConfirmModal from "src/components/invoice/AddPaymentConfirmModal";
import AddPaymentModal from "src/components/invoice/AddPaymentModal";
import EditAddressModal from "src/components/invoice/EditAddressModal";
import {
  ManagerCredentialsForReturnDialog,
  SaleReturnStatusDialog,
  SelectReturnPersonDialog,
} from "src/components/invoice/InvoiceReturnDialogs";
import LoadingModal from "src/components/loader/LoadingModal";
import { INVOICE_CREATION_STEPS_KEYS } from "src/constants/keys/local-storage.keys";
import invoiceRoutes from "src/constants/routes/invoice.routes";
import RootLayout from "src/layout/Layout";
import {
  IConfirmInvoiceWithPaymentAPIPayload,
  ICreatePaymentEntryPayload,
  useConfirmInvoiceWithPaymentMutation,
  useGetInvoiceByIdMutation,
  useHoldSalesInvoiceMutation,
  useReturnSaleInvoiceMutation,
  useUnholdSalesInvoiceMutation,
} from "src/redux/api/saleAndInvoiceApi";
import { setIsInvoiceDrawerOpen } from "src/redux/features/invoiceSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";
import { StyledAddPaymentButton } from "src/styles/StyledButtons";
import {
  generateRemoteMediaUrl,
  handleLocalStorage,
  handleWordingOfAbandonFurniture,
} from "src/utils/common";
import { printInvoiceIntoBrowser } from "src/utils/print-invoice";
import {
  Box,
  Button,
  Checkbox,
  ChevronLeft,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "../../ui-library";
import CustLoadingButton from "src/components/form-components/CustLoadingButton";

const InvoiceDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoiceId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const isConfirmed = searchParams.get("isConfirmed");

  // LOCAL STATES...
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isPaymentModalOpen, setIsPaymentModalOpen] = useState(false);
  const [isAddPaymentConfirmModalOpen, setIsAddPaymentConfirmModalOpen] =
    useState(false);
  const [addPaymentPayload, setAddPaymentPayload] =
    useState<ICreatePaymentEntryPayload>();
  const [isAddressModalOpen, setIsAdressModalOpen] = useState(false);
  const [isNeedHoldInvoice, setIsNeedHoldInvoice] = useState(false);
  const [isNeedHoldInvoiceDraft, setIsNeedHoldInvoiceDraft] = useState(false);
  const [returnPersonDialogConfig, setReturnPersonDialogConfig] = useState({
    open: false,
    sale_person: "",
  });
  const [saleManagerCredDialogConfig, setSaleManagerCredDialogConfig] =
    useState({
      open: false,
      is_success: true,
      message: {} as any,
    });
  const [saleReturnStatusDialog, setSaleReturnStatusDialog] = useState(false);

  // REDUX THINGS..
  const [getInvoiceById, { data: invoiceData, isLoading: getInvoiceLoading }] =
    useGetInvoiceByIdMutation();
  const [
    confirmInvoiceWithPayment,
    { isSuccess: addPaymentSuccess, isLoading: addPaymentLoading },
  ] = useConfirmInvoiceWithPaymentMutation();

  const [
    holdSalesInvoice,
    { isError: isHoldInvoiceError, isLoading: isHoldLoading },
  ] = useHoldSalesInvoiceMutation();

  const [
    unholdSalesInvoice,
    { isError: isunholdInvoiceError, isLoading: isUnHoldLoading },
  ] = useUnholdSalesInvoiceMutation();

  const [
    returnSaleInvoice,
    {
      // isLoading: isSaleInvoiceReturnLoading,
      isSuccess: isSaleInvoiceReturnSuccess,
      isError: isSaleInvoiceReturnError,
      error: saleInvoiceReturnError,
    },
  ] = useReturnSaleInvoiceMutation();

  // INIT STATE CHECKS...
  useEffect(() => {
    if (invoiceId) {
      getInvoiceById({ invoice_name: invoiceId });
    }

    if (isConfirmed && String(isConfirmed) === "true") {
      setIsSuccessModalOpen(true);

      // remove previously save Payment info into localStorage
      handleLocalStorage.removeValue(INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO);
    }

    if (addPaymentSuccess) setAddPaymentPayload(undefined);
  }, [invoiceId, isConfirmed, addPaymentSuccess]);

  useEffect(() => {
    if (invoiceData) {
      setIsNeedHoldInvoice(
        invoiceData.data.custom_is_hold === 1 ? true : false,
      );
    }
  }, [invoiceData]);

  // UPDATE ISHOLD STATE ACCORDING TO RESPONSE...
  useEffect(() => {
    if (isHoldInvoiceError || isunholdInvoiceError) {
      // rollback to previous state
      setIsNeedHoldInvoice(isNeedHoldInvoiceDraft);
    }
  }, [isunholdInvoiceError, isHoldInvoiceError]);

  // handle things when invoice return is success...
  useEffect(() => {
    setSaleManagerCredDialogConfig((oldState) => {
      return {
        ...oldState,
        open: false,
        message: JSON.stringify(saleInvoiceReturnError ?? "{}", null, 2),
        is_success: isSaleInvoiceReturnSuccess ? true : false,
      };
    });

    if (isSaleInvoiceReturnSuccess) {
      getInvoiceById({ invoice_name: String(invoiceId) });
    }

    if (isSaleInvoiceReturnSuccess || isSaleInvoiceReturnError) {
      setSaleReturnStatusDialog(true);
    }
  }, [
    isSaleInvoiceReturnSuccess,
    isSaleInvoiceReturnError,
    saleInvoiceReturnError,
  ]);

  // HANDLE INVOICE HOLD AND UNHOLD API CALL...
  const handleInvoiceHold = (checked: boolean) => {
    if (invoiceId) {
      setIsNeedHoldInvoiceDraft(isNeedHoldInvoice);
      setIsNeedHoldInvoice(checked);
      if (checked === true) holdSalesInvoice({ invoice_name: invoiceId });
      else unholdSalesInvoice({ invoice_name: invoiceId });
    }
  };

  // HANDLE ADD PAYMENT ENTRY MODAL SUBMIT...
  const handleAddPaymentSubmit = (payload: ICreatePaymentEntryPayload) => {
    setIsPaymentModalOpen(false);
    setAddPaymentPayload(payload);
    setIsAddPaymentConfirmModalOpen(true);
  };

  // ADD PAYMENT CONFIRM MODAL SUBMIT HANDLE...
  const handleAddPaymentConfirmSubmit = (isConfirm: boolean) => {
    if (isConfirm === true && addPaymentPayload) {
      // createPaymentEntry(addPaymentPayload);
      const payload = {
        invoice_name: String(addPaymentPayload.invoiceId),
        mode_of_payments: addPaymentPayload.mode_of_payments.map((item) => {
          return {
            doctype: "Sales Invoice Payment",
            amount: item.amount,
            mode_of_payment: item.mode_of_payment,
            type: item.type,
          };
        }),
        custom_payment_remarks: addPaymentPayload.payment_remarks,
      } as IConfirmInvoiceWithPaymentAPIPayload;

      confirmInvoiceWithPayment(payload);
    }
    setIsAddPaymentConfirmModalOpen(false);
  };

  // HANDLE ADD EDIT ADDRESS...
  const handleEditAddressModalSubmit = (isSuccess: boolean) => {
    if (isSuccess) {
      getInvoiceById({ invoice_name: String(invoiceId) });
    }
    setIsAdressModalOpen(false);
  };

  const handleInvoiceDrawerClose = () => {
    dispatch(setIsInvoiceDrawerOpen(false));
    navigate(invoiceRoutes.base);
  };

  // HANDLE PRINT INVOICE...
  const handlePrintInvoice = async () => {
    setIsLoading(true);
    if (invoiceData && invoiceData.data && invoiceData.data.name) {
      await printInvoiceIntoBrowser(invoiceData.data);
    }
    setIsLoading(false);
  };

  // HANDLE IMAGE LOAD ERROR
  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  return (
    <RootLayout>
      <Stack spacing={2.5}>
        <Box aria-label="header-section" display="flex" alignItems="center">
          <StyledHeaderBackButton
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={handleInvoiceDrawerClose}
          >
            返回
          </StyledHeaderBackButton>

          <Box flexGrow={1} aria-labelledby="invoice-name">
            <Stack
              spacing={"10px"}
              direction="row"
              justifyContent="center"
              alignItems="center"
            >
              <Box
                component="img"
                src="/icons/docs.svg"
                width={40}
                height={40}
                alt="doc icon"
              />

              <Typography fontSize={"30px"} fontWeight={700}>
                {`銷售單#${invoiceData?.data?.name ?? ""} - ${
                  invoiceData?.data?.posting_date ?? ""
                }`}
              </Typography>
            </Stack>
          </Box>

          <Box aria-labelledby="hold-button-box-wraper">
            {invoiceData?.data?.has_purchase_order === true ? (
              <Box
                bgcolor="#F7F7F7"
                borderRadius="5px"
                color="#5A6070"
                border="1px solid #f0f0f0"
                padding={"15px"}
              >
                已建立PO
              </Box>
            ) : (
              <Box
                sx={{
                  "& .form_input_checkbox": {
                    marginRight: 0,
                    padding: "15px",
                    borderRadius: "5px",
                    border: `1px solid ${
                      isNeedHoldInvoice ? "transparent" : "#BDBDBD"
                    }`,
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="need_hold_order"
                      value={isNeedHoldInvoice}
                      onChange={(e, checked) => {
                        handleInvoiceHold(checked);
                      }}
                      sx={{
                        color: isNeedHoldInvoice
                          ? "#FFF !important"
                          : "#828282",
                        "&.Mui-checked": {
                          color: isNeedHoldInvoice ? "#FFF" : "#828282",
                        },
                      }}
                    />
                  }
                  checked={isNeedHoldInvoice}
                  label="需要HOLD單"
                  sx={{
                    marginRight: 0,
                    padding: "15px",
                    borderRadius: "5px",
                    border: `1px solid ${
                      isNeedHoldInvoice ? "transparent" : "#BDBDBD"
                    }`,
                    bgcolor: isNeedHoldInvoice ? "#45A492" : "#fff",
                    color: isNeedHoldInvoice ? "#FFF !important" : "#5A6070",
                  }}
                />
              </Box>
            )}
          </Box>
        </Box>

        {isNeedHoldInvoice === true ? (
          <Typography
            textAlign="center"
            color="#F2994A"
            fontSize={30.7}
            fontWeight={700}
          >
            HOLD單
          </Typography>
        ) : null}
      </Stack>

      <Box
        className="check__info__page__content checkout__process"
        display="flex"
        flexDirection="column"
        gap="20px"
        marginTop="20px"
      >
        {invoiceData?.data?.status === 0 && (
          <Typography
            sx={{
              color: "#3742F9",
              fontSize: " 30.688px",
              fontWeight: "700",
              textAlign: "center",
            }}
          >
            草稿
          </Typography>
        )}

        {/* SALE PERSON INFORMATION SECTION... */}
        <Box className="salesperson__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/sale-girl.svg"
                alt="sale-girl"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              銷售員信息
            </Typography>
          </Stack>
          <Box
            padding="30px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <InputLabel>主銷售員</InputLabel>
                <Typography>
                  {invoiceData?.data?.sales_team?.[0]?.sales_person_name}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>其他銷售員</InputLabel>
                <Typography>
                  {invoiceData?.data?.sales_team?.[1]?.sales_person_name}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* PRODUCT INFORMATION SECTION... */}
        <Box className="product__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/info.svg"
                alt="sale-girl"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              商品信息
            </Typography>
          </Stack>

          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            padding="20px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
            className="MuiGrid-item"
          >
            <Stack direction="row" alignItems="center">
              <InputLabel>供應商</InputLabel>&nbsp;
              <Typography sx={{ padding: "0px !important" }}>
                {invoiceData?.data?.supplier || "-"}
              </Typography>
            </Stack>
            <Box aria-label="product-table">
              <Stack
                aria-label="header-and-data-section"
                spacing={5}
                sx={{
                  border: "1.125px solid #E0E0E0",
                  borderRadius: "5px",
                  padding: "20px 12px",
                }}
              >
                <Grid
                  width={"100%"}
                  container
                  aria-label="table-header"
                  columnSpacing={3}
                  sx={{
                    height: 70,
                    fontWeight: 500,
                    fontSize: 20,
                    borderBottom: "1px solid #BDBDBD",
                    py: "10px",
                    "& .table_header": {
                      "&:not(:last-child)": {
                        borderRight: "1px solid #BDBDBD",
                      },
                    },
                  }}
                >
                  <Grid item xs={6} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      商品資料
                    </Box>
                  </Grid>

                  <Grid item xs={2} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      單價(HKD)
                    </Box>
                  </Grid>

                  <Grid item xs={2} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      折扣(HKD)
                    </Box>
                  </Grid>

                  <Grid item xs={2} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      金額(HKD)
                    </Box>
                  </Grid>
                </Grid>

                {/* START: Table content (Product) */}
                {invoiceData?.data?.items?.map((product, index: number) => (
                  <Grid
                    key={`invoice-item-${index}`}
                    width={"100%"}
                    container
                    columnSpacing={3}
                    aria-label={`product-item-section`}
                    sx={{
                      "& .MuiGrid-item": {
                        "&:not(:last-child)": {
                          borderRight: "1px solid #BDBDBD",
                        },
                      },
                    }}
                  >
                    <Grid item xs={6}>
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="20px"
                        aria-label="invoice-product-item"
                      >
                        <img
                          src={generateRemoteMediaUrl(
                            product.custom_template_thumbnail,
                          )}
                          alt={product.item_name ?? "product image"}
                          width={158.625}
                          style={{ objectFit: "contain" }}
                          onError={handleImageError}
                        />

                        <Stack gap="10px">
                          <Box display="flex" flexDirection="column" gap="5px">
                            <Typography
                              sx={{
                                padding: "0 !important",
                              }}
                            >
                              {product?.item_code || "-"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "20px !important",
                                fontWeight: "700 !important",
                                padding: "0 !important",
                              }}
                            >
                              {product?.item_name || "-"}
                            </Typography>
                            <Typography
                              sx={{
                                padding: "0 !important",
                              }}
                            >
                              {product.custom_width ? (
                                <span>{product.custom_width}cm(W)</span>
                              ) : null}
                              {product.custom_height ? (
                                <span> x {product.custom_height}cm(H)</span>
                              ) : null}
                              {product.custom_size_remarks ? (
                                <span>[{product.custom_size_remarks}]</span>
                              ) : null}
                            </Typography>
                          </Box>
                          {/* <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "18px !important",
                            }}
                          >
                            {product?.custom_item_remarks || "-"}
                          </Typography> */}
                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "18px !important",
                            }}
                          >
                            {product?.sales_invoice_custom_item_remarks || "-"}
                          </Typography>
                          {product.attributes.length ? (
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                padding: "8px 0px",
                              }}
                            >
                              {product.attributes.map((attr, index: number) => {
                                return (
                                  <Typography
                                    id={attr.attribute}
                                    key={`product-attribute-${index}`}
                                    sx={{
                                      display: "inline-flex",
                                      flexDirection: "row",
                                      padding: "0 !important",
                                      fontSize: "15px !important",
                                    }}
                                  >
                                    <Box component="span">
                                      {attr.attribute}:{" "}
                                    </Box>
                                    <Box component="span" fontWeight={400}>
                                      {attr.attribute_value}
                                    </Box>
                                  </Typography>
                                );
                              })}
                            </Box>
                          ) : (
                            <Typography
                              sx={{
                                padding: "8px 0 !important",
                                fontSize: "15px !important",
                                fontWeight: "500 !important",
                              }}
                            >
                              產品沒有選項
                            </Typography>
                          )}

                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "20px !important",
                              fontWeight: "700 !important",
                            }}
                          >
                            x{product?.qty || 1}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                      >
                        <Typography
                          sx={{
                            padding: "0 !important",
                            fontSize: "14px !important",
                            fontWeight: "500 !important",
                            textDecorationLine: "line-through",
                          }}
                        >
                          $ {product?.custom_original_price || null}
                        </Typography>

                        <Typography
                          sx={{
                            padding: "0 !important",
                            fontSize: "14px !important",
                            fontStyle: "italic !important",
                            color: "#DC2427 !important",
                          }}
                        >
                          {product?.custom_markup_discount}% off
                        </Typography>

                        <InputLabel>$ {product?.rate}</InputLabel>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        {product.applied_coupons.length ? (
                          <Stack spacing={1}>
                            {product.applied_coupons.map((d) => {
                              return (
                                <Box key={d.coupon_code}>
                                  <Typography
                                    sx={{
                                      padding: "0 !important",
                                      fontSize: "20px !important",
                                      fontWeight: "700 !important",
                                    }}
                                  >
                                    -$ {d.amount}
                                  </Typography>
                                  <Button
                                    sx={{
                                      background: "#828282",
                                      color: "#fff !important",
                                    }}
                                    disabled
                                  >
                                    {d.coupon_name}
                                  </Button>
                                </Box>
                              );
                            })}
                          </Stack>
                        ) : (
                          <>-</>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography
                          sx={{
                            padding: "0 !important",
                            fontSize: "24px !important",
                            fontWeight: "700 !important",
                          }}
                        >
                          {product.custom_discounted_amount}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
                {/* END: Table content (Product) */}
              </Stack>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              padding="30px 20px 20px 20px"
              border="1.13px solid #E0E0E0"
              borderRadius="5px"
            >
              <Stack direction="row" justifyContent="space-between">
                <InputLabel>產品累計</InputLabel>
                <Typography sx={{ padding: "0px !important" }}>
                  ${" "}
                  {invoiceData?.data?.custom_total_item_discounted_amount ||
                    "-"}
                </Typography>
              </Stack>
              {invoiceData?.data?.applied_coupons?.map(
                (coupon, index: number) => (
                  <Stack
                    key={`applied-coupon-${index}`}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <InputLabel>全單折扣</InputLabel>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{ background: "#828282", color: "#fff !important" }}
                        disabled
                      >
                        {coupon?.coupon_name}
                      </Button>
                      <Typography sx={{ padding: "0px !important" }}>
                        -$ {coupon?.amount}
                      </Typography>
                    </Box>
                  </Stack>
                ),
              )}
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <InputLabel>全單總計</InputLabel>
                <Typography sx={{ padding: "0px !important" }}>
                  $ {invoiceData?.data?.rounded_total}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>

        {/* PAYMENT INFORMATION SECTION... */}
        <Box className="payment__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/payment-card.svg"
                alt="payment-card"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              付款信息
            </Typography>
          </Stack>

          <Stack
            spacing={2.5}
            padding="20px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              padding="30px 20px 20px 20px"
              borderRadius="5px"
              border="1px solid #E0E0E0"
              className="MuiGrid-item"
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ padding: "0px !important" }}>
                  全單總計
                </Typography>
                <InputLabel>$ {invoiceData?.data?.rounded_total}</InputLabel>
              </Stack>

              {/* payment list  */}
              <Divider />
              {invoiceData?.data?.payments &&
                invoiceData.data.payments.map((payment, index) => (
                  <Fragment key={`payment-${index}`}>
                    <Stack direction="row">
                      <Typography sx={{ padding: "0px !important" }}>
                        <Box component={"span"} mr={1}>
                          付款於
                        </Box>
                        <Box component={"span"}>{payment?.date ?? "-"}</Box>
                      </Typography>
                    </Stack>
                    <Stack direction="row" justifyContent="space-between">
                      <Button
                        sx={{
                          background: "#828282",
                          color: "#fff !important",
                        }}
                        disabled
                      >
                        {payment.mode_of_payment ?? "-"}
                      </Button>
                      <InputLabel>
                        -$ {payment?.total_allocated_amount ?? 0}
                      </InputLabel>
                    </Stack>

                    <Stack direction="row" alignItems="center">
                      <InputLabel>備註 : </InputLabel>&nbsp;
                      <Typography sx={{ padding: "0px !important" }}>
                        {payment.custom_payment_remarks || "-"}
                      </Typography>
                    </Stack>
                  </Fragment>
                ))}

              {/* transfer details... */}
              {invoiceData?.data?.transfer_payments.map((payment, index) => (
                <Stack spacing={2.5} key={`transfer-payment-${index}`} pt={3}>
                  <Stack direction="row">
                    <Typography sx={{ padding: "0px !important" }}>
                      <Box component={"span"} mr={1}>
                        轉單於
                      </Box>
                      <Box component={"span"}>
                        {payment?.date} (
                        {payment.custom_handler_custom_sales_code}{" "}
                        {payment.custom_handler_name})
                      </Box>
                    </Typography>
                  </Stack>

                  <Stack direction="row" justifyContent="space-between">
                    <Button
                      sx={{
                        background: "#45A492",
                        color: "#fff !important",
                      }}
                      disabled
                    >
                      {payment.invoice_name ?? "-"}
                    </Button>
                    <InputLabel>-$ {payment?.amount ?? 0}</InputLabel>
                  </Stack>

                  {payment?.custom_journal_notes ? (
                    <Stack direction="row" alignItems="center">
                      <InputLabel>備註 : </InputLabel>&nbsp;
                      <Typography sx={{ padding: "0px !important" }}>
                        {payment.custom_journal_notes || "-"}
                      </Typography>
                    </Stack>
                  ) : null}
                </Stack>
              ))}

              {/* total remaining section.. */}
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ padding: "0px !important" }}>餘額</Typography>
                <InputLabel>
                  $ {invoiceData?.data?.outstanding_total}
                </InputLabel>
              </Stack>
            </Box>

            <StyledAddPaymentButton
              variant={"contained"}
              fullWidth
              startIcon={<AddCircleIcon />}
              onClick={() => setIsPaymentModalOpen(true)}
            >
              新增付款方式
            </StyledAddPaymentButton>
          </Stack>
        </Box>

        {/* MEMBER INFORMATION SECTION... */}
        <Box className="member__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/account-box.svg"
                alt="account-box"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              會員
            </Typography>
          </Stack>
          <Box
            padding="30px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Grid container rowGap="20px">
              <Grid item xs={6}>
                <InputLabel>會員編號</InputLabel>
                <Typography>{invoiceData?.data?.customer?.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>生日月份</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.custom_birth_month}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>稱謂</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.salutation || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>姓名</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.customer_name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>電郵地址</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.custom_email || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>手機號碼</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.custom_unique_phone || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* SHIPPING INFORMATION SECTION... */}
        <Box className="shipping__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/shipping-truck.svg"
                alt="shipping-truck"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              送貨資料
            </Typography>
          </Stack>

          <Stack
            spacing={2.5}
            padding="30px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Button
              variant="contained"
              disableElevation
              startIcon={<EditIcon />}
              sx={{ width: "max-content", p: "10px 25px" }}
              onClick={() => {
                setIsAdressModalOpen(true);
              }}
            >
              編輯地址
            </Button>
            <Grid container rowGap="20px">
              <Grid item container>
                <Grid item xs={3}>
                  <InputLabel>收貨日期</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_delivery_date || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>需要棄置舊傢具</InputLabel>
                  <Typography>
                    {handleWordingOfAbandonFurniture(
                      invoiceData?.data?.delivery?.custom_discard_old_furniture,
                    )}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>棄置產品費(HK$)</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_discard_fee || "-"}
                  </Typography>
                </Grid>

                <Grid item xs={3}>
                  {" "}
                </Grid>

                <Grid item xs={12} my={2.5}>
                  <Divider />
                </Grid>
              </Grid>

              <Grid item xs={3}>
                <InputLabel>收貨人姓名</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_recipient_name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>收貨人電話</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_phone || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>收貨人其他電話</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_second_phone || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>區域</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_region || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>地區</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_district || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>街道號碼及屋苑大廈</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_address_line1 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>樓層及單位</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_address_line2 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>上樓費(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_stairs_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>推路費(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_cart_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>偏遠地區(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_remote_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>地址備註</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_address_remarks || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </Box>

        {/* INFO CONTROL SECTION... */}
        <CustLoadingButton
          loading={isLoading}
          variant="contained"
          onClick={handlePrintInvoice}
          loadingIndicator={
            <Stack spacing={2} direction={"row"} alignItems={"center"}>
              <Typography>列印帳單</Typography>
              <CircularProgress size={25} />
            </Stack>
          }
        >
          列印帳單
        </CustLoadingButton>

        {/* HANDLE RETURN PROCESS... */}
        <Button
          className=""
          variant="outlined"
          onClick={() =>
            setReturnPersonDialogConfig((prevState) => {
              return { ...prevState, open: true };
            })
          }
        >
          退單
        </Button>
      </Box>

      <SubmitInvoiceDialog
        handleClose={() => {
          setIsSuccessModalOpen(false);
          setSearchParams("isConfirmed=false");
        }}
        isModalOpen={isSuccessModalOpen}
      />

      <AddPaymentModal
        isOpen={isPaymentModalOpen}
        invoiceDetail={invoiceData?.data ?? null}
        handleClose={() => setIsPaymentModalOpen(false)}
        handleSubmit={handleAddPaymentSubmit}
      />

      <AddPaymentConfirmModal
        open={isAddPaymentConfirmModalOpen}
        handleClose={handleAddPaymentConfirmSubmit}
      />

      {/* EDIT SHIPOING ADDRESS MODAL... */}
      <EditAddressModal
        open={isAddressModalOpen}
        invoice={invoiceData ? invoiceData.data : undefined}
        handleModalClose={handleEditAddressModalSubmit}
      />

      <SelectReturnPersonDialog
        open={returnPersonDialogConfig.open}
        handleModalClose={() => {
          setReturnPersonDialogConfig((prevVal) => {
            return { ...prevVal, open: false };
          });
        }}
        onSelectReturnPerson={(person) => {
          setSaleManagerCredDialogConfig((oldState) => {
            return { ...oldState, open: true };
          });
          setReturnPersonDialogConfig({ open: false, sale_person: person });
        }}
      />

      <ManagerCredentialsForReturnDialog
        open={saleManagerCredDialogConfig.open}
        handleModalClose={() => {
          setSaleManagerCredDialogConfig((oldState) => {
            return { ...oldState, open: false };
          });
        }}
        onSubmit={(managerCred) => {
          returnSaleInvoice({
            custom_return_handler: returnPersonDialogConfig.sale_person,
            invoice_name: String(invoiceId),
            usr: managerCred.usr,
            pwd: managerCred.pwd,
          });
        }}
      />

      <SaleReturnStatusDialog
        open={saleReturnStatusDialog}
        invoice_id={String(invoiceId)}
        is_success={saleManagerCredDialogConfig.is_success}
        message={saleManagerCredDialogConfig.message}
        handleModalClose={() => {
          setSaleReturnStatusDialog(false);
        }}
      />

      <LoadingModal
        open={
          addPaymentLoading ||
          getInvoiceLoading ||
          isUnHoldLoading ||
          isHoldLoading
        }
      />
    </RootLayout>
  );
};

export default InvoiceDetailPage;

// HELPER FUNCTIONS...
type SubmitInvoiceDialogProps = {
  isModalOpen: boolean;
  handleClose: () => void;
};

const SubmitInvoiceDialog = ({
  isModalOpen,
  handleClose,
}: SubmitInvoiceDialogProps) => {
  return (
    <Dialog open={isModalOpen}>
      <DialogContent
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "20.04px",
          padding: "40px 77.68px",
        }}
      >
        <img
          src="/icons/order-approve.svg"
          alt="order-play"
          width={60}
          height={60}
          style={{ objectFit: "contain" }}
        />

        <Typography
          variant="h2"
          sx={{ fontSize: "20px", fontWeight: "600", color: "#333" }}
        >
          成功建立訂單
        </Typography>

        <Button
          variant="contained"
          sx={{ padding: "15px 40px" }}
          onClick={handleClose}
        >
          完成
        </Button>
      </DialogContent>
    </Dialog>
  );
};
