import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedSupplierId: null,
  hasInvoiceItem: false,
  isInvoiceDrawerOpen: false,
  invoiceStatus: "",
  searchKeyword: "",
  invoiceStartDate: null,
  invoiceEndDate: null,
  page: 0,
  rowsPerPage: 10,
  invoiceOrderBy: "posting_date DESC",
};

export const invoiceSlice = createSlice({
  initialState,
  name: "invoiceSlice",
  reducers: {
    setSelectedSupplierId: (state, action) => {
      state.selectedSupplierId = action.payload;
    },
    setHasInvoiceItem: (state, action) => {
      state.hasInvoiceItem = action.payload;
    },
    setIsInvoiceDrawerOpen: (state, action) => {
      state.isInvoiceDrawerOpen = action.payload;
    },
    setInvoiceStatus: (state, action) => {
      state.invoiceStatus = action.payload;
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    },
    setInvoiceStartDate: (state, action) => {
      state.invoiceStartDate = action.payload;
    },
    setInvoiceEndDate: (state, action) => {
      state.invoiceEndDate = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setInvoiceOrderBy: (state, action) => {
      state.invoiceOrderBy = action.payload;
    },
  },
});

export default invoiceSlice.reducer;

export const {
  setSelectedSupplierId,
  setHasInvoiceItem,
  setIsInvoiceDrawerOpen,
  setSearchKeyword,
  setInvoiceStatus,
  setInvoiceStartDate,
  setInvoiceEndDate,
  setPage,
  setRowsPerPage,
  setInvoiceOrderBy,
} = invoiceSlice.actions;
