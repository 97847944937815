import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMemberOrderBy,
  setPage,
  setRowsPerPage,
} from "../../redux/features/memberSlice";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  visuallyHidden,
} from "../../ui-library";
import MemberDetailDrawer from "./MemberDetailDrawer";
import { formatDate } from "src/utils/formatDateTime";
import { useGetMemberDetailMutation } from "src/redux/api/memberApi";
import { buildFullPhoneNumber } from "src/utils/common";

interface Data {
  name: string | null;
  customer_name: string | null;
  mobile_phone: string | null;
  email: string | null;
  creation: string | null;
}

function createData(
  name: string,
  customer_name: string,
  mobile_phone: string,
  email: string,
  creation: string,
): Data {
  return {
    name,
    customer_name,
    mobile_phone,
    email,
    creation,
  };
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key,
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string },
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort<T>(
  array: readonly T[],
  comparator: (a: T, b: T) => number,
) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "會員編號",
  },
  {
    id: "customer_name",
    numeric: false,
    disablePadding: false,
    label: "姓名",
  },
  {
    id: "mobile_phone",
    numeric: false,
    disablePadding: false,
    label: "電話號碼",
  },
  {
    id: "email",
    numeric: false,
    disablePadding: false,
    label: "電郵地址",
  },
  {
    id: "creation",
    numeric: false,
    disablePadding: false,
    label: "建立日期",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function MemberTable(props: any) {
  const dispatch = useDispatch();
  const { page, rowsPerPage, memberOrderBy } = useSelector(
    (state: any) => state.memberState,
  );

  const [mOrderBy, mOrder] = memberOrderBy.split(" ");

  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [order, setOrder] = React.useState<Order>(mOrder);
  const [orderBy, setOrderBy] = React.useState<keyof Data>(mOrderBy);
  const [rows, setRows] = React.useState<Data[]>([]);

  const [getMemberDetail, { data: memberData }] = useGetMemberDetailMutation();

  React.useEffect(() => {
    if (props.data) {
      setRows([]);
      props.data?.data?.forEach((member: any) => {
        setRows((oldRow: Data[]) => [
          ...oldRow,
          createData(
            member.name ?? "-",
            member.customer_name ?? "-",
            buildFullPhoneNumber(
              member.custom_unique_phone ?? "-",
              member.custom_country_calling_code,
            ),
            member.custom_email ?? "-",
            member.creation,
          ),
        ]);
      });
    }
  }, [props]);

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    dispatch(setMemberOrderBy(`${property} ${isAsc ? "desc" : "asc"}`));
  };

  const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
    getMemberDetail({ memberId: row.name });
    setIsDrawerOpen(true);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(0));
  };

  return (
    <Box className="table__wrapper" sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.map((row) => {
              return (
                <TableRow
                  hover
                  onClick={(event) => handleClick(event, row)}
                  tabIndex={-1}
                  key={row.name}
                  sx={{ cursor: "pointer" }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.customer_name}</TableCell>
                  <TableCell>{row.mobile_phone}</TableCell>
                  <TableCell>{row.email}</TableCell>
                  <TableCell>{formatDate(row.creation)}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage="每頁行數："
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props?.data?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />

      <MemberDetailDrawer
        isDrawerOpen={isDrawerOpen}
        toggleDrawer={toggleDrawer}
        memberData={memberData?.data}
      />
    </Box>
  );
}
