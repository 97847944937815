import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IUser } from "src/interfaces/user";

// USERS ARE THE ACTUALLY STAFF OF THE SHOP WHO OPERATE THIS POS SYSTEM...
const initialState = {
  user: null,
} as UserSliceStateType;

export const userSlice = createSlice({
  initialState,
  name: "userSlice",
  reducers: {
    setUser: (state, action) => {
      state.user = action.payload;
    },

    setUserBranch: (state, action: PayloadAction<{ branchName: string }>) => {
      state.user = { ...state.user, branchName: action.payload.branchName };
    },
  },
});

export default userSlice.reducer;

export const { setUser, setUserBranch } = userSlice.actions;

// TYPE DECLERATION...
export interface UserSliceStateType {
  user: IUser | null;
}
