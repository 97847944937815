import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { IMember } from "../../interfaces/member";

// MEMBERS ARE ACTUALLY THE CUSTOMER OF THE SHOP. THEY ARE PRONOUNCE AS MEMBER IN THIS POS SYSTEM...
export interface IMemberState {
  allMembers: null | any;
  selectedMember?: IMember;
  selectedInvoiceId: string;
  selectedEditingPVariant: IselectedEditingPVariantState | null;
  searchKeyword: string;
  page: number;
  rowsPerPage: number;
  memberOrderBy: string;
  needRefeatchInvoice: boolean;
  invoiceDetail: IInvoiceDetail | null;
}

const initialState = {
  allMembers: null,
  selectedMember: undefined,
  selectedInvoiceId: "",
  selectedEditingPVariant: null,
  searchKeyword: "",
  page: 0,
  rowsPerPage: 10,
  memberOrderBy: "name desc",
  needRefeatchInvoice: false,
  invoiceDetail: null,
} as IMemberState;

export const memberSlice = createSlice({
  name: "memberSlice",
  initialState,
  reducers: {
    setAllMembers: (state, action) => {
      state.allMembers = action.payload;
    },
    setSearchKeyword: (state, action) => {
      state.searchKeyword = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setRowsPerPage: (state, action) => {
      state.rowsPerPage = action.payload;
    },
    setMemberOrderBy: (state, action) => {
      state.memberOrderBy = action.payload;
    },
    setSelectedMember: (state, action: PayloadAction<IMember>) => {
      // const t = action.type;
      // state.selectedMember = { ...state.selectedMember, ...action.payload };
      state.selectedMember = action.payload;
    },

    // PRODUCT RELATED THINGS...
    setSelectedInvoiceId: (state, action: PayloadAction<string>) => {
      state.selectedInvoiceId = action.payload;
    },
    setSelectedVariant: (
      state,
      action: PayloadAction<IselectedEditingPVariantState | null>,
    ) => {
      state.selectedEditingPVariant = action.payload;
    },
    setNeedRefeatchInvoice: (state, action: PayloadAction<boolean>) => {
      state.needRefeatchInvoice = action.payload;
    },

    setInvoiceDetails: (
      state,
      action: PayloadAction<IInvoiceDetail | null>,
    ) => {
      state.invoiceDetail = action.payload;
    },
  },
});

export default memberSlice.reducer;

export const {
  setAllMembers,
  setSearchKeyword,
  setPage,
  setRowsPerPage,
  setMemberOrderBy,
  setSelectedMember,
  setSelectedInvoiceId,
  setSelectedVariant,
  setNeedRefeatchInvoice,
  setInvoiceDetails,
} = memberSlice.actions;

// TYPE DECLERATION...
export interface IselectedEditingPVariantState {
  parent_item_code: string | null;
  name: string;
  item_name: string;
}
