import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { getFormattedInvoiceDate, handleLocalStorage } from "./common";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { ENV_CONFIG } from "src/config/env.config";

export const printInvoiceIntoBrowser = async (invoiceData: IInvoiceDetail) => {
  const authToken =
    handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY) ?? "";

  const payload = {
    tasks: [
      {
        documents: [
          {
            document_type: "SI",
            statuses: [
              {
                status: "normal",
                suppliers: [
                  {
                    document_names: [`${invoiceData.name}`],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  };

  try {
    let headers: HeadersInit | undefined = {
      "Content-Type": "application/json",
    };
    // const sid = Cookies.get(COOKIES_KEYS.SID);
    // console.log("cookies: ", sid);

    if (authToken) {
      headers["Authorization"] = `${authToken}`;
    }

    const taskIdsResponse = await fetch(
      `${ENV_CONFIG.PRINTING_SERVER}/api/method/generate_pdfs`,
      {
        method: "POST", // or 'POST', 'PUT', etc.
        headers: headers,
        body: JSON.stringify(payload),
        credentials: "include",
      },
    );

    if (taskIdsResponse.ok) {
      const data = await taskIdsResponse.json();
      const taskIds: Array<string> = data.data.task_ids;

      if (taskIds && taskIds.length) {
        const based64Chunks = await getBased64Chunks(taskIds[0], headers);

        if (based64Chunks) {
          const decodedString = decodeBase64(String(based64Chunks ?? ""));
          const base64String = JSON.parse(decodedString ?? "{}")[0]?.content;
          const date = getFormattedInvoiceDate(invoiceData.posting_date);

          const fileName = `${date}_INV_${invoiceData.name}.pdf`;

          // Convert base64 to Blob
          const pdfBlob = base64toBlob(base64String, "application/pdf");

          // Create a Blob URL
          const pdfUrl = URL.createObjectURL(pdfBlob);

          // Open a new tab and load the PDF URL
          const newTab = window.open(pdfUrl, "_blank");

          // Optionally, you can add an event listener to revoke the Blob URL when the tab is closed
          if (newTab) {
            newTab.document.title = fileName;
            newTab.addEventListener("beforeunload", () => {
              URL.revokeObjectURL(pdfUrl);
            });
          }
        } else {
          // Handle errors
          console.error(
            "Response2 Error:",
            "problem while getting based64 chunks",
          );
        }
      }
    } else {
      // Handle errors
      console.error("Error:", taskIdsResponse.statusText);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};

const getBased64Chunks = async (task_id: string, headers: any) => {
  let start = 0;
  const length = 665860;
  let totalLength = 0;
  let base64Data = "";

  do {
    const response = await fetch(
      `${ENV_CONFIG.PRINTING_SERVER}/api/method/get_pdf?task_id=${task_id}&start=${start}&length=${length}`,
      {
        method: "GET",
        credentials: "include",
        headers: headers,
      },
    );
    const result = await response.json();
    const d = result.data;

    base64Data += d.chunk;
    start = d.end;
    totalLength = d.total_length;
  } while (start < totalLength);

  return base64Data;
};

const decodeBase64 = (encodedString: string) => {
  try {
    const decodedString = atob(encodedString);
    return decodedString;
  } catch (error) {
    console.error("Error decoding base64 string:", error);
    return null;
  }
};

// Function to convert base64 to Blob
function base64toBlob(base64Data: string, contentType: string) {
  contentType = contentType || "";
  const sliceSize = 1024;
  const byteCharacters = atob(base64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
}
