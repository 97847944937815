import {
  Button,
  Dialog,
  DialogActions,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";

const AddPaymentConfirmModal = (props: DefaultInfoModal) => {
  const { children, handleClose, ...dialogProps } = props;

  return (
    <Dialog {...dialogProps}>
      <Stack
        spacing={2.5}
        className="modal_container"
        padding={"40px 77.656px"}
      >
        <Typography fontSize={20} fontWeight={600}>
          你確認新增後加付款嗎?
        </Typography>

        <DialogActions sx={{ p: 0, justifyContent: "center" }}>
          <Button
            onClick={() => handleClose(false)}
            variant="outlined"
            color="inherit"
            sx={{ color: "#5A6070", padding: "10px 15px" }}
          >
            取消
          </Button>

          <Button
            variant="contained"
            onClick={() => handleClose(true)}
            sx={{ padding: "10px 15px" }}
          >
            確定
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

export default AddPaymentConfirmModal;

export interface DefaultInfoModal extends DialogProps {
  handleClose: (isSubmit: boolean) => void;
}
