import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogProps,
  Stack,
  Typography,
} from "@mui/material";
import React from "react";

const LoadingModal = (props: LoadingModalProps) => {
  const { loaderComp, ...dialogProps } = props;

  const DefaultLoader = loaderComp ?? (
    <Stack
      spacing={2}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{ minHeight: 150, minWidth: 200 }}
    >
      <Box>
        <CircularProgress color="inherit" />
      </Box>

      <Typography fontWeight={600}>Please Wait</Typography>
    </Stack>
  );

  return (
    <Dialog
      {...dialogProps}
      aria-labelledby={dialogProps["aria-label"] ?? "loading-modal"}
    >
      <DialogContent>{DefaultLoader}</DialogContent>
    </Dialog>
  );
};

export default LoadingModal;

export interface LoadingModalProps extends DialogProps {
  loaderComp?: React.ReactElement;
}
