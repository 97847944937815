import React from "react";
import { Button, Dialog, DialogActions, DialogContent } from "../../ui-library";

type ConfirmInvoiceDeleteDialogProps = {
  isModalOpen: boolean;
  title: string;
  content: string;
  handleModalClose: () => void;
  handleDelete: () => void;
};

const ConfirmInvoiceDeleteDialog = ({
  title,
  content,
  isModalOpen,
  handleModalClose,
  handleDelete,
}: ConfirmInvoiceDeleteDialogProps) => {
  return (
    <Dialog
      open={isModalOpen}
      onClose={handleModalClose}
      className="alert__dialog"
    >
      <DialogContent className="alert__dialog-content">
        <h2>{title}</h2>
        <p>{content}</p>
      </DialogContent>
      <DialogActions sx={{ justifyContent: "center", alignItems: "stretch" }}>
        <Button variant="outlined" color="info" onClick={handleModalClose}>
          取消
        </Button>
        <Button variant="contained" onClick={handleDelete}>
          確定
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmInvoiceDeleteDialog;
