import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "src/config/env.config";
import {
  INVOICE_CREATION_STEPS_KEYS,
  USER_RELATED_KEYS,
} from "src/constants/keys/local-storage.keys";
import { IAPIBasePaginatedReqQuery } from "src/interfaces/api-request.interfaces";
import {
  IAPIPaginatedRes,
  IAPIResponse,
} from "src/interfaces/api-response.interfaces";
import { IDeliveryDateItem } from "src/interfaces/delivery-date.interfaces";
import {
  ICreateSaleInvoicePayload,
  ICreateSaleInvoiceResponse,
  IDeleteInvoiceByIdPayload,
  IGetInvoiceDetailPayload,
  IGetInvoiceListItem,
  IInvoiceCoupon,
  IInvoiceDetail,
  IUpdateItemOfSaleInvoicePayload,
} from "src/interfaces/invoice.interfaces";
import { IStateAndCityItem } from "src/interfaces/member";
import {
  IPaymentMethod,
  IPaymentMode,
} from "src/interfaces/payment-method.interfaces";
import { ISalePerson } from "src/interfaces/sale-person.interfaces";
import { handleLocalStorage } from "src/utils/common";
import {
  setInvoiceDetails,
  setSelectedInvoiceId,
} from "../features/memberSlice";

// SALES & INVOICE APIS...
export const salesAndInvoiceApi = createApi({
  reducerPath: "salesAndInvoiceApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_CONFIG.API_URL}/`,
    credentials: "include",
    prepareHeaders: async (headers) => {
      const AuthKey = handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY);
      if (AuthKey) {
        headers.set("Authorization", AuthKey);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    createInvoice: builder.mutation<
      ICreateSaleInvoiceResponse,
      ICreateSaleInvoicePayload
    >({
      query: (body) => {
        return {
          url: `resource/Sales Invoice`,
          method: "POST",
          body: body,
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data && data.data && data.data.name) {
            // Dispatch the setSelectedMember action with the received data
            dispatch(setSelectedInvoiceId(data.data.name));
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),

    getInvoiceById: builder.mutation<
      IAPIResponse<IInvoiceDetail>,
      IGetInvoiceDetailPayload
    >({
      query: (args) => {
        const params = args;

        return {
          url: `method/get_sales_invoice`,
          credentials: "include",
          params,
        };
      },

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // saveinvoice detail into redux store...
          dispatch(setInvoiceDetails(data.data));
        } catch (error) {
          // Handle error if needed
        }
      },
    }),

    deleteInvoiceById: builder.mutation<any, IDeleteInvoiceByIdPayload>({
      query: (args) => {
        const { invoiceId } = args;

        return {
          url: `resource/Sales Invoice/${invoiceId}`,
          method: "DELETE",
        };
      },
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;
          if (data.message === "ok") {
            // remove selected invoice from redux store...
            dispatch(setSelectedInvoiceId(""));
            localStorage.removeItem(INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO);
          }
        } catch (error) {
          // Handle error if needed
        }
      },
    }),

    addUpdateInvoiceItem: builder.mutation<
      any,
      IUpdateItemOfSaleInvoicePayload
    >({
      query: (body) => {
        return {
          url: "method/update_item_in_sales_invoice",
          method: "PUT",
          body,
        };
      },
    }),

    deleteInvoiceItem: builder.mutation<any, IUpdateItemOfSaleInvoicePayload>({
      query: (body) => {
        return {
          url: "method/update_item_in_sales_invoice",
          method: "DELETE",
          body,
        };
      },
    }),

    holdSalesInvoice: builder.mutation<any, IHoldSalesInvoiceApiPayload>({
      query: (payload) => {
        return {
          // url: `method/hold_sales_invoice?invoice_id=${}`,
          url: `resource/Sales Invoice/${payload.invoice_name}`,
          method: "PUT",
          body: { custom_is_hold: 1 },
        };
      },
    }),

    unholdSalesInvoice: builder.mutation<any, IHoldSalesInvoiceApiPayload>({
      query: (payload) => {
        return {
          // url: `method/hold_sales_invoice?invoice_id=${}`,
          url: `resource/Sales Invoice/${payload.invoice_name}`,
          method: "PUT",
          body: { custom_is_hold: 0 },
        };
      },
    }),

    getAddressStatesAndCities: builder.query<
      IAPIResponse<Array<IStateAndCityItem>>,
      any
    >({
      query: () => "/method/get_address_regions_and_districts",
    }),

    getDeliveryDateOptions: builder.mutation<
      IAPIResponse<Array<IDeliveryDateItem>>,
      IGetDeliveryDateOptionsParams
    >({
      query: (params) => {
        return {
          url: "resource/Sales Invoice Delivery Date Options",
          params,
        };
      },
    }),

    updateMemberAddressOfInvoice: builder.mutation<
      IAPIResponse<any>,
      IUpdateMemberAddressOfInvoicePayload
    >({
      query: (body) => {
        const { invoiceId, ...restBody } = body;

        return {
          url: `resource/Sales Invoice/${invoiceId}`,
          method: "PUT",
          body: restBody,
        };
      },
    }),

    // SALE PERSON...
    getSalePersonList: builder.mutation<
      IAPIResponse<Array<ISalePerson>>,
      ISalePersonAPIPayload
    >({
      query: (params) => {
        if (!params.fields?.length) {
          params.fields = '["*"]';
        }

        return {
          url: "resource/Sales Person",
          method: "GET",
          params,
        };
      },
    }),

    addSalesPersonInInvoice: builder.mutation({
      query: (args) => {
        const { invoiceId, body } = args;

        return {
          url: `resource/Sales Invoice/${invoiceId}`,
          method: "PUT",
          body,
        };
      },
    }),

    // INVOICE COUPONS...
    getInvoiceCoupons: builder.mutation<
      IAPIResponse<IInvoiceCoupon>,
      IGetInvoiceCouponsApiPayload
    >({
      query: (query) => {
        return {
          url: "method/get_pos_coupons_for_sales_invoice",
          method: "GET",
          params: query,
        };
      },
    }),

    applyCouponsOnInvoice: builder.mutation<
      IAPIResponse<any>,
      IApplyCouponsOnInvoiceApiPayload
    >({
      query: (body) => {
        return {
          url: "method/apply_pos_coupons_to_sales_invoice",
          method: "PUT",
          body,
        };
      },
    }),

    confirmInvoiceWithPayment: builder.mutation<
      { [key: string]: any; mode_of_payments: Array<any> },
      IConfirmInvoiceWithPaymentAPIPayload
    >({
      query: (body) => {
        return {
          url: `method/confirm_payment_with_pos`,
          method: "POST",
          body,
        };
      },
    }),

    submitSalesInvoice: builder.mutation<
      IAPIResponse<IInvoiceDetail>,
      { invoice_name: string }
    >({
      query: ({ invoice_name }) => {
        return {
          url: `resource/Sales Invoice/${invoice_name}`,
          method: "PUT",
          body: {
            docstatus: 1,
          },
        };
      },
    }),

    // get name of submitted invoice...
    getSubmittedInvoiceName: builder.mutation<
      IAPIResponse<{ name: string }[]>,
      { invoice_name: string }
    >({
      query: ({ invoice_name }) => {
        return {
          url: `resource/Sales Invoice`,
          method: "GET",

          params: {
            filters: JSON.stringify([
              ["custom_draft_invoice_ref", "=", invoice_name],
            ]),
          },
        };
      },
    }),

    createPaymentEntry: builder.mutation<
      IAPIResponse<any>,
      ICreatePaymentEntryPayload
    >({
      query: (body) => {
        const refinedBody = {
          doctype: "Payment Entry",
          docstatus: 1,
          payment_type: "Receive",
          party_type: "Customer",
          party: body.memberId,
          paid_amount: body.paid_amount,
          received_amount: body.paid_amount,
          paid_to: "1110 - Cash - FS",
          paid_to_account_currency: "HKD",
          references: [
            {
              doctype: "Payment Entry Reference",
              reference_doctype: "Sales Invoice",
              reference_name: body.invoiceId,
              allocated_amount: body.paid_amount,
            },
          ],
          mode_of_payments: body.mode_of_payments,
          payment_remarks: body.payment_remarks,
        };
        return {
          url: "resource/Payment Entry",
          method: "POST",
          body: refinedBody,
        };
      },
    }),

    // PAYMENT METHODS...
    getPaymentMethods: builder.mutation<
      IAPIResponse<Array<IPaymentMethod>>,
      IGetPaymentMethodsApiPayload
    >({
      query: (query) => {
        const newQuery = {
          fields: query.fields ?? '["*"]',
          filters: query.filters ?? '[["custom_eligible_for_sales", "=", 1]]',
          limit_page_length: query.limit_page_length ?? 100,
        } as IGetPaymentMethodsApiPayload;

        return {
          url: "resource/Mode of Payment",
          method: "GET",
          params: newQuery,
        };
      },
    }),

    // SHIPPING ADDRESS RELATED APIS...
    updateInvoiceShippingAddress: builder.mutation<
      IAPIResponse<IInvoiceDetail>,
      IUpdateInvoiceShippingAddressPayload
    >({
      query: (body) => {
        const { invoiceID, ...restBody } = body;

        return {
          url: `resource/Sales Invoice/${invoiceID}`,
          method: "PUT",
          body: restBody,
        };
      },
    }),

    getSalesInvoiceList: builder.mutation<
      IAPIPaginatedRes<IGetInvoiceListItem[]>,
      IGetSaleInvoiceParams
    >({
      query: (params) => {
        return {
          url: "method/get_sales_invoices",
          params,
        };
      },
    }),

    returnSaleInvoice: builder.mutation<
      IAPIResponse<any>,
      IReturnSaleInvoicePayload
    >({
      query: (body) => {
        return {
          url: "method/return_sales_invoice",
          method: "POST",
          body,
        };
      },
    }),

    confirmSaleReturnWithPayment: builder.mutation<
      IAPIResponse<any>,
      IConfirmSaleReturnWithPaymentPayload
    >({
      query: (body) => {
        return {
          url: "method/confirm_refund_with_pos",
          method: "POST",
          body,
        };
      },
    }),

    transferSaleInvoice: builder.mutation<
      IAPIResponse<any>,
      ITransferSaleInvoicePayload
    >({
      query: (body) => {
        return {
          url: "method/transfer_sales_invoice",
          method: "POST",
          body,
        };
      },
    }),
  }),
});

export const {
  useGetInvoiceByIdMutation,
  useCreateInvoiceMutation,
  useDeleteInvoiceByIdMutation,
  useAddUpdateInvoiceItemMutation,
  useDeleteInvoiceItemMutation,
  useHoldSalesInvoiceMutation,
  useUnholdSalesInvoiceMutation,
  useGetAddressStatesAndCitiesQuery,
  useGetDeliveryDateOptionsMutation,
  useGetSalePersonListMutation,
  useAddSalesPersonInInvoiceMutation,
  useGetInvoiceCouponsMutation,
  useApplyCouponsOnInvoiceMutation,
  useConfirmInvoiceWithPaymentMutation,
  useSubmitSalesInvoiceMutation,
  useGetSubmittedInvoiceNameMutation,
  useCreatePaymentEntryMutation,
  useGetPaymentMethodsMutation,
  useUpdateMemberAddressOfInvoiceMutation,
  useUpdateInvoiceShippingAddressMutation,
  useGetSalesInvoiceListMutation,
  useReturnSaleInvoiceMutation,
  useConfirmSaleReturnWithPaymentMutation,
  useTransferSaleInvoiceMutation,
} = salesAndInvoiceApi;

// TYPE DECLERATION...
export interface ISalePersonAPIPayload {
  fields?: string;
}

interface IHoldSalesInvoiceApiPayload {
  invoice_name: string;
}

export interface IConfirmInvoiceWithPaymentAPIPayload {
  invoice_name: string;
  mode_of_payments: Array<IPaymentMode>;
  custom_payment_remarks?: string;
}

interface IGetInvoiceCouponsApiPayload {
  invoice_name: string;
}
interface IApplyCouponsOnInvoiceApiPayload {
  invoice_name: string;
  coupon_names: string[];
}

interface IGetPaymentMethodsApiPayload {
  fields?: string;
  limit_page_length?: number;
  filters?: string;
}

export interface IUpdateMemberAddressOfInvoicePayload {
  invoiceId: string;
  shipping_address_name: string;
  custom_delivery_date_option: string;
  custom_discard_old_furniture: number;
  custom_discard_fee?: number;
}

export interface ICreatePaymentEntryPayload {
  memberId: string;
  paid_amount: number;
  invoiceId: string;
  mode_of_payments: Array<IICreatePaymentEntryModeOfPayment>;
  payment_remarks?: string;
}

export interface IICreatePaymentEntryModeOfPayment {
  doctype: string;
  type: string;
  mode_of_payment: string;
  account: string;
  amount: number;
}

export interface IUpdateInvoiceShippingAddressPayload {
  invoiceID: string;
  delivery_date_choice?: string;
  custom_discard_old_furniture?: number;
  custom_discard_fee?: number;
  custom_delivery_date_option?: string;

  custom_recipient_name?: string;
  custom_phone?: string;
  custom_second_phone?: string;
  custom_region?: string;
  custom_district?: string;
  custom_address_line1?: string;
  custom_address_line2?: string;
  custom_stairs_fee?: number;
  custom_cart_fee?: number;
  custom_remote_fee?: number;
  custom_address_remarks?: string;
}

export interface IGetDeliveryDateOptionsParams {
  limit_page_length?: number;
}

export interface IGetSaleInvoiceParams extends IAPIBasePaginatedReqQuery {
  posting_date_start?: string;
  posting_date_end?: string;
  status?: number;
}

export interface IReturnSaleInvoicePayload {
  usr: string;
  pwd: string;
  invoice_name: string;
  custom_return_handler: string;
}

export interface IConfirmSaleReturnWithPaymentPayload {
  usr: string;
  pwd: string;
  invoice_name: string;
  custom_handler: string;
  mode_of_payments: [
    {
      mode_of_payment: string;
      amount: number;
    },
  ];
  custom_payment_remarks?: string;
}

export interface ITransferSaleInvoicePayload {
  usr: string;
  pwd: string;
  custom_handler: string;
  source_invoice_name: string;
  target_invoice_name: string;
  amount: number;
  custom_journal_notes: string;
}
