import { Edit } from "@mui/icons-material";
import { Box, Button, Paper, Typography } from "../../ui-library";
// import productImage from "../assets/images/product-item-1.png";
import { IInvoiceProductItemDetail } from "src/interfaces/invoice.interfaces";
import decreaseIcon from "../../assets/images/decreaseIcon.png";
import deleteIcon from "../../assets/images/deleteIcon.svg";
import increaseIcon from "../../assets/images/increaseIcon.png";
import { generateRemoteMediaUrl, getValidImageUrl } from "src/utils/common";

interface DrawerInvoiceItemProps {
  product: IInvoiceProductItemDetail;
  handleDeleteItem: (itemCode: string) => void;
  handleEditItem: (invoiceItem: IInvoiceProductItemDetail) => void;
  handleUpdateQuantity: (
    item: IInvoiceProductItemDetail,
    isIncrease: boolean,
  ) => void;
}

const DrawerInvoiceItem = (props: DrawerInvoiceItemProps) => {
  // PROPS DISTRUCTURE...
  const { product, handleDeleteItem, handleEditItem, handleUpdateQuantity } =
    props;

  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  return (
    <Paper className="drawer__cart-wrapper">
      <Box className="drawer__cart-header">
        <Box className="drawer__cart-header-left">
          <img
            // src={generateRemoteMediaUrl(product.custom_template_thumbnail)}
            src={getValidImageUrl(product.custom_template_thumbnail)}
            alt={props.product.parent_item_name ?? "selected variant"}
            width={60}
            height={60}
            onError={handleImageError}
          />
          <Box className="drawer__cart-header-product-title">
            <Typography className="drawer__cart-header-product-code">
              {product.item_code}
            </Typography>
            <Typography className="drawer__cart-header-product-name">
              {product.item_name}
            </Typography>
          </Box>
        </Box>

        <Button>
          <img
            src={deleteIcon}
            alt="product-remove"
            onClick={() => handleDeleteItem(product.item_code)}
          />
        </Button>
      </Box>

      <Box className="drawer__cart-detail">
        <Box className="drawer__cart-detail-left">
          {product.custom_display_item === 1 && (
            <Typography
              sx={{
                color: "#3742F9 !important",
                fontSize: "16px !important",
                fontWeight: "700  !important",
              }}
            >
              陳列品
            </Typography>
          )}
          {product.attributes.length
            ? product.attributes.map((attr, index) => {
                return (
                  <Typography key={index}>
                    {attr.attribute}: {attr.attribute_value}
                  </Typography>
                );
              })
            : // <Box color="#7f7f7f">無可用屬性</Box>
              null}
        </Box>

        <Button
          variant="contained"
          onClick={() => handleEditItem(product)}
          startIcon={<Edit />}
        >
          編輯
        </Button>
      </Box>

      <Box className="drawer__cart-footer">
        <Box className="drawer_-cart-footer-qty-control-wrapper">
          <Button
            className="footer__qty-control-item"
            onClick={() => handleUpdateQuantity(product, false)}
          >
            <img src={decreaseIcon} alt="qty-decrease" />
          </Button>

          <Typography className="footer__qty-control-item">
            {product.qty}
          </Typography>

          <Button
            className="footer__qty-control-item"
            onClick={() => handleUpdateQuantity(product, true)}
          >
            <img src={increaseIcon} alt="qty-increase" />
          </Button>
        </Box>

        <Typography className="drawer__cart-footer-price">
          HK$ {product.base_amount}
        </Typography>
      </Box>
    </Paper>
  );
};

export default DrawerInvoiceItem;
