import { Box, BoxProps, CircularProgress } from "@mui/material";
import React from "react";

const DefaultLoaderContainer = (props: DefaultLoaderContainerProps) => {
  // PROPS DESTRUCTURE...
  const {
    children,
    isLoading = true,
    loaderBoxProps,
    loaderComp,
    ...restProps
  } = props;

  const loader = loaderComp ?? <CircularProgress color="inherit" />;

  return (
    <Box aria-label="default-loader-container" {...restProps}>
      {isLoading ? (
        <Box
          aria-label="default-loader"
          {...loaderBoxProps}
          display={loaderBoxProps?.display ?? "flex"}
          justifyContent={loaderBoxProps?.justifyContent ?? "center"}
          alignItems={loaderBoxProps?.alignItems ?? "center"}
        >
          {loader}
        </Box>
      ) : (
        <Box aria-label="default-loader-container-children">{children}</Box>
      )}
    </Box>
  );
};

export default DefaultLoaderContainer;

// TYPE DECLERATION...
export interface DefaultLoaderContainerProps extends BoxProps {
  isLoading?: boolean;
  loaderComp?: React.ReactElement;
  loaderBoxProps?: BoxProps;
}
