import AddCircleIcon from "@mui/icons-material/AddCircle";
import {
  Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Snackbar,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  IMemberState,
  setInvoiceDetails,
} from "src/redux/features/memberSlice";
import CouponInvoiceProductRow from "./CouponInvoiceProductRow";
import LoadingModal from "src/components/loader/LoadingModal";
import ApplyCouponModal from "./ApplyCouponModal";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import memberRoutes from "src/constants/routes/member.routes";
import {
  useAddUpdateInvoiceItemMutation,
  useGetInvoiceByIdMutation,
  useHoldSalesInvoiceMutation,
  useUnholdSalesInvoiceMutation,
} from "src/redux/api/saleAndInvoiceApi";
import {
  IInvoiceDetail,
  IInvoiceProductItemDetail,
} from "src/interfaces/invoice.interfaces";
import { createSelector } from "@reduxjs/toolkit";

// Memoized selectors
const selectInvoiceDetail = createSelector(
  (state: any) => state.memberState.invoiceDetail,
  (invoiceDetail) => invoiceDetail,
);

const selectSelectedInvoiceId = createSelector(
  (state: any) => state.memberState.selectedInvoiceId,
  (selectedInvoiceId) => selectedInvoiceId,
);

interface ProductInformationProps {
  handleSaveSalesPerson: () => void;
  isAddSalesPersonLoading: boolean;
  isAddSalesPersonSuccess: boolean;
  addSalesPersonError?: any;
}

const ProductInformation = ({
  handleSaveSalesPerson,
  isAddSalesPersonLoading,
  isAddSalesPersonSuccess,
  addSalesPersonError,
}: ProductInformationProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const updateSourceRef = useRef<"coupon" | "normal" | null>(null);

  // LOCAL STATES...
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [isNeedHoldInvoice, setIsNeedHoldInvoice] = useState(false);
  const [isNeedHoldInvoiceDraft, setIsNeedHoldInvoiceDraft] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [applyCouponModalOpen, setApplyCouponModalOpen] = useState(false);
  const [isCouponEditingOn, setIsCouponEditingOn] = useState(false);
  const [latestInvoiceDetail, setLatestInvoiceDetail] =
    useState<IInvoiceDetail>();

  // REDUX THINGS...
  // const { invoiceDetail: invoiceDetailInStore, selectedInvoiceId } =
  //   useSelector((state: any) => state.memberState as IMemberState);
  const invoiceDetailInStore = useSelector(selectInvoiceDetail);
  const selectedInvoiceId = useSelector(selectSelectedInvoiceId);

  // REDUX MUTATION...
  const [
    getInvoiceById,
    {
      data: invoiceDetail,
      isSuccess: isInvoiceDataSuccess,
      isLoading: isInvoiceLoading,
    },
  ] = useGetInvoiceByIdMutation();

  const [holdSalesInvoice, { isError: isHoldInvoiceError }] =
    useHoldSalesInvoiceMutation();

  const [unholdSalesInvoice, { isError: isunholdInvoiceError }] =
    useUnholdSalesInvoiceMutation();

  const [
    addUpdateInvoiceItem,
    { isLoading: isUpdateRemarksLoading, isSuccess: isUpdateRemarksSuccess },
  ] = useAddUpdateInvoiceItemMutation();

  // CALL THE GET INVOICE DETAIL API IF DATA ARE NOT IN STORE...
  useEffect(() => {
    const timer = setTimeout(() => {
      if (selectedInvoiceId && invoiceDetailInStore) {
        setIsNeedHoldInvoice(
          invoiceDetailInStore?.custom_is_hold === 1 ? true : false,
        );
        setLatestInvoiceDetail(invoiceDetailInStore);
      } else if (!selectedInvoiceId && !invoiceDetailInStore) {
        getInvoiceById({ invoice_name: selectedInvoiceId });
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, [selectedInvoiceId, invoiceDetailInStore]);

  // SAVE NEW INVOICE DETAIL INTO STORE...
  useEffect(() => {
    if (isInvoiceDataSuccess && invoiceDetail) {
      dispatch(setInvoiceDetails(invoiceDetail.data));
      setLatestInvoiceDetail(invoiceDetail.data);
      setIsNeedHoldInvoice(
        invoiceDetail.data?.custom_is_hold === 1 ? true : false,
      );
      const d = invoiceDetail.data;
      if (
        (d && d.total_applied_coupons.item.length) ||
        (d && d.total_applied_coupons.amount.length) ||
        (d && d.total_applied_coupons.percentage.length)
      ) {
        setIsCouponEditingOn(true);
      } else setIsCouponEditingOn(false);
    }
    setIsLoading(false);
  }, [isInvoiceDataSuccess, invoiceDetail]);

  // UPDATE ISHOLD STATE ACCORDING TO RESPONSE...
  useEffect(() => {
    if (isHoldInvoiceError || isunholdInvoiceError) {
      // rollback to previous state
      setIsNeedHoldInvoice(isNeedHoldInvoiceDraft);
    }
  }, [isunholdInvoiceError, isHoldInvoiceError]);

  useEffect(() => {
    if (addSalesPersonError) {
      const addErrorMessage = JSON.parse(
        addSalesPersonError?.data?._server_messages,
      );

      if (Array.isArray(addErrorMessage) && addErrorMessage.length > 0) {
        const message = JSON.parse(addErrorMessage[0]);
        setSnackbarMessage(message?.message);
        handleSnackbarToggle();
      }
    }
    if (isAddSalesPersonSuccess) {
      navigate(memberRoutes.productSelectionStep.shippingInfo);
    }
  }, [isAddSalesPersonLoading]);

  useEffect(() => {
    if (isUpdateRemarksSuccess) {
      if (updateSourceRef.current === "coupon") {
        getInvoiceById({ invoice_name: selectedInvoiceId });
      } else if (updateSourceRef.current === "normal") {
        handleSaveSalesPerson();
      }
      updateSourceRef.current = null; // Reset after handling
    }
  }, [isUpdateRemarksLoading]);

  const handleSnackbarToggle = () => {
    setOpenSnackbar(!openSnackbar);
  };

  const handleInvoiceHold = (checked: boolean) => {
    setIsNeedHoldInvoiceDraft(isNeedHoldInvoice);
    setIsNeedHoldInvoice(checked);
    if (checked === true) holdSalesInvoice({ invoice_name: selectedInvoiceId });
    else unholdSalesInvoice({ invoice_name: selectedInvoiceId });
  };

  // REFATCH AND HANDLE LOADING WHILE APPLYING COUPONS...
  const handleCouponApplySuccess = () => {
    updateSourceRef.current = "coupon";
    setApplyCouponModalOpen(false);
    if (latestInvoiceDetail?.items?.length) {
      const items = latestInvoiceDetail.items.map((item) => ({
        item_code: item.item_code,
        custom_item_remarks: item.sales_invoice_custom_item_remarks || "",
      }));
      addUpdateInvoiceItem({
        invoice_name: selectedInvoiceId,
        items: items,
      });
    }
  };

  const handleNextPageClick = () => {
    updateSourceRef.current = "normal";
    if (latestInvoiceDetail?.items?.length) {
      const items = latestInvoiceDetail.items.map((item) => ({
        item_code: item.item_code,
        custom_item_remarks: item.sales_invoice_custom_item_remarks || "",
      }));
      addUpdateInvoiceItem({
        invoice_name: selectedInvoiceId,
        items: items,
      });
    }
  };

  const updateItemRemarks = (index: number, newRemarks: string) => {
    setLatestInvoiceDetail((prevState) => {
      if (!prevState) {
        // Handle the case where prevState is undefined
        return;
      }
      const updatedItems = [...prevState.items];
      updatedItems[index] = {
        ...updatedItems[index],
        sales_invoice_custom_item_remarks: newRemarks,
      };
      return {
        ...prevState,
        items: updatedItems,
      };
    });
  };

  // console.log("invoice latest info: ", latestInvoiceDetail);

  return (
    <Box aria-label="product-information">
      <Stack
        direction="row"
        spacing={1}
        py="20.5px"
        px="30px"
        borderRadius="5px 5px 0 0"
        bgcolor="#5A6070"
        width="fit-content"
        color="#fff"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src="/icons/info.svg"
            alt="sale-girl"
            width={24}
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Typography fontSize={18} fontWeight={700}>
          商品信息
        </Typography>
      </Stack>

      <Stack
        spacing={2.5}
        padding="20px"
        borderRadius="0 5px 5px 5px"
        border="1px solid #E0E0E0"
        bgcolor="#fff"
      >
        <Box
          aria-labelledby="hold-invoice-section"
          display="flex"
          justifyContent="space-between"
          alignItems={"center"}
          fontSize={20}
        >
          <Box>
            <Box component="span" fontWeight={700}>
              供應商
            </Box>
            <Box component="span" fontWeight={500} ml={1.1}>
              {latestInvoiceDetail?.supplier}
            </Box>
          </Box>

          <Box
            sx={{
              "& .form_input_checkbox": {
                marginRight: 0,
                padding: "15px",
                borderRadius: "5px",
                border: `1px solid ${
                  isNeedHoldInvoice ? "transparent" : "#BDBDBD"
                }`,
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  name="need_hold_order"
                  value={isNeedHoldInvoice}
                  onChange={(e, checked) => {
                    handleInvoiceHold(checked);
                  }}
                  sx={{
                    color: isNeedHoldInvoice ? "#FFF !important" : "#828282",
                    "&.Mui-checked": {
                      color: isNeedHoldInvoice ? "#FFF" : "#828282",
                    },
                  }}
                />
              }
              checked={isNeedHoldInvoice}
              label="需要HOLD單"
              sx={{
                marginRight: 0,
                padding: "15px",
                borderRadius: "5px",
                border: `1px solid ${
                  isNeedHoldInvoice ? "transparent" : "#BDBDBD"
                }`,
                bgcolor: isNeedHoldInvoice ? "#45A492" : "#fff",
                color: isNeedHoldInvoice ? "#FFF !important" : "#5A6070",
              }}
            />
          </Box>
        </Box>

        {/* PRODUCT TABLE... */}
        <Box aria-label="product-table" mt={2.5}>
          <Stack
            aria-label="header-and-data-section"
            spacing={5}
            sx={{
              border: "1.125px solid #E0E0E0",
              borderRadius: "5px",
              padding: "20px 12px",
            }}
          >
            <Grid
              width={"100%"}
              container
              aria-label="table-header"
              // columnSpacing={0}
              sx={{
                height: 70,
                fontWeight: 500,
                fontSize: 20,
                borderBottom: "1px solid #BDBDBD",
                py: "10px",
                "& .table_header": {
                  "&:not(:last-child)": {
                    borderRight: "1px solid #BDBDBD",
                  },
                },
              }}
            >
              <Grid item xs={6} className="table_header">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  商品資料
                </Box>
              </Grid>

              <Grid item xs={2} className="table_header">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  單價(HK$)
                </Box>
              </Grid>

              <Grid item xs={2} className="table_header">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  折扣(HK$)
                </Box>
              </Grid>

              <Grid item xs={2} className="table_header">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  金額(HK$)
                </Box>
              </Grid>
            </Grid>

            {/* INVOICE ITEM DETAIL ROW... */}
            {latestInvoiceDetail &&
              latestInvoiceDetail.items.map((product, ind) => {
                return (
                  <CouponInvoiceProductRow
                    key={ind}
                    {...product}
                    custom_item_remarks={
                      product.sales_invoice_custom_item_remarks
                    }
                    onRemarksChange={(newRemarks: string) =>
                      updateItemRemarks(ind, newRemarks)
                    }
                  />
                );
              })}
          </Stack>
        </Box>

        <Button
          variant={isCouponEditingOn ? "contained" : "outlined"}
          fullWidth
          startIcon={isCouponEditingOn ? <EditIcon /> : <AddCircleIcon />}
          sx={{
            padding: "12px 29.72px",
            fontSize: "16px",
            fontWeight: 700,
            background: isCouponEditingOn ? "#E26F71" : "#fff",
            color: isCouponEditingOn ? "#fff" : "#E26F71",
            borderColor: isCouponEditingOn ? "transparent" : "#E26F71",
            "&:hover": {
              background: isCouponEditingOn ? "#E26F71" : "#fff",
              borderColor: isCouponEditingOn ? "transparent" : "#E26F71",
            },
          }}
          onClick={() => setApplyCouponModalOpen(true)}
        >
          新增優惠券
        </Button>

        <Stack
          spacing={2.5}
          sx={{
            p: "30px 20px 20px 20px",
            border: "1.13px solid #E0E0E0",
            borderRadius: "5px",
          }}
        >
          {/* ACCUMULATOR SECTION... */}
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography fontSize={20}>產品累計</Typography>
            <Typography fontSize={25} fontWeight={700}>
              $ {latestInvoiceDetail?.custom_total_item_discounted_amount}
            </Typography>
          </Box>

          {/* COUPON SECTION */}
          {latestInvoiceDetail?.applied_coupons.length ? (
            <Stack spacing={2.5}>
              {latestInvoiceDetail?.applied_coupons.map((d) => {
                return (
                  <Box
                    key={d.name}
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Box
                      p={"15px"}
                      borderRadius={"5px"}
                      fontWeight={700}
                      color="#fff"
                      bgcolor="#E26F71"
                    >
                      {d.coupon_name}
                    </Box>
                    <Typography fontSize={25} fontWeight={700}>
                      -$ {d.amount}
                    </Typography>
                  </Box>
                );
              })}
            </Stack>
          ) : null}

          {/* TOTAL SECTION.. */}
          <Stack spacing={2.5}>
            <Divider />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography fontSize={20}>全單總計</Typography>
              <Typography fontSize={25} fontWeight={700}>
                $ {latestInvoiceDetail?.rounded_total}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Button
        variant="contained"
        fullWidth
        disabled={isAddSalesPersonLoading || isUpdateRemarksLoading}
        sx={{ p: "8px 10px", mt: 1 }}
        onClick={handleNextPageClick}
      >
        下一步
      </Button>

      {/* ADD COUPON MODAL */}
      <ApplyCouponModal
        open={applyCouponModalOpen}
        handleClose={() => setApplyCouponModalOpen(false)}
        // invoiceCode={selectedInvoiceId ?? "ACC-SINV-2023-00081"}
        invoiceCode={selectedInvoiceId}
        handleCouponApplySuccess={handleCouponApplySuccess}
      />

      <LoadingModal
        maxWidth="sm"
        open={isLoading || isInvoiceLoading || isUpdateRemarksLoading}
      />

      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleSnackbarToggle}
      >
        <Alert
          onClose={handleSnackbarToggle}
          severity="error"
          sx={{ width: "100%" }}
        >
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ProductInformation;
