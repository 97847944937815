import CloseIcon from "@mui/icons-material/Close";
import {
  Box,
  Button,
  Dialog,
  FormHelperText,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { CustTextField } from "src/components/form-components/FormInput";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";

// ============================ || INVOICE TRANSFER DIALOG || ====================================//
const TransferInvoiceDialog = (props: TransferInvoiceDialogProps) => {
  const { sourceInvoice, targetInvoice, handleModalClose, open, onSubmit } =
    props;

  // LOCAL STATES...
  const [transferAmount, setTransferAmount] = useState(0);
  const [journalNotes, setJournalNotes] = useState("");

  const handleSubmit = () => {
    if (transferAmount) {
      onSubmit(transferAmount, journalNotes);
    }
  };

  return (
    <Dialog
      aria-labelledby="select-return-person-dialog"
      maxWidth="md"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Stack
          aria-label="header-section"
          direction="row"
          spacing={2.5}
          alignItems={"center"}
          borderBottom={(theme) => `1px solid ${theme.palette.grey[500]}`}
          pb={2.5}
        >
          <Box color={"gray.500"}>
            <Button
              variant="outlined"
              disableElevation
              color="inherit"
              sx={{ p: "14px", borderRadius: "6px" }}
              onClick={handleModalClose}
            >
              <CloseIcon />
            </Button>
          </Box>

          <Stack direction="row" spacing={"12.8px"} mb={2.5}>
            <Box
              component="img"
              src="/icons/payment-black.svg"
              width={40}
              height={40}
              alt="payment-icon"
            />
            <Typography fontWeight={700} fontSize={28.8}>
              退款
            </Typography>
          </Stack>
        </Stack>

        {/* REST BODY SECTION... */}
        <Stack sx={{ my: "25px" }} spacing={2.5}>
          {/* SOURCE INVOICE SECTION... */}
          <Stack
            aria-label="source-invoice-section"
            spacing={2.5}
            sx={{ bgcolor: "#F2F2F2", p: 2.5, borderRadius: "6px" }}
          >
            <Stack spacing={2.5}>
              <Stack
                spacing={2.5}
                borderBottom={(theme) => `1px solid ${theme.palette.grey[500]}`}
                pb={2.5}
              >
                <Typography fontWeight={600} fontSize={22}>
                  舊單 ({sourceInvoice.name})
                </Typography>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={20}>退款餘額</Typography>
                  <Typography fontSize={25} fontWeight={700}>
                    $ {sourceInvoice?.return_outstanding_total}
                  </Typography>
                </Box>
              </Stack>

              {/* AMOUNT INPUT BOX... */}
              <Box aria-label="amount-input-box">
                <Box
                  bgcolor={"secondary.main"}
                  borderRadius={"6px"}
                  p="10px"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    ".Mui-focused": {
                      border: "none",
                      outline: "none",
                      // outlineOffset: "none",
                    },
                    "& fieldset": {
                      border: "none",
                      outline: "none",
                      borderColor: "transparent",
                    },
                  }}
                >
                  <Typography
                    color={"white"}
                    fontSize={18}
                    fontWeight={700}
                    px={"29px"}
                  >
                    轉單金額(HK$){" "}
                  </Typography>

                  <TextField
                    name="transfer_amt"
                    type="number"
                    value={transferAmount}
                    onChange={(e) => {
                      const val = e.target.value as string;
                      setTransferAmount(Number(val ?? 0));
                    }}
                    sx={{
                      width: 292,
                      bgcolor: "white",
                      border: "none",
                      outline: "none",
                      borderRadius: "6px",
                      "& input": {
                        height: 30,
                        p: 1,
                      },
                    }}
                  />
                </Box>
                {transferAmount > targetInvoice.outstanding_total ? (
                  <FormHelperText
                    error={true}
                    sx={{ textAlign: "end", mt: "10px" }}
                  >
                    金額不可超過新單餘額
                  </FormHelperText>
                ) : null}
              </Box>

              <Box
                aria-label="refund-balance-section"
                borderTop={(theme) => `1px solid ${theme.palette.grey[500]}`}
                pt={2.5}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={20}>轉單後退款餘額</Typography>
                  <Typography fontSize={25} fontWeight={700}>
                    $ {sourceInvoice?.return_outstanding_total - transferAmount}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Stack>

          {/* TARGET INVOICE SECTION... */}
          <Stack
            aria-label="target-invoice-section"
            spacing={2.5}
            sx={{
              p: 2.5,
              borderRadius: "6px",
              border: (theme) => `1px solid ${theme.palette.grey[500]}`,
            }}
          >
            <Stack spacing={2.5}>
              <Stack
                spacing={2.5}
                borderBottom={(theme) => `1px solid ${theme.palette.grey[500]}`}
                pb={2.5}
              >
                <Typography fontWeight={600} fontSize={22}>
                  新單 ({targetInvoice.name})
                </Typography>

                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={20}>需支付餘額</Typography>
                  <Typography fontSize={25} fontWeight={700}>
                    $ {targetInvoice?.outstanding_total}
                  </Typography>
                </Box>
              </Stack>

              {/* TRANSFERED AMOUNT BOX... */}
              <Box
                aria-label="transferred-amount-calculation-box"
                display={"flex"}
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <Box
                  bgcolor={"secondary.main"}
                  borderRadius={"6px"}
                  p="10px"
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                  sx={{
                    ".Mui-focused": {
                      border: "none",
                      outline: "none",
                      // outlineOffset: "none",
                    },
                    "& fieldset": {
                      border: "none",
                      outline: "none",
                      borderColor: "transparent",
                    },
                  }}
                >
                  <Typography
                    color={"white"}
                    fontSize={18}
                    fontWeight={700}
                    px={"29px"}
                  >
                    轉單金額(HK$)
                  </Typography>
                </Box>

                <Typography fontSize={25} fontWeight={700}>
                  $ {transferAmount}
                </Typography>
              </Box>

              <Box
                aria-label="refund-balance-section"
                borderTop={(theme) => `1px solid ${theme.palette.grey[500]}`}
                pt={2.5}
              >
                <Box
                  display={"flex"}
                  justifyContent={"space-between"}
                  alignItems={"center"}
                >
                  <Typography fontSize={20}>轉單後需支付餘額</Typography>
                  <Typography fontSize={25} fontWeight={700}>
                    $ {targetInvoice?.outstanding_total - transferAmount}
                  </Typography>
                </Box>
              </Box>
            </Stack>
          </Stack>

          {/* CUTOM JOURNAL NOTE SECTION... */}
          <Stack
            aria-label="custom-journal-note-section"
            spacing={2.5}
            sx={{
              p: 2.5,
              borderRadius: "6px",
              border: (theme) => `1px solid ${theme.palette.grey[500]}`,
            }}
          >
            <CustTextField
              label="轉單備註"
              placeholder="全部轉單"
              value={journalNotes}
              onChange={(e) => setJournalNotes(e.target.value)}
            />
          </Stack>
        </Stack>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={2.5}
          color="grey.500"
        >
          <Button
            variant="outlined"
            onClick={() => handleModalClose()}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="inherit"
          >
            取消
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
export default TransferInvoiceDialog;

// TYPE DECLERATIONS...
export interface TransferInvoiceDialogProps {
  open: boolean;
  handleModalClose: () => void;
  sourceInvoice: IInvoiceDetail;
  targetInvoice: IInvoiceDetail;
  onSubmit: (transfer_amt: number, journal_note: string) => void;
}
