import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Typography,
} from "../../ui-library";

type SubmitInvoiceDialogProps = {
  isLoading: boolean;
  isCompleteInvoice?: boolean;
  isModalOpen: boolean;
  title: string;
  confirmLabel?: string;
  handleModalClose?: () => void;
  handleConfirm: () => void;
};

const SubmitInvoiceDialog = ({
  isModalOpen,
  isCompleteInvoice,
  title,
  isLoading,
  confirmLabel,
  handleModalClose,
  handleConfirm,
}: SubmitInvoiceDialogProps) => {
  return (
    <Dialog open={isModalOpen} onClose={handleModalClose}>
      {isLoading ? (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20.04px",
            padding: "40px 77.68px",
          }}
        >
          <CircularProgress color="inherit" />
        </DialogContent>
      ) : (
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            gap: "20.04px",
            padding: "40px 77.68px",
          }}
        >
          {isCompleteInvoice ? (
            <img
              src="/icons/order-approve.svg"
              alt="order-play"
              width={60}
              height={60}
              style={{ objectFit: "contain" }}
            />
          ) : (
            <img
              src="/icons/order-play.svg"
              alt="order-play"
              width={60}
              height={60}
              style={{ objectFit: "contain" }}
            />
          )}

          <Typography
            variant="h2"
            sx={{ fontSize: "20px", fontWeight: "600", color: "#333" }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              display: "flex",
              gap: "20px",
            }}
          >
            {handleModalClose ? (
              <Button
                variant="outlined"
                color="info"
                sx={{ padding: "15px 25px" }}
                onClick={handleModalClose}
              >
                取消
              </Button>
            ) : null}
            <Button
              variant="contained"
              sx={{ padding: "15px 40px" }}
              onClick={handleConfirm}
            >
              {confirmLabel ? confirmLabel : "確定"}
            </Button>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default SubmitInvoiceDialog;
