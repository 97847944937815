import { Navigate, createBrowserRouter } from "react-router-dom";
import App from "src/App";
import LoginPage from "src/pages/auth/login";
import appRoutes from "./app.routes";
//import PrivateRoute from "./privateRoute";

// CREATE ROUTE OBJECTS...
export const routes = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    children: [...appRoutes, { path: "login", element: <LoginPage /> }],
  },

  //{ path: "login", element: <LoginPage /> },
  { path: "*", element: <Navigate to="/" replace={true} /> },
]);
