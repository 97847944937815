import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "src/config/env.config";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { IAPIResponse } from "src/interfaces/api-response.interfaces";
import {
  ICreateMember,
  ICretaeMemberAddressResponse,
  IGetMemberListPayload,
  IGetMemberListResponse,
  IMemberAddress,
  IMember,
  ICreateMemberAddress,
  IUpdateMember,
} from "src/interfaces/member";
import { handleLocalStorage } from "src/utils/common";
import { setSelectedMember } from "../features/memberSlice";

// CUSTOMER APIS...
export const memberApi = createApi({
  reducerPath: "memberAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_CONFIG.API_URL}/`,
    credentials: "include",
    prepareHeaders: async (headers) => {
      const AuthKey = handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY);
      if (AuthKey) {
        headers.set("Authorization", AuthKey);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getMembers: builder.mutation<IGetMemberListResponse, IGetMemberListPayload>(
      {
        query: (args) => {
          const params = {
            ...args,
            fields: '["*"]',
          };

          return {
            url: "method/get_customers",
            method: "GET",
            params,
          };
        },
      },
    ),

    getMemberDetail: builder.mutation<
      IAPIResponse<IMember>,
      IGetMemberDetailPayload
    >({
      query: (args) => {
        const { memberId } = args;
        let params = { fields: '["*"]' };

        return {
          url: `resource/Customer/${memberId}`,
          params,
        };
      },

      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled;

          // Dispatch the setSelectedMember action with the received data
          dispatch(setSelectedMember(data.data));
        } catch (error) {
          // Handle error if needed
        }
      },
    }),

    createMember: builder.mutation<any, { body: ICreateMember }>({
      query: (data) => {
        return {
          url: "resource/Customer",
          method: "POST",
          body: data.body,
        };
      },
    }),

    updateMember: builder.mutation<any, { id: string; body: IUpdateMember }>({
      query: (payload) => {
        return {
          url: `resource/Customer/${payload.id}`,
          method: "PUT",
          body: payload.body,
        };
      },
    }),

    checkDuplicateMember: builder.mutation({
      query: (args: CheckDuplicateMemberArgs) => {
        // const { mobilePhone } = args;
        return {
          url: "method/check_customer_phone_is_duplicate",
          method: "GET",
          params: args,
        };
      },
    }),

    getMemberAddresses: builder.mutation<
      IAPIResponse<Array<IMemberAddress>>,
      IGetMemberAddressesPayload
    >({
      query: (query) => {
        const filters = [
          ["Dynamic Link", "link_name", "=", query.memberId ?? "FB-000016"],
        ];
        const fields = '["*"]';

        let params = {
          fields: query.fields ?? fields,
          filters: query.filters ?? JSON.stringify(filters),
        };

        return {
          url: "/resource/Address",
          params,
        };
      },
    }),

    createMemberAddress: builder.mutation<
      IAPIResponse<ICretaeMemberAddressResponse>,
      ICreateMemberAddressPayload
    >({
      query: (body) => {
        const { memberId, ...rest } = body;

        const refinedBody = {
          ...rest,
          doctype: "Address",
          address_type: "Shipping",
          // country: "Hong Kong",
          links: [
            {
              doctype: "Dynamic Link",
              parentfield: "links",
              parenttype: "Address",
              link_doctype: "Customer",
              link_name: memberId,
            },
          ],
        };

        return {
          url: "resource/Address",
          method: "POST",
          body: refinedBody,
        };
      },
    }),

    updateMemberAddress: builder.mutation<
      IAPIResponse<ICretaeMemberAddressResponse>,
      IUpdateMemberAddressPayload
    >({
      query: (body) => {
        const { addressId, ...restBody } = body;

        return {
          url: `resource/Address/${addressId}`,
          method: "PUT",
          body: restBody,
        };
      },
    }),
  }),
});

export const {
  useGetMembersMutation,
  useGetMemberDetailMutation,
  useCreateMemberMutation,
  useUpdateMemberMutation,
  useCheckDuplicateMemberMutation,
  useCreateMemberAddressMutation,
  useUpdateMemberAddressMutation,
  useGetMemberAddressesMutation,
} = memberApi;

// TYPE DECLERATION...
export interface IGetMemberDetailPayload {
  memberId: string;
}
interface CheckDuplicateMemberArgs {
  mobilePhone: string;
  areaCode: string;
}

interface IGetMemberAddressesPayload {
  fields?: string;
  filters?: string;
  memberId?: string;
}

export interface ICreateMemberAddressPayload extends ICreateMemberAddress {
  memberId: string;
}
export interface IUpdateMemberAddressPayload
  extends Partial<ICreateMemberAddress> {
  addressId: string;
  disabled?: 0 | 1;
}
