import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ENV_CONFIG } from "src/config/env.config";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { handleLocalStorage, isValidValue } from "src/utils/common";

interface PrivateRouteProps {
  children: React.ReactNode;
}

const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  // Replace this with your actual authentication check

  const authToken = handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY);
  const userLoginStatus = handleLocalStorage.getValue(
    USER_RELATED_KEYS.USER_LOGIN_STATUS,
  );
  const isAuthenticated = () => {
    let authenticated = false;

    if (
      ENV_CONFIG.NODE_ENV.toLowerCase() === "production" &&
      userLoginStatus === "true"
    ) {
      authenticated = true;
    } else if (
      ENV_CONFIG.NODE_ENV.toLowerCase() === "development" &&
      userLoginStatus === "true" &&
      isValidValue(authToken)
    ) {
      authenticated = true;
    } else {
      authenticated = false;
    }
    return authenticated;
  };

  return isAuthenticated() ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace={true} />
  );
};

export default PrivateRoute;
