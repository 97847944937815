import dayjs from "dayjs";

export const formatDate = (
  date: string | null | undefined,
  format?: string | null,
) => {
  if (!date) return "--";

  if (date && format) {
    return dayjs(date).format(format);
  }
  return dayjs(date).format("YYYY-MM-DD");
};
