import Grid2 from "@mui/material/Unstable_Grid2";
import { Box, Button } from "src/ui-library";
import RootLayout from "../../layout/Layout";
import { reportBreadcrumb } from "./_components/BreadCrumb";

// ==============================|| REPORT INDEX PAGE COMPONENT ||============================== //
const ReportIndexPage = () => {
  return (
    <RootLayout>
      <Box aria-label="breadcrumb-section">{reportBreadcrumb.indexPage}</Box>

      <Grid2 container spacing={"12px"}>
        <Grid2 xs={12} md={6} color="#5A6070">
          <Button
            variant="outlined"
            color="inherit"
            fullWidth
            sx={{
              py: "12px",
              px: "30px",
              height: 120,
              color: "#5A6070",
              border: "3px solid #5A6070",
              fontWeight: 700,
            }}
          >
            {" "}
            銷售額報告
          </Button>
        </Grid2>

        <Grid2 xs={12} md={6} color="#5A6070">
          <Button
            variant="outlined"
            color="inherit"
            fullWidth
            sx={{
              py: "12px",
              px: "30px",
              height: 120,
              color: "#5A6070",
              border: "3px solid #5A6070",
              fontWeight: 700,
            }}
          >
            {" "}
            支付額報告
          </Button>
        </Grid2>
      </Grid2>
    </RootLayout>
  );
};

export default ReportIndexPage;
