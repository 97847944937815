import { Box, Stack } from "@mui/material";
import ToggleButton, { ToggleButtonProps } from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { styled } from "@mui/material/styles";
import * as React from "react";
import {
  ISelectedAttribute,
  ISelectedAttributeValue,
} from "src/interfaces/product.interfaces";

export function ProductAttributeSlectionButton(
  props: ProductAttributeSlectionButtonProps,
) {
  // PROPS DESTRUCTURE...
  const {
    attribute,
    attribute_value,
    handleSelection,
    isRequired = true,
  } = props;

  // LOCAL STATES
  const [selectedAttribute, setSelectedAttribute] = React.useState<
    string | null
  >(null);

  React.useEffect(() => {
    if (attribute_value.length) {
      const selectedAttr = attribute_value.find(
        (val) => val.isSelected === true,
      );
      if (selectedAttr) setSelectedAttribute(selectedAttr.attrVal);
      else setSelectedAttribute(null);
    }
  }, [attribute_value, selectedAttribute]);

  const handleAttributeSelection = (
    event: React.MouseEvent<HTMLElement>,
    attrVal: string,
  ) => {
    if (attrVal) {
      setSelectedAttribute(attrVal);
      const attrObj = attribute_value.filter((val) => val.attrVal === attrVal);
      if (attrObj && attrObj.length) {
        const obj = attrObj[0];
        handleSelection({
          attribute,
          attribute_value: { ...obj, isSelected: true },
        });
      }
      setSelectedAttribute(null);
    }
  };

  // console.log("attribute_value: ", attribute_value);
  // console.log("selectedAttribute: ", selectedAttribute);

  return (
    <Stack spacing={"10px"}>
      <Box sx={{ fontSize: "18px" }}>
        {attribute} {isRequired ? "*" : null}
      </Box>

      <StyledToggleButtonGroup
        size="small"
        value={selectedAttribute}
        exclusive
        onChange={handleAttributeSelection}
        aria-labelledby="attribute-selection-toggle-group"
      >
        {attribute_value.map((val, ind) => {
          if (val.is_color) {
            return (
              <StyledColorToggleButtn
                key={ind}
                value={val.attrVal}
                selected={val.attrVal === selectedAttribute ? true : false}
                aria-label={val.attrVal}
                disableRipple
                disabled={val.isDisabled}
              >
                <Box
                  className="color_box"
                  sx={{ bgcolor: val.color_description }}
                />
              </StyledColorToggleButtn>
            );
          } else {
            return (
              <StyledToggledButton
                key={ind}
                value={val.attrVal}
                selected={val.attrVal === selectedAttribute ? true : false}
                aria-label={val.attrVal}
                disableRipple
                disabled={val.isDisabled}
              >
                {val.attrVal}
              </StyledToggledButton>
            );
          }
        })}
      </StyledToggleButtonGroup>
    </Stack>
  );
}

export interface ProductAttributeSlectionButtonProps {
  attribute: string;
  attribute_value: Array<ISelectedAttributeValue>;
  isRequired?: boolean;
  handleSelection: (val: ISelectedAttribute) => void;
}

// STYLE CUSTOMIZATION...
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  "& .MuiToggleButtonGroup-grouped": {
    // margin: theme.spacing(1.2),
    marginLeft: theme.spacing(2.5),
    marginRight: theme.spacing(2.5),

    "&:first-of-type": {
      marginLeft: 0,
    },
    "&:last-of-type": {
      marginRight: 0,
    },

    // "&.MuiToggleButton-root": {
    //   background: "#FFF",
    //   padding: "17.5px 28.5px",
    //   borderRadius: "5px !important",
    //   border: "1px solid #E0E0E0 !important",
    //   color: "#5A6070",
    //   fontSize: theme.spacing(2),

    //   "&.Mui-selected": {
    //     background: "#E26F71",
    //     border: "0px solid transparent",
    //     color: "#fff",
    //     fontWeight: theme.typography.fontWeightBold,
    //   },
    //   "&.Mui-disabled": {
    //     background: theme.palette.action.disabled,
    //   },
    // },
  },
}));

const StyledToggledButton = styled(ToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    background: "#FFF",
    padding: "17.5px 28.5px",
    borderRadius: "5px !important",
    border: "1px solid #E0E0E0 !important",
    color: "#5A6070",
    fontSize: theme.spacing(2),

    "&.Mui-selected": {
      background: "#E26F71",
      border: "0px solid transparent",
      color: "#fff",
      fontWeight: theme.typography.fontWeightBold,

      "&:hover": {
        background: "#E26F71",
        border: "0px solid transparent",
        color: "#fff",
        fontWeight: theme.typography.fontWeightBold,
      },
    },
    "&.Mui-disabled": {
      background: theme.palette.action.disabled,
    },
  }),
);

const StyledColorToggleButtn = styled(ToggleButton)<ToggleButtonProps>(
  ({ theme }) => ({
    width: 43.75,
    height: 43.75,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    background: "#fff ",
    borderRadius: "50% !important",
    border: "3px solid transparent !important",
    padding: 0,

    "&.Mui-selected": {
      border: "3px solid #E26F71 !important",
    },

    "& .color_box": {
      width: theme.spacing(4),
      height: theme.spacing(4),
      borderRadius: "50%",
    },
  }),
);
