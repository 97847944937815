import * as yup from "yup";

const phoneRegex = /^(?:\s*)?(5|6|9)[0-9]{7}$/;

export const createMemberFormSchema = yup
  .object({
    customer_name: yup.string().required("此欄位必須輸入").default(""),
    customer_group: yup.string().default("All Customer Groups"),
    territory: yup.string().default("All Territories"),

    custom_country_calling_code: yup.string().default("852"),

    custom_unique_phone: yup
      .string()
      .matches(phoneRegex, "請輸入有效的電話号码")
      .default(""),

    custom_email: yup.string().email().optional().default(""),

    salutation: yup.string().default(""),
    custom_birth_month: yup.string().default(""),
    // how_discover: yup.array(),
    custom_how_to_know_us: yup
      .array()
      .of(
        yup.object().shape({
          how_to_know_us: yup.string(),
        }),
      )
      .default([]),
  })
  .required();
