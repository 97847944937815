import React from "react";
import {
  AccountBox,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "../ui-library";
import { useNavigate } from "react-router-dom";

interface AlertDialogProps {
  isModalOpen: boolean;
  isAddSuccessModal?: boolean;
  title: string;
  content: string;
  handleModalClose: () => void;
  handleRedirectAfterSuccess?: () => void;
}

const AlertDialog = (props: AlertDialogProps) => {
  // DESTRUCTURE PROPS...
  const {
    isModalOpen,
    isAddSuccessModal,
    title,
    content,
    handleModalClose,
    handleRedirectAfterSuccess,
  } = props;

  // INITIALIZE...
  const navigate = useNavigate();

  const handleClose = (event: any, reason?: any) => {
    if (reason && reason === "backdropClick") return;
    handleModalClose();
  };

  const handleBackToHome = () => {
    handleModalClose();
    navigate("/");
  };

  return (
    <Dialog
      open={isModalOpen}
      onClose={handleClose}
      className={`alert__dialog ${isAddSuccessModal && "add-success-modal"}`}
    >
      {isAddSuccessModal && (
        <DialogTitle id="alert-dialog-title">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40"
            height="39"
            viewBox="0 0 40 39"
            fill="none"
          >
            <path
              d="M9.57886 27.1409C9.57886 23.8076 16.2455 21.9743 19.5789 21.9743C22.9122 21.9743 29.5789 23.8076 29.5789 27.1409V28.8076H9.57886M24.5789 13.8076C24.5789 15.1337 24.0521 16.4055 23.1144 17.3432C22.1767 18.2808 20.9049 18.8076 19.5789 18.8076C18.2528 18.8076 16.981 18.2808 16.0433 17.3432C15.1056 16.4055 14.5789 15.1337 14.5789 13.8076C14.5789 12.4815 15.1056 11.2098 16.0433 10.2721C16.981 9.3344 18.2528 8.80762 19.5789 8.80762C20.9049 8.80762 22.1767 9.3344 23.1144 10.2721C24.0521 11.2098 24.5789 12.4815 24.5789 13.8076ZM4.57886 7.14095V30.4743C4.57886 31.3583 4.93005 32.2062 5.55517 32.8313C6.18029 33.4564 7.02814 33.8076 7.91219 33.8076H31.2455C32.1296 33.8076 32.9774 33.4564 33.6025 32.8313C34.2277 32.2062 34.5789 31.3583 34.5789 30.4743V7.14095C34.5789 6.2569 34.2277 5.40905 33.6025 4.78393C32.9774 4.15881 32.1296 3.80762 31.2455 3.80762H7.91219C7.02814 3.80762 6.18029 4.15881 5.55517 4.78393C4.93005 5.40905 4.57886 6.2569 4.57886 7.14095Z"
              fill="#45A492"
            />
          </svg>
        </DialogTitle>
      )}
      <DialogContent className="alert__dialog-content">
        <h2>{title}</h2>
        <p>{content}</p>
      </DialogContent>

      <DialogActions sx={{ justifyContent: "center" }}>
        <Button variant="contained" onClick={handleRedirectAfterSuccess}>
          {isAddSuccessModal && <AccountBox />}
          {isAddSuccessModal ? "為此客人用戶選購產品" : "確定"}
        </Button>

        {isAddSuccessModal && (
          <Button variant="outlined" color="info" onClick={handleBackToHome}>
            返回主頁
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default AlertDialog;
