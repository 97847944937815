export const invoiceStatusData = [
  {
    label: "全部",
    value: "",
  },
  {
    label: "草稿",
    value: "0",
  },
  {
    label: "已下單",
    value: "1",
  },
  {
    label: "已退單",
    value: "2",
  },
  {
    label: "HOLD單",
    value: "3",
  },
];
