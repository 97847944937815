import {
  Box,
  Button,
  Dialog,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "@mui/material";
import { Fragment, useEffect } from "react";
import DefaultLoaderContainer from "src/components/loader";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { useGetInvoiceByIdMutation } from "src/redux/api/saleAndInvoiceApi";
import {
  generateRemoteMediaUrl,
  handleWordingOfAbandonFurniture,
} from "src/utils/common";

// ============================ || SELECTED INVOICE PREVIEW DIALOG || ====================================//
export interface InvoicePreviewDialogProps {
  open: boolean;
  invoice_name: string;
  handleModalClose: () => void;
  onSubmit: (invoice: IInvoiceDetail) => void;
}
export const InvoicePreviewDialog = (props: InvoicePreviewDialogProps) => {
  const { handleModalClose, open, onSubmit, invoice_name } = props;

  // REDUX THINGS..
  const [
    getInvoiceById,
    {
      data: invoiceData,
      isLoading: getInvoiceLoading,
      isError: isNewInvoiceError,
      isSuccess,
      error: newInvoiceError,
    },
  ] = useGetInvoiceByIdMutation();

  // INIT STATE CHECKS...
  useEffect(() => {
    getInvoiceById({ invoice_name });
  }, [invoice_name]);

  const handleSubmit = () => {
    if (invoiceData && invoiceData.data) {
      onSubmit(invoiceData.data);
    }
  };

  // HANDLE IMAGE LOAD ERROR
  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  return (
    <Dialog
      aria-labelledby="select-return-person-dialog"
      maxWidth="lg"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
          請確認轉單是否正確
        </Typography>

        {/* render invoice details if its has no error... */}
        {isSuccess && invoiceData && invoiceData.data.name ? (
          <Box sx={{ my: "30px" }}>
            <DefaultLoaderContainer
              isLoading={getInvoiceLoading}
              minHeight={"10vh"}
            >
              <Box
                className="check__info__page__content checkout__process"
                display="flex"
                flexDirection="column"
                gap="20px"
                marginTop="20px"
              >
                <Box flexGrow={1} aria-labelledby="invoice-name">
                  <Stack
                    spacing={"10px"}
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                  >
                    <Box
                      component="img"
                      src="/icons/docs.svg"
                      width={40}
                      height={40}
                      alt="doc icon"
                    />

                    <Typography fontSize={"30px"} fontWeight={700}>
                      {`銷售單#${invoiceData?.data?.name ?? ""} - ${
                        invoiceData?.data?.posting_date ?? ""
                      }`}
                    </Typography>
                  </Stack>
                </Box>

                {/* SALE PERSON INFORMATION SECTION... */}
                <Box className="salesperson__info">
                  <Stack
                    direction="row"
                    spacing={1}
                    py="20.5px"
                    px="30px"
                    borderRadius="5px 5px 0 0"
                    bgcolor="#5A6070"
                    width="fit-content"
                    color="#fff"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src="/icons/sale-girl.svg"
                        alt="sale-girl"
                        width={24}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                    <Typography fontSize={18} fontWeight={700}>
                      銷售員信息
                    </Typography>
                  </Stack>
                  <Box
                    padding="30px 20px 20px 20px"
                    borderRadius="0 5px 5px 5px"
                    border="1px solid #E0E0E0"
                    bgcolor="#fff"
                  >
                    <Grid container spacing={5}>
                      <Grid item xs={6}>
                        <InputLabel>主銷售員</InputLabel>
                        <Typography>
                          {
                            invoiceData?.data?.sales_team?.[0]
                              ?.sales_person_name
                          }
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>其他銷售員</InputLabel>
                        <Typography>
                          {
                            invoiceData?.data?.sales_team?.[1]
                              ?.sales_person_name
                          }
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {/* PRODUCT INFORMATION SECTION... */}
                <Box className="product__info">
                  <Stack
                    direction="row"
                    spacing={1}
                    py="20.5px"
                    px="30px"
                    borderRadius="5px 5px 0 0"
                    bgcolor="#5A6070"
                    width="fit-content"
                    color="#fff"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src="/icons/info.svg"
                        alt="sale-girl"
                        width={24}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                    <Typography fontSize={18} fontWeight={700}>
                      商品信息
                    </Typography>
                  </Stack>

                  <Box
                    display="flex"
                    flexDirection="column"
                    gap="20px"
                    padding="20px 20px 20px 20px"
                    borderRadius="0 5px 5px 5px"
                    border="1px solid #E0E0E0"
                    bgcolor="#fff"
                    className="MuiGrid-item"
                  >
                    <Stack direction="row" alignItems="center">
                      <InputLabel>供應商</InputLabel>&nbsp;
                      <Typography sx={{ padding: "0px !important" }}>
                        {invoiceData?.data?.supplier || "-"}
                      </Typography>
                    </Stack>

                    <Box aria-label="product-table">
                      <Stack
                        aria-label="header-and-data-section"
                        spacing={5}
                        sx={{
                          border: "1.125px solid #E0E0E0",
                          borderRadius: "5px",
                          padding: "20px 12px",
                        }}
                      >
                        <Grid
                          width={"100%"}
                          container
                          aria-label="table-header"
                          columnSpacing={3}
                          sx={{
                            height: 70,
                            fontWeight: 500,
                            fontSize: 20,
                            borderBottom: "1px solid #BDBDBD",
                            py: "10px",
                            "& .table_header": {
                              "&:not(:last-child)": {
                                borderRight: "1px solid #BDBDBD",
                              },
                            },
                          }}
                        >
                          <Grid item xs={6} className="table_header">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              商品資料
                            </Box>
                          </Grid>

                          <Grid item xs={2} className="table_header">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              單價(HKD)
                            </Box>
                          </Grid>

                          <Grid item xs={2} className="table_header">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              折扣(HKD)
                            </Box>
                          </Grid>

                          <Grid item xs={2} className="table_header">
                            <Box
                              display="flex"
                              justifyContent="center"
                              alignItems="center"
                              height="100%"
                            >
                              金額(HKD)
                            </Box>
                          </Grid>
                        </Grid>

                        {/* START: Table content (Product) */}
                        {invoiceData?.data?.items?.map(
                          (product, index: number) => (
                            <Grid
                              key={`invoice-item-${index}`}
                              width={"100%"}
                              container
                              columnSpacing={3}
                              aria-label={`product-item-section`}
                              sx={{
                                "& .MuiGrid-item": {
                                  "&:not(:last-child)": {
                                    borderRight: "1px solid #BDBDBD",
                                  },
                                },
                              }}
                            >
                              <Grid item xs={6}>
                                <Box
                                  display="flex"
                                  alignItems="center"
                                  gap="20px"
                                  aria-label="invoice-product-item"
                                >
                                  <img
                                    src={generateRemoteMediaUrl(
                                      product.custom_template_thumbnail,
                                    )}
                                    alt={product.item_name ?? "product image"}
                                    width={158.625}
                                    style={{ objectFit: "contain" }}
                                    onError={handleImageError}
                                  />

                                  <Stack gap="10px">
                                    <Box
                                      display="flex"
                                      flexDirection="column"
                                      gap="5px"
                                    >
                                      <Typography
                                        sx={{
                                          padding: "0 !important",
                                        }}
                                      >
                                        {product?.item_code || "-"}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          fontSize: "20px !important",
                                          fontWeight: "700 !important",
                                          padding: "0 !important",
                                        }}
                                      >
                                        {product?.item_name || "-"}
                                      </Typography>
                                      <Typography
                                        sx={{
                                          padding: "0 !important",
                                        }}
                                      >
                                        {product.custom_width ? (
                                          <span>
                                            {product.custom_width}cm(W)
                                          </span>
                                        ) : null}
                                        {product.custom_height ? (
                                          <span>
                                            {" "}
                                            x {product.custom_height}cm(H)
                                          </span>
                                        ) : null}
                                        {product.custom_size_remarks ? (
                                          <span>
                                            [{product.custom_size_remarks}]
                                          </span>
                                        ) : null}
                                      </Typography>
                                    </Box>
                                    <Typography
                                      sx={{
                                        padding: "0 !important",
                                        fontSize: "18px !important",
                                      }}
                                    >
                                      {product?.sales_invoice_custom_item_remarks ||
                                        "-"}
                                    </Typography>
                                    {product.attributes.length ? (
                                      <Box
                                        sx={{
                                          display: "flex",
                                          flexDirection: "column",
                                          padding: "8px 0px",
                                        }}
                                      >
                                        {product.attributes.map(
                                          (attr, index: number) => {
                                            return (
                                              <Typography
                                                id={attr.attribute}
                                                key={`product-attribute-${index}`}
                                                sx={{
                                                  display: "inline-flex",
                                                  flexDirection: "row",
                                                  padding: "0 !important",
                                                  fontSize: "15px !important",
                                                }}
                                              >
                                                <Box component="span">
                                                  {attr.attribute}:{" "}
                                                </Box>
                                                <Box
                                                  component="span"
                                                  fontWeight={400}
                                                >
                                                  {attr.attribute_value}
                                                </Box>
                                              </Typography>
                                            );
                                          },
                                        )}
                                      </Box>
                                    ) : (
                                      <Typography
                                        sx={{
                                          padding: "8px 0 !important",
                                          fontSize: "15px !important",
                                          fontWeight: "500 !important",
                                        }}
                                      >
                                        產品沒有選項
                                      </Typography>
                                    )}

                                    <Typography
                                      sx={{
                                        padding: "0 !important",
                                        fontSize: "20px !important",
                                        fontWeight: "700 !important",
                                      }}
                                    >
                                      x{product?.qty || 1}
                                    </Typography>
                                  </Stack>
                                </Box>
                              </Grid>

                              <Grid item xs={2}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  alignItems="center"
                                  justifyContent="center"
                                  height="100%"
                                >
                                  <Typography
                                    sx={{
                                      padding: "0 !important",
                                      fontSize: "14px !important",
                                      fontWeight: "500 !important",
                                      textDecorationLine: "line-through",
                                    }}
                                  >
                                    $ {product?.custom_original_price || null}
                                  </Typography>

                                  <Typography
                                    sx={{
                                      padding: "0 !important",
                                      fontSize: "14px !important",
                                      fontStyle: "italic !important",
                                      color: "#DC2427 !important",
                                    }}
                                  >
                                    {product?.custom_markup_discount}% off
                                  </Typography>

                                  <InputLabel>$ {product?.rate}</InputLabel>
                                </Box>
                              </Grid>

                              <Grid item xs={2}>
                                <Box
                                  display="flex"
                                  flexDirection="column"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                >
                                  {product.applied_coupons.length ? (
                                    <Stack spacing={1}>
                                      {product.applied_coupons.map(
                                        (d, index) => {
                                          return (
                                            <Box
                                              key={`${d.coupon_code}-coupon-${index}`}
                                            >
                                              <Typography
                                                sx={{
                                                  padding: "0 !important",
                                                  fontSize: "20px !important",
                                                  fontWeight: "700 !important",
                                                }}
                                              >
                                                -$ {d.amount}
                                              </Typography>
                                              <Button
                                                sx={{
                                                  background: "#828282",
                                                  color: "#fff !important",
                                                }}
                                                disabled
                                              >
                                                {d.coupon_name}
                                              </Button>
                                            </Box>
                                          );
                                        },
                                      )}
                                    </Stack>
                                  ) : (
                                    <>-</>
                                  )}
                                </Box>
                              </Grid>

                              <Grid item xs={2}>
                                <Box
                                  display="flex"
                                  justifyContent="center"
                                  alignItems="center"
                                  height="100%"
                                >
                                  <Typography
                                    sx={{
                                      padding: "0 !important",
                                      fontSize: "24px !important",
                                      fontWeight: "700 !important",
                                    }}
                                  >
                                    {product.custom_discounted_amount}
                                  </Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          ),
                        )}
                        {/* END: Table content (Product) */}
                      </Stack>
                    </Box>

                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="20px"
                      padding="30px 20px 20px 20px"
                      border="1.13px solid #E0E0E0"
                      borderRadius="5px"
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <InputLabel>產品累計</InputLabel>
                        <Typography sx={{ padding: "0px !important" }}>
                          ${" "}
                          {invoiceData?.data
                            ?.custom_total_item_discounted_amount || "-"}
                        </Typography>
                      </Stack>
                      {invoiceData?.data?.applied_coupons?.map(
                        (coupon, index: number) => (
                          <Stack
                            key={`applied-coupon-${index}`}
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                          >
                            <InputLabel>全單折扣</InputLabel>
                            <Box
                              sx={{
                                display: "flex",
                                gap: "60px",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                sx={{
                                  background: "#828282",
                                  color: "#fff !important",
                                }}
                                disabled
                              >
                                {coupon?.coupon_name}
                              </Button>
                              <Typography sx={{ padding: "0px !important" }}>
                                -$ {coupon?.amount}
                              </Typography>
                            </Box>
                          </Stack>
                        ),
                      )}
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <InputLabel>全單總計</InputLabel>
                        <Typography sx={{ padding: "0px !important" }}>
                          $ {invoiceData?.data?.rounded_total}
                        </Typography>
                      </Stack>
                    </Box>
                  </Box>
                </Box>

                {/* PAYMENT INFORMATION SECTION... */}
                <Box className="payment__info">
                  <Stack
                    direction="row"
                    spacing={1}
                    py="20.5px"
                    px="30px"
                    borderRadius="5px 5px 0 0"
                    bgcolor="#5A6070"
                    width="fit-content"
                    color="#fff"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src="/icons/payment-card.svg"
                        alt="payment-card"
                        width={24}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                    <Typography fontSize={18} fontWeight={700}>
                      付款信息
                    </Typography>
                  </Stack>

                  <Stack
                    spacing={2.5}
                    padding="20px 20px 20px 20px"
                    borderRadius="0 5px 5px 5px"
                    border="1px solid #E0E0E0"
                    bgcolor="#fff"
                  >
                    <Box
                      display="flex"
                      flexDirection="column"
                      gap="20px"
                      padding="30px 20px 20px 20px"
                      borderRadius="5px"
                      border="1px solid #E0E0E0"
                      className="MuiGrid-item"
                    >
                      <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ padding: "0px !important" }}>
                          全單總計
                        </Typography>
                        <InputLabel>
                          $ {invoiceData?.data?.rounded_total}
                        </InputLabel>
                      </Stack>

                      <Divider />

                      {invoiceData?.data?.payments &&
                        invoiceData.data.payments.map((payment, index) => (
                          <Fragment key={`payment-${index}`}>
                            <Stack direction="row">
                              <Typography sx={{ padding: "0px !important" }}>
                                <Box component={"span"} mr={1}>
                                  付款於
                                </Box>
                                <Box component={"span"}>
                                  {payment?.date ?? "-"}
                                </Box>
                              </Typography>
                            </Stack>

                            <Stack
                              direction="row"
                              justifyContent="space-between"
                            >
                              <Button
                                sx={{
                                  background: "#828282",
                                  color: "#fff !important",
                                }}
                                disabled
                              >
                                {payment.mode_of_payment ?? "-"}
                              </Button>
                              <InputLabel>
                                -$ {payment?.total_allocated_amount ?? 0}
                              </InputLabel>
                            </Stack>

                            <Stack direction="row" alignItems="center">
                              <InputLabel>備註 : </InputLabel>&nbsp;
                              <Typography sx={{ padding: "0px !important" }}>
                                {payment.custom_payment_remarks || "-"}
                              </Typography>
                            </Stack>
                          </Fragment>
                        ))}
                      <Divider />
                      <Stack direction="row" justifyContent="space-between">
                        <Typography sx={{ padding: "0px !important" }}>
                          餘額
                        </Typography>
                        <InputLabel>
                          $ {invoiceData?.data?.outstanding_total}
                        </InputLabel>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>

                {/* MEMBER INFORMATION SECTION... */}
                <Box className="member__info">
                  <Stack
                    direction="row"
                    spacing={1}
                    py="20.5px"
                    px="30px"
                    borderRadius="5px 5px 0 0"
                    bgcolor="#5A6070"
                    width="fit-content"
                    color="#fff"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src="/icons/account-box.svg"
                        alt="account-box"
                        width={24}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                    <Typography fontSize={18} fontWeight={700}>
                      會員
                    </Typography>
                  </Stack>
                  <Box
                    padding="30px 20px 20px 20px"
                    borderRadius="0 5px 5px 5px"
                    border="1px solid #E0E0E0"
                    bgcolor="#fff"
                  >
                    <Grid container rowGap="20px">
                      <Grid item xs={6}>
                        <InputLabel>會員編號</InputLabel>
                        <Typography>
                          {invoiceData?.data?.customer?.name}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>生日月份</InputLabel>
                        <Typography>
                          {invoiceData?.data?.customer?.custom_birth_month}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>稱謂</InputLabel>
                        <Typography>
                          {invoiceData?.data?.customer?.salutation || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>姓名</InputLabel>
                        <Typography>
                          {invoiceData?.data?.customer?.customer_name || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>電郵地址</InputLabel>
                        <Typography>
                          {invoiceData?.data?.customer?.custom_email || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>手機號碼</InputLabel>
                        <Typography>
                          {invoiceData?.data?.customer?.custom_unique_phone ||
                            "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>

                {/* SHIPPING INFORMATION SECTION... */}
                <Box className="shipping__info">
                  <Stack
                    direction="row"
                    spacing={1}
                    py="20.5px"
                    px="30px"
                    borderRadius="5px 5px 0 0"
                    bgcolor="#5A6070"
                    width="fit-content"
                    color="#fff"
                    alignItems="center"
                  >
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                    >
                      <img
                        src="/icons/shipping-truck.svg"
                        alt="shipping-truck"
                        width={24}
                        style={{ objectFit: "contain" }}
                      />
                    </Box>
                    <Typography fontSize={18} fontWeight={700}>
                      送貨資料
                    </Typography>
                  </Stack>

                  <Stack
                    spacing={2.5}
                    padding="30px 20px 20px 20px"
                    borderRadius="0 5px 5px 5px"
                    border="1px solid #E0E0E0"
                    bgcolor="#fff"
                  >
                    <Grid container rowGap="20px">
                      <Grid item container>
                        <Grid item xs={3}>
                          <InputLabel>收貨日期</InputLabel>
                          <Typography>
                            {invoiceData?.data?.delivery
                              ?.custom_delivery_date || "-"}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>需要棄置舊傢具</InputLabel>
                          <Typography>
                            {handleWordingOfAbandonFurniture(
                              invoiceData?.data?.delivery
                                ?.custom_discard_old_furniture,
                            )}
                          </Typography>
                        </Grid>
                        <Grid item xs={3}>
                          <InputLabel>棄置產品費(HK$)</InputLabel>
                          <Typography>
                            {invoiceData?.data?.delivery?.custom_discard_fee ||
                              "-"}
                          </Typography>
                        </Grid>

                        <Grid item xs={3}>
                          {" "}
                        </Grid>

                        <Grid item xs={12} my={2.5}>
                          <Divider />
                        </Grid>
                      </Grid>

                      <Grid item xs={3}>
                        <InputLabel>收貨人姓名</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_recipient_name ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel>收貨人電話</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_phone || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel>收貨人其他電話</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_second_phone ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>區域</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_region || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>地區</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_district || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>街道號碼及屋苑大廈</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_address_line1 ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel>樓層及單位</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_address_line2 ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel>上樓費(HK$)</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_stairs_fee ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel>推路費(HK$)</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_cart_fee || "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={3}>
                        <InputLabel>偏遠地區(HK$)</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery?.custom_remote_fee ||
                            "-"}
                        </Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <InputLabel>地址備註</InputLabel>
                        <Typography>
                          {invoiceData?.data?.delivery
                            ?.custom_address_remarks || "-"}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Stack>
                </Box>
              </Box>
            </DefaultLoaderContainer>
          </Box>
        ) : (
          <Box>
            <Typography fontWeight={600} fontSize={18} color="error.main">
              發生錯誤
            </Typography>
            <Box mt={2}>{JSON.stringify(newInvoiceError, null, 2)}</Box>
          </Box>
        )}

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={2.5}
          color="grey.500"
        >
          <Button
            variant="outlined"
            onClick={() => handleModalClose()}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="inherit"
          >
            取消
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            disabled={isNewInvoiceError}
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
