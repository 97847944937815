import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  StackProps,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { CustTextField } from "src/components/form-components/FormInput";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { IPaymentMode } from "src/interfaces/payment-method.interfaces";
import { useGetPaymentMethodsMutation } from "src/redux/api/saleAndInvoiceApi";
import { StyledAddPaymentButton } from "src/styles/StyledButtons";

const InvoicePaymentBoxForRefund = (props: InvoicePaymentBoxProps) => {
  const { handleChange, invoiceDetail, isPaymentBTNShowing, ...stackProps } =
    props;

  // LOCAL STATES...
  const [isPaymentBtnOn, setIsPaymentBtnOn] = useState(true);
  const [selectedAddFundOption, setSelecteAddFundOption] = useState<{
    [key: string]: boolean;
  }>({});
  const [remarksInput, setRemarksInput] = useState("");
  const [selectedPaymentObj, setSelectedPaymentObj] = useState<IPaymentMode>({
    mode_of_payment: "",
    type: "",
    amount: 0,
  });
  const [selectedPaymentErrorObj, setSelectedPaymentErrorObj] = useState({
    amount: "",
    mode_of_payment: "",
    type: "",
  });
  const [selectedPaymentObjList, setSelectedPaymentObjList] = useState<
    Array<IPaymentMode>
  >([]);

  // REDUX THINGS...
  const [getPaymentMethods, { data: paymentMethods }] =
    useGetPaymentMethodsMutation();

  const totalOutstandingAmt =
    (invoiceDetail && invoiceDetail?.return_outstanding_total) ?? 0;

  // LOAD PAYMENT METHOD LISTS...
  useEffect(() => {
    getPaymentMethods({ filters: '[["custom_eligible_for_refund", "=", 1]]' });
  }, []);

  useEffect(() => {
    isPaymentBTNShowing(isPaymentBtnOn);
  }, [isPaymentBtnOn]);

  useEffect(() => {
    let error = { ...selectedPaymentErrorObj };
    if (!selectedPaymentObj.amount) {
      error.amount = "*請選擇金額";
    } else {
      error.amount = "";
    }

    setSelectedPaymentErrorObj((prevVal) => {
      return { ...prevVal, ...error };
    });
  }, [selectedPaymentObj]);

  // HANDLE PAYMENT METHOD SELECTION...
  const handleModeOfPaymentSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    mode_of_payment: string,
  ) => {
    setSelectedPaymentErrorObj((prevState) => {
      return { ...prevState, mode_of_payment: "" };
    });
    const obj = paymentMethods?.data.filter(
      (v) => v.mode_of_payment === mode_of_payment,
    );

    if (obj && obj.length) {
      const ob = obj[0];
      setSelectedPaymentObj((prevState) => {
        return {
          ...prevState,
          mode_of_payment: ob.mode_of_payment,
          type: ob.type,
        };
      });
    }
  };

  // HANDLE ADD AMOUNT PERCENTAGE...
  const handleAddAmountPercentageOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const { name } = e.target;
    if (checked === true) {
      const obj = addFundPercentOptions.filter((o) => o.label === name);

      if (obj.length) {
        const percent = obj[0].value;
        setSelectedPaymentObj((prevState) => {
          return {
            ...prevState,
            amount: Math.round(totalOutstandingAmt * (percent / 100)),
          };
        });
      }
    }

    setSelecteAddFundOption((prev) => {
      return { [name]: checked };
    });
  };

  // HANDLE PAYMENT METHOD SELECTION CONFIRM...
  const handlePMSelection = (isConfirmed = false) => {
    // reset error to empty
    let error = { amount: "", mode_of_payment: "", type: "" };
    setSelectedPaymentErrorObj(error);

    if (isConfirmed === true) {
      if (!selectedPaymentObj.mode_of_payment)
        error.mode_of_payment = "*請選擇付款方式";
      if (!selectedPaymentObj.amount) error.amount = "*請選擇金額";

      if (error.amount || error.mode_of_payment || error.type) {
        setSelectedPaymentErrorObj(() => {
          return { ...error };
        });
        return;
      }

      const oldState = selectedPaymentObjList;
      const index = selectedPaymentObjList.findIndex(
        (v) => v.mode_of_payment === selectedPaymentObj.mode_of_payment,
      );

      if (index >= 0) {
        oldState.splice(index, 1, selectedPaymentObj);
        setSelectedPaymentObjList(oldState);

        handleChange({
          paymentMethodList: oldState,
          remarks: remarksInput,
        });
      } else {
        setSelectedPaymentObjList((prevState) => {
          return [...prevState, selectedPaymentObj];
        });

        handleChange({
          paymentMethodList: [...oldState, selectedPaymentObj],
          remarks: remarksInput,
        });
      }

      // RESETTING PREV STATES...
      setSelectedPaymentObj({ amount: 0, mode_of_payment: "", type: "" });
      setSelecteAddFundOption({ dot: false });
      setIsPaymentBtnOn(true);
    } else {
      setSelectedPaymentObj({ amount: 0, mode_of_payment: "", type: "" });
      setSelectedPaymentErrorObj({
        amount: "",
        mode_of_payment: "",
        type: "",
      });
      setIsPaymentBtnOn(true);
    }
  };

  // GET BALANCE AMOUNT AFTER APPLYING PAYMENT METHOD AND PAYMENT AMOUNT...
  const getBalanceAmount = () => {
    if (selectedPaymentObjList.length) {
      const t = selectedPaymentObjList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0,
      );

      return `$ ${totalOutstandingAmt - t}`;
    } else return `$ ${totalOutstandingAmt}`;
  };

  // HANDLE REMOVE PAYMENT METHOD...
  const handleRemovePaymentMethod = (item: IPaymentMode) => {
    const oldState = selectedPaymentObjList;
    const index = oldState.findIndex(
      (v) => v.mode_of_payment === item.mode_of_payment,
    );

    if (index >= 0) {
      oldState.splice(index, 1);
      setSelectedPaymentObjList((prevState) => {
        return [...oldState];
      });

      handleChange({
        paymentMethodList: [...oldState],
        remarks: remarksInput,
      });
    }
  };

  return (
    <Stack
      aria-label="invoice-payment-return-box"
      spacing={"25px"}
      p={2.5}
      bgcolor="#fff"
      {...stackProps}
    >
      {/* TOP PAYMENT INFORMATION SECTION */}
      {isPaymentBtnOn ? (
        <Box aria-label="payment info section">
          {/* TOTAL PAYMENT SECTION... */}
          <Box
            aria-label="total-payment-container"
            sx={{
              p: 2.5,
              pt: "30px",
              borderRadius: "5px",
              border: "1.13px solid #E0E0E0",
            }}
          >
            <Stack
              direction={"row"}
              spacing={2}
              justifyContent={"space-between"}
              pb={2.5}
              borderBottom={(theme) => `1px solid ${theme.palette.grey[500]}`}
            >
              <Typography fontSize={20}>客戶己付金額</Typography>
              <Typography fontSize={25} fontWeight={700}>
                $ {invoiceDetail?.paid_total}
              </Typography>
            </Stack>

            <Box aria-label="selected-payment-info">
              {selectedPaymentObjList.length > 0 ? (
                <Stack mt="20px" spacing={2.5}>
                  {selectedPaymentObjList.map((obj, ind) => {
                    return (
                      <Box
                        key={ind}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack spacing="10px" direction="row">
                          <Box
                            sx={{
                              borderRadius: "5px",
                              background: "#45A492",
                              p: "12px 29.72px",
                              color: "#fff",
                              fontSize: 18,
                            }}
                          >
                            {obj.mode_of_payment}
                          </Box>

                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#333",
                              color: "#fff",
                              "&:hover": {
                                bgcolor: "#333",
                              },
                            }}
                            onClick={() => {
                              handleRemovePaymentMethod(obj);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Stack>

                        <Typography fontSize={25} fontWeight={700} pr={"18px"}>
                          -$ {obj.amount}
                        </Typography>
                      </Box>
                    );
                  })}
                </Stack>
              ) : null}
            </Box>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              mt={"15px"}
              pt={2.5}
              borderTop={(theme) => `1px solid ${theme.palette.grey[500]}`}
            >
              <Typography fontSize={20}>剩餘已付金額</Typography>
              <Typography fontSize={30} fontWeight={700} mr={2}>
                {/* ${invoiceDetail?.return_outstanding_total} */}
                {getBalanceAmount()}
              </Typography>
            </Box>
          </Box>
        </Box>
      ) : null}

      {/* PAYMENT OPTIONS SECTION... */}
      <Box aria-labelledby="payment-method-options-container">
        {isPaymentBtnOn ? (
          <StyledAddPaymentButton
            variant={"contained"}
            fullWidth
            startIcon={<AddCircleIcon />}
            onClick={() => setIsPaymentBtnOn(false)}
          >
            新增付款方式
          </StyledAddPaymentButton>
        ) : (
          <Stack
            spacing={2.5}
            aria-labelledby="payment-method-selection"
            sx={{
              p: 2.5,
              pt: "30px",
            }}
          >
            {paymentMethods && paymentMethods.data.length >= 1 ? (
              <FormControl>
                <FormLabel
                  id="payment--method-selection-buttons-group-label"
                  sx={{
                    fontWeight: 700,
                    mb: "9px",
                    color: "#333 !important",
                  }}
                >
                  付款方式
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="payment-option-selection-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedPaymentObj.mode_of_payment}
                  onChange={handleModeOfPaymentSelection}
                  sx={{ gap: "9px" }}
                >
                  {paymentMethods.data.map((method, ind) => {
                    return (
                      <StyledFormControlLabel
                        key={`${method}-${ind}`}
                        value={method.mode_of_payment}
                        control={<Radio />}
                        label={method.mode_of_payment}
                        checked={
                          selectedPaymentObj.mode_of_payment ===
                          method.mode_of_payment
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            ) : null}

            {/* PAYMENT METHOD NOT SELECTED ERROR... */}
            {selectedPaymentErrorObj.mode_of_payment ? (
              <Typography fontWeight={700} fontSize={18} color="#DC2427">
                {selectedPaymentErrorObj.mode_of_payment}
              </Typography>
            ) : null}

            {/* FUND INPUT... */}
            <Stack spacing="8.33px">
              <Typography fontWeight={700} fontSize={15}>
                金額(HK$)
              </Typography>

              <Stack direction="row" spacing="9px">
                <CustTextField
                  name="amount"
                  placeholder="100"
                  type="number"
                  value={selectedPaymentObj.amount}
                  onChange={(e) => {
                    setSelectedPaymentObj((prevState) => {
                      return {
                        ...prevState,
                        amount: Number(e.target.value),
                      };
                    });
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 10px 10px 20px",
                      height: 40,
                      width: 345,
                    },
                  }}
                />

                {defaultAddFundOptions.map((fund, ind) => {
                  return (
                    <Button
                      key={ind}
                      color="secondary"
                      variant="contained"
                      sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        lineHeight: "24.874px",
                        p: "15px",
                      }}
                      onClick={() => {
                        setSelectedPaymentObj((prevState) => {
                          return {
                            ...prevState,
                            amount: prevState.amount + fund,
                          };
                        });
                      }}
                    >
                      + {fund}
                    </Button>
                  );
                })}

                <Button
                  color="inherit"
                  variant="outlined"
                  disableElevation
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    lineHeight: "24.874px",
                    p: "15px",
                    borderColor: "#5A6070",
                    color: "#5A6070",
                  }}
                  onClick={() =>
                    setSelectedPaymentObj((prevState) => {
                      return { ...prevState, amount: 0 };
                    })
                  }
                >
                  重設
                </Button>
              </Stack>

              <Stack direction="row" spacing="9px">
                {addFundPercentOptions.map((fund, ind) => {
                  return (
                    <FormControlLabel
                      key={ind}
                      control={
                        <Checkbox
                          name={fund.label}
                          checked={selectedAddFundOption[fund.label] ?? false}
                          onChange={handleAddAmountPercentageOption}
                        />
                      }
                      label={fund.label}
                    />
                  );
                })}
              </Stack>
            </Stack>

            {/* AMOUNT NOT SELECTED ERROR... */}
            {selectedPaymentErrorObj.amount ? (
              <Typography fontWeight={700} fontSize={18} color="#DC2427">
                {selectedPaymentErrorObj.amount}
              </Typography>
            ) : null}

            {/* CONFIRM FUND... */}
            <Box display="flex" justifyContent="flex-end">
              <Stack spacing={"10px"} direction="row">
                <Button
                  variant="outlined"
                  onClick={() => handlePMSelection(false)}
                  sx={{ p: "15px" }}
                >
                  取消
                </Button>

                <Button
                  variant="contained"
                  onClick={() => handlePMSelection(true)}
                  sx={{ p: "15px", minWidth: 120 }}
                >
                  下一步
                </Button>
              </Stack>
            </Box>
          </Stack>
        )}
      </Box>
    </Stack>
  );
};

export default InvoicePaymentBoxForRefund;

// TYPE DECLERATION...
export interface InvoicePaymentBoxProps extends StackProps {
  invoiceDetail: IInvoiceDetail | null;
  handleChange: (payload: {
    paymentMethodList: Array<IPaymentMode>;
    remarks: string;
  }) => void;
  isPaymentBTNShowing: (isShowing: boolean) => void;
}

// STYLE...
export const StyledFormControlLabel = styled(
  FormControlLabel,
)<FormControlLabelProps>(({ theme, checked }) => ({
  padding: "15px",
  borderRadius: "5px",
  border: `1px solid ${checked ? "transparent" : "#E0E0E0"} `,
  margin: 0,
  background: checked ? "#45A492" : "#fff",
  color: checked ? "#fff" : "#828282",
  "& .Mui-checked": {
    color: "#fff",
  },
}));

// CONSTANT DATA...
const defaultAddFundOptions = [50, 100, 500];
const addFundPercentOptions = [
  { label: "付3成", value: 30 },
  { label: "付五成", value: 50 },
  { label: "付七成", value: 70 },
  { label: "付全數", value: 100 },
];
