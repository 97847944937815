// INVOICE CREATION STEP LOCAL_STORAGE KEYS...
export const INVOICE_CREATION_STEPS_KEYS = {
  PAYMENT_INFO: "PAYMENT_INFO",
};

// USER RELATED KEYS...
export const USER_RELATED_KEYS = {
  AUTH_KEY: "AuthKey",
  BRANCH_NAME: "BRANCH_NAME",
  USER_LOGIN_STATUS: "USER_LOGIN_STATUS",
};
