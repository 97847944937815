export const salutationData = [
  {
    label: "小姐",
    value: "小姐",
  },
  {
    label: "先生",
    value: "先生",
  },
];
