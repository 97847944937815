import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import invoiceRoutes from "src/constants/routes/invoice.routes";
import memberRoutes from "src/constants/routes/member.routes";
import { getInvoiceStatusDetails } from "src/helpers/invoiceStatus";
import { useGetInvoiceByIdMutation } from "src/redux/api/saleAndInvoiceApi";
import {
  setInvoiceOrderBy,
  setIsInvoiceDrawerOpen,
  setPage,
  setRowsPerPage,
} from "src/redux/features/invoiceSlice";
import {
  setSelectedInvoiceId,
  setSelectedMember,
} from "src/redux/features/memberSlice";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  visuallyHidden,
} from "../../ui-library";

interface Data {
  name: string;
  posting_date: string | null;
  customer_name: string | null;
  custom_country_calling_code: string | null;
  custom_unique_phone: string | null;
  status: string | null;
}

function createData(
  name: string,
  posting_date: string,
  customer_name: string,
  custom_country_calling_code: string,
  custom_unique_phone: string,
  status: string,
): Data {
  return {
    name,
    posting_date,
    customer_name,
    custom_country_calling_code,
    custom_unique_phone,
    status,
  };
}

type Order = "asc" | "desc";

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
}

const headCells: readonly HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "帳單號碼",
  },
  {
    id: "posting_date",
    numeric: false,
    disablePadding: false,
    label: "建立日期",
  },
  {
    id: "customer_name",
    numeric: false,
    disablePadding: false,
    label: "會員姓名",
  },
  {
    id: "custom_country_calling_code",
    numeric: false,
    disablePadding: false,
    label: "電話號碼",
  },
  {
    id: "status",
    numeric: false,
    disablePadding: false,
    label: "帳單狀態",
  },
];

interface EnhancedTableProps {
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => void;
  order: Order;
  orderBy: string;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

export default function SalesInvoiceTable(props: any) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { page, rowsPerPage, invoiceOrderBy } = useSelector(
    (state: any) => state.invoiceState,
  );
  const [iOrderBy, iOrder] = invoiceOrderBy.split(" ");

  const [order, setOrder] = React.useState(iOrder);
  const [orderBy, setOrderBy] = React.useState(iOrderBy);
  const [rows, setRows] = React.useState<Data[]>([]);

  const [getInvoiceById, { data: invoiceData }] = useGetInvoiceByIdMutation();

  React.useEffect(() => {
    if (props.data) {
      setRows([]);
      props.data?.data?.forEach((invoice: any) => {
        setRows((oldRow: Data[]) => [
          ...oldRow,
          createData(
            invoice.name ?? "-",
            invoice.posting_date ?? "-",
            invoice.customer_name ?? "-",
            invoice.custom_country_calling_code ?? "-",
            invoice.custom_unique_phone ?? "-",
            invoice.status ?? "-",
          ),
        ]);
      });
    }
  }, [props]);

  React.useEffect(() => {
    if (invoiceData) {
      dispatch(setSelectedMember(invoiceData?.data?.customer as any));
      dispatch(setSelectedInvoiceId(invoiceData?.data?.name as string));
      dispatch(setIsInvoiceDrawerOpen(true));
      navigate(
        memberRoutes.productSelectionStep.selectProduct(
          invoiceData?.data?.supplier?.split(" ")[0] as string,
        ),
      );
    }
  }, [invoiceData]);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
    dispatch(setInvoiceOrderBy(`${property} ${isAsc ? "desc" : "asc"}`));
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatch(setPage(newPage));
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    dispatch(setRowsPerPage(parseInt(event.target.value, 10)));
    dispatch(setPage(1));
  };

  const handleRowClick = (row: Data) => {
    if (Number(row.status) === 0) {
      getInvoiceById({ invoice_name: row.name });
    } else if (Number(row.status) === 2) {
      navigate(invoiceRoutes.invoiceReturn(row.name));
    } else {
      // navigate(`/invoice/${row.name}/detail`);
      navigate(invoiceRoutes.invoiceDetail(row.name));
    }
  };

  return (
    <Box className="table__wrapper" sx={{ width: "100%" }}>
      <TableContainer>
        <Table
          sx={{ minWidth: 750 }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <EnhancedTableHead
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
          <TableBody>
            {rows.map((row) => {
              const saleOrderDetails = getInvoiceStatusDetails(
                Number(row.status ?? 0),
              );
              return (
                <TableRow
                  hover
                  tabIndex={-1}
                  key={row.name}
                  sx={{ cursor: "pointer" }}
                  onClick={() => handleRowClick(row)}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>{row.posting_date}</TableCell>
                  <TableCell>{row.customer_name}</TableCell>
                  <TableCell>{`${row.custom_country_calling_code} ${row.custom_unique_phone}`}</TableCell>
                  <TableCell
                    sx={{ color: saleOrderDetails.color, fontWeight: 600 }}
                  >
                    {" "}
                    {saleOrderDetails.label}{" "}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        labelRowsPerPage="每頁行數："
        rowsPerPageOptions={[10, 20, 30]}
        component="div"
        count={props?.data?.total}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Box>
  );
}
