/* eslint-disable react/jsx-props-no-spreading */
import { yupResolver } from "@hookform/resolvers/yup";
import { FormHelperText, Stack } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AlertDialog from "../../components/AlertDialog";
import RootLayout from "../../layout/Layout";
import { CustTextField } from "../../components/form-components/FormInput";
import { CustSelect } from "../../components/form-components/FormSelect";
import { birthMonthData } from "../../data/birthMonthData";
import { salutationData } from "../../data/salutationData";
import { createMemberValidationSchema } from "../../services/validations";
import {
  Box,
  Button,
  ChevronLeft,
  FormControl,
  FormGroup,
  Grid,
  InputLabel,
  MenuItem,
  Typography,
} from "../../ui-library";
import { getPhoneCodeList } from "../../utils/common";
import HowToKnowCheckBox from "src/components/member/HowToKnowCheckBox";
import { setSelectedMember } from "src/redux/features/memberSlice";
import memberRoutes from "src/constants/routes/member.routes";
import { useDispatch } from "react-redux";
import {
  useCheckDuplicateMemberMutation,
  useCreateMemberMutation,
} from "src/redux/api/memberApi";
import { useHowToKnowUsOptionsMutation } from "src/redux/api/commonApi";

type FormData = yup.InferType<typeof createMemberValidationSchema>;

const CreateMember = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [howDiscoverOptions, setHowDiscoverOptions] = useState<Array<string>>(
    [],
  );

  const [createMember, { isLoading, isSuccess, error, isError, data }] =
    useCreateMemberMutation();
  const [getHowToKnowUsList, { data: howToKnowUsData }] =
    useHowToKnowUsOptionsMutation();
  const [checkDuplicateMember, { error: duplicateError }] =
    useCheckDuplicateMemberMutation();

  const {
    handleSubmit,
    control,
    getValues,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: { ...createMemberValidationSchema.cast({}) },
    resolver: yupResolver(createMemberValidationSchema),
    mode: "onChange",
  });

  // GET HOW TO KNOW US OPTIONS LIST...
  useEffect(() => {
    getHowToKnowUsList({});
  }, []);

  useEffect(() => {
    if (isError) {
      console.error("Error create: ", error);
    } else if (isSuccess) {
      setOpenSuccessModal(true);
    }
  }, [isLoading]);

  useEffect(() => {
    if (duplicateError) {
      setError("custom_unique_phone", {
        message: "號碼已存在，請提供新號碼",
      });
    } else {
      clearErrors("custom_unique_phone");
    }
  }, [duplicateError]);

  const handleSuccessModalClose = () => {
    setOpenSuccessModal(!openSuccessModal);
  };

  const handleMultipleCheckBox = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked && !howDiscoverOptions.includes(name)) {
      setHowDiscoverOptions((prev) => {
        return [...prev, name];
      });
    }
    if (!checked && howDiscoverOptions.includes(name)) {
      const d = howDiscoverOptions.filter((k) => k !== name);
      setHowDiscoverOptions(d);
    }
  };

  const checkDuplicatePhone = (phone: string) => {
    const code = getValues("custom_country_calling_code");

    if (phone && phone.length) {
      if (!code) {
        setError("custom_unique_phone", {
          message: "需要區號",
        });
      } else {
        clearErrors("custom_unique_phone");
      }

      checkDuplicateMember({
        mobilePhone: phone,
        areaCode: code,
      });
    }
  };

  const onSubmit = (data: FormData) => {
    // create data...
    const how_discover = howDiscoverOptions.map((val) => {
      return { how_to_know_us: val };
    });

    const newData = {
      ...data,
      custom_how_to_know_us: how_discover,
      custom_birth_month: Number(data.custom_birth_month),
    };
    // console.log("Form Data: ", newData);
    createMember({ body: newData });
  };

  const handleRedirectAfterSuccess = () => {
    handleSuccessModalClose();
    dispatch(setSelectedMember(data.data));
    navigate(memberRoutes.productSelectionStep.selectSupplier);
  };

  return (
    <RootLayout>
      <Stack spacing={"15px"}>
        {/* HEADER SECTION... */}
        <Box display="flex" gap={3.6}>
          <Button
            variant="outlined"
            color="info"
            startIcon={<ChevronLeft />}
            onClick={() => navigate("/")}
          >
            返回
          </Button>

          <Box className="page__header-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="40"
              viewBox="0 0 39 40"
              fill="none"
            >
              <path
                d="M9 28.5286C9 25.1953 15.6667 23.362 19 23.362C22.3333 23.362 29 25.1953 29 28.5286V30.1953H9M24 15.1953C24 16.5214 23.4732 17.7932 22.5355 18.7308C21.5979 19.6685 20.3261 20.1953 19 20.1953C17.6739 20.1953 16.4021 19.6685 15.4645 18.7308C14.5268 17.7932 14 16.5214 14 15.1953C14 13.8692 14.5268 12.5975 15.4645 11.6598C16.4021 10.7221 17.6739 10.1953 19 10.1953C20.3261 10.1953 21.5979 10.7221 22.5355 11.6598C23.4732 12.5975 24 13.8692 24 15.1953ZM4 8.52865V31.862C4 32.746 4.35119 33.5939 4.97631 34.219C5.60143 34.8441 6.44928 35.1953 7.33333 35.1953H30.6667C31.5507 35.1953 32.3986 34.8441 33.0237 34.219C33.6488 33.5939 34 32.746 34 31.862V8.52865C34 7.64459 33.6488 6.79674 33.0237 6.17162C32.3986 5.5465 31.5507 5.19531 30.6667 5.19531H7.33333C6.44928 5.19531 5.60143 5.5465 4.97631 6.17162C4.35119 6.79674 4 7.64459 4 8.52865Z"
                fill="#333333"
              />
            </svg>
            <Typography variant="h2" className="title">
              新增會員
            </Typography>
          </Box>
        </Box>

        {/* ADD NEW MEMBER FORM... */}
        <Box className="page__content">
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Stack spacing={"8.83px"}>
                  <InputLabel>手機號碼*</InputLabel>
                  <Box
                    display={"flex"}
                    flexDirection="row"
                    alignItems={"center"}
                  >
                    <Controller
                      name="custom_country_calling_code"
                      control={control}
                      render={({ field, fieldState }) => (
                        <FormControl sx={{ width: 150, mr: "9px" }}>
                          <CustSelect
                            id="custom_country_calling_code"
                            {...field}
                            defaultValue={"852"}
                          >
                            {getPhoneCodeList().map((option, index: number) => {
                              return (
                                <MenuItem
                                  key={`phone-code-${index}`}
                                  value={option.value}
                                >
                                  {option.label}
                                </MenuItem>
                              );
                            })}
                          </CustSelect>
                        </FormControl>
                      )}
                    />

                    <Controller
                      name="custom_unique_phone"
                      control={control}
                      render={({ field, fieldState, formState }) => (
                        <CustTextField
                          id="custom_unique_phone"
                          placeholder="9843750574"
                          {...field}
                          onBlur={(e) => {
                            const val = e.target.value;
                            checkDuplicatePhone(val);
                          }}
                          error={fieldState.invalid}
                          // helperText={
                          //   fieldState.error ? fieldState.error.message : ""
                          // }
                        />
                      )}
                    />
                  </Box>

                  {errors && errors.custom_unique_phone?.message ? (
                    <FormHelperText sx={{ marginTop: 0, color: "red" }}>
                      {errors.custom_unique_phone?.message}
                    </FormHelperText>
                  ) : null}
                </Stack>
              </Grid>
              <Grid item xs={6}>
                <Controller
                  name="custom_email"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_email"
                      label="電郵地址"
                      placeholder="nawaraj@gmail.com"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="salutation"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustSelect
                      id="salutation"
                      label="稱謂"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    >
                      {salutationData.map((option, index: number) => {
                        return (
                          <MenuItem
                            key={`member-salutation-${index}`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </CustSelect>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="custom_birth_month"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustSelect
                      label="生日月份"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    >
                      {birthMonthData.map((option, index: number) => {
                        return (
                          <MenuItem
                            key={`birthday-month-${index}`}
                            value={option.value}
                          >
                            {option.label}
                          </MenuItem>
                        );
                      })}
                    </CustSelect>
                  )}
                />
              </Grid>

              <Grid item xs={6}>
                <Controller
                  name="customer_name"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="customer_name"
                      label="名字*"
                      placeholder="李"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} className="form-input-group">
                <InputLabel>如何認識我們(可多選)</InputLabel>

                {howToKnowUsData && howToKnowUsData.data.length ? (
                  <FormGroup className="form-checkbox-group">
                    {howToKnowUsData.data.map((item, index) => {
                      return (
                        <HowToKnowCheckBox
                          key={index}
                          label={item.name}
                          name={item.name}
                          onChange={handleMultipleCheckBox}
                          optionArray={howDiscoverOptions}
                        />
                      );
                    })}
                  </FormGroup>
                ) : null}
              </Grid>

              <Grid item xs={12} className="form-action-items">
                <Button
                  variant="outlined"
                  color="info"
                  onClick={() => navigate("/")}
                >
                  取消
                </Button>
                <Button type="submit" variant="contained">
                  新增
                </Button>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Stack>

      <AlertDialog
        isModalOpen={openSuccessModal}
        isAddSuccessModal={true}
        title="成功新增會員"
        content={`${data?.data?.customer_name} (${data?.data?.name})`}
        handleModalClose={handleSuccessModalClose}
        handleRedirectAfterSuccess={handleRedirectAfterSuccess}
      />
    </RootLayout>
  );
};

export default CreateMember;
