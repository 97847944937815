import { Checkbox, FormControlLabel, Stack } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import {
  ICreateSaleInvoicePayload,
  IUpdateItemOfSaleInvoicePayload,
} from "src/interfaces/invoice.interfaces";
import {
  IProductAttributeItem,
  IProductDetail,
  ISelectableAttributesList,
  ISelectedAttribute,
  ISelectedAttributeValue,
} from "src/interfaces/product.interfaces";
import { setHasInvoiceItem } from "src/redux/features/invoiceSlice";
import {
  IMemberState,
  setNeedRefeatchInvoice,
  setSelectedVariant,
} from "src/redux/features/memberSlice";
import {
  generateRemoteMediaUrl,
  getDimension,
  getMostRepeatedValueFromArray,
  getValidImageUrl,
  handleLocalStorage,
} from "src/utils/common";
import {
  Box,
  Button,
  Dialog,
  Divider,
  InputLabel,
  Typography,
} from "../../ui-library";
import CustLoadingButton from "../form-components/CustLoadingButton";
import DefaultLoaderContainer from "../loader";
import { ProductAttributeSlectionButton } from "../member-product-selection-steps/ProductAttributeSelectionButton";
import { useGetProductDetailMutation } from "src/redux/api/suppliersAndProductsApi";
import {
  useAddUpdateInvoiceItemMutation,
  useCreateInvoiceMutation,
} from "src/redux/api/saleAndInvoiceApi";

const ProductDetailModal = (props: ProductDetailModalProps) => {
  const { isModalOpen, selectedProductCode, handleModalClose, isEditing } =
    props;

  // INITIALIZATION...
  const dispatch = useDispatch();

  // LOCAL STATES...
  const [selectedAttributes, setSelectedAttributes] = useState<
    Array<ISelectedAttribute>
  >([]);
  const [attributeLists, setAttributeLists] = useState<
    ISelectableAttributesList[]
  >([]);
  const [selectedProductAttributeItemId, setSlectedProductAttributeItemId] =
    useState<string>();
  const [isDisplayItem, setIsDisplayItem] = useState<boolean>();
  const [headerInfo, setHeaderInfo] = useState<HeaderInfoStateType>();

  // REDUCER THINGS...
  const {
    selectedInvoiceId,
    selectedMember,
    selectedEditingPVariant,
    needRefeatchInvoice,
    invoiceDetail,
  } = useSelector((state: any) => state.memberState as IMemberState);

  const [
    getProductDetail,
    { isSuccess: isProductDetailGetSuccess, data: productDetail },
  ] = useGetProductDetailMutation();
  const [
    CreateInvoice,
    { isSuccess: createtInvoiceSuccess, isLoading: isCreateInvoiceLoading },
  ] = useCreateInvoiceMutation();
  const [
    addUpdateInvoiceItem,
    { isSuccess: isInvoiceUpdateSuccess, isLoading: isInvoiceUpdateLoading },
  ] = useAddUpdateInvoiceItemMutation();

  // CALLING API TO GET PRODUCT DETAIL WITH ITS ATTRIBUTES...
  useEffect(() => {
    if (selectedProductCode && isModalOpen) {
      if (isEditing) {
        getProductDetail({
          item_name: selectedProductCode,
          invoice_name: selectedInvoiceId,
        });
      } else {
        getProductDetail({
          item_name: selectedProductCode,
        });
      }
    }
  }, [selectedProductCode, isModalOpen]);

  // SET VARIANT ID IF EDITING MODE IS ON...
  useEffect(() => {
    if (selectedEditingPVariant && isEditing) {
      setSlectedProductAttributeItemId(selectedEditingPVariant.name);
    }
  }, [selectedEditingPVariant, isEditing]);

  // SET SELECTED VARIANT HEADER INFORMATION...
  useEffect(() => {
    if (productDetail && productDetail.data) {
      const pdetail = productDetail.data;

      if (pdetail.attributes.length) {
        const selectedAttr = pdetail.attributes.filter(
          (item) => item.name === selectedProductAttributeItemId,
        );

        if (selectedAttr && selectedAttr.length)
          handleSetHeaderInfo(selectedAttr[0]);
      } else {
        handleSetHeaderInfo(pdetail);
      }
    }
  }, [productDetail, selectedProductAttributeItemId]);

  // HANDLE PRODUCT DETAIL AFTER SUCCESSFULLY RETRIVE...
  useEffect(() => {
    if (isProductDetailGetSuccess && productDetail) {
      const pDetail = productDetail.data;
      setIsDisplayItem(pDetail.custom_display_item === 1 ? true : false);

      // CHECK IF PRODUCT HAS VARIATION OR NOT...
      if (pDetail.attributes.length) {
        const productAttributes = pDetail.attributes;

        const getSelectedVariantId = () => {
          if (isEditing && selectedEditingPVariant) {
            return selectedEditingPVariant.name;
          } else {
            const defaultAttributes = productAttributes[0];
            setSlectedProductAttributeItemId(defaultAttributes.name);
            return defaultAttributes.name;
          }
        };
        // MAKE SELECTABLE ATTRIBUTES LIST...
        const attributeListData = productAttributes.reduce(
          (accumulator: ISelectableAttributesList[], attribute) => {
            attribute.values.forEach((value) => {
              const title = value.attribute;
              const attribute_value = {
                attrVal: value.attribute_value,
                pId: [attribute.name],
                isDisabled: false,
                isSelected:
                  attribute.name === getSelectedVariantId() ? true : false,
                color_description: value.custom_colour_code,
                is_color: value.custom_is_colour,
              } as ISelectedAttributeValue;

              const accInd = accumulator.findIndex(
                (item) => item.attribute === title,
              );

              if (accInd >= 0) {
                const existingAttribute = accumulator[accInd];

                const valIndex = existingAttribute.attribute_value.findIndex(
                  (val) => val.attrVal === value.attribute_value,
                );

                if (valIndex >= 0) {
                  const extVal = existingAttribute.attribute_value[valIndex];

                  existingAttribute.attribute_value.splice(valIndex, 1, {
                    ...extVal,
                    pId: [...extVal.pId, attribute.name],
                  });
                } else {
                  existingAttribute.attribute_value.push(attribute_value);
                }
                accumulator.splice(accInd, 1, existingAttribute);
              } else {
                accumulator.push({
                  attribute: title,
                  attribute_value: [attribute_value],
                  isRequired: true,
                });
              }
            });

            return accumulator;
          },
          [],
        );

        let attributeListDataRefined: ISelectableAttributesList[] = [];
        attributeListData.forEach((item) => {
          let t = item;
          const t2 = item.attribute_value.map((d) => {
            if (d.pId.includes(getSelectedVariantId())) {
              return { ...d, isSelected: true };
            } else {
              return { ...d, isSelected: false };
            }
          });

          t["attribute_value"] = t2;
          attributeListDataRefined.push(t);
        });
        setAttributeLists(attributeListDataRefined);
      } else {
        setSlectedProductAttributeItemId(pDetail.name);
      }
    }
  }, [isProductDetailGetSuccess]);

  // HANDLE THINGS AFTER ADD OR CREATE INVOICE IS SUCCESS...
  useEffect(() => {
    if (isInvoiceUpdateSuccess || createtInvoiceSuccess) {
      handleModalClose(true);
      dispatch(setNeedRefeatchInvoice(!needRefeatchInvoice));

      // RESETTING STATES...
      dispatch(setSelectedVariant(null));
      dispatch(setHasInvoiceItem(true));
      setSelectedAttributes([]);
      setSlectedProductAttributeItemId(undefined);
      setAttributeLists([]);
    }
  }, [isInvoiceUpdateSuccess, createtInvoiceSuccess]);

  // CHANGE HEADER INFO DATA AFTER PRODUCT ATTRIBUTE CHANGE...
  useEffect(() => {
    if (
      productDetail &&
      productDetail.data &&
      productDetail.data.attributes &&
      selectedProductAttributeItemId
    ) {
      const variants = productDetail.data.attributes.filter(
        (val: any) => val.name === selectedProductAttributeItemId,
      );
      if (variants.length) {
        const variantDetail = variants[0];
        handleSetHeaderInfo(variantDetail);
      }
    }
  }, [productDetail, selectedProductAttributeItemId]);

  const handleSetHeaderInfo = (
    item: IProductDetail | IProductAttributeItem,
  ) => {
    // SET DEFUALT PRODUCT INFO OF ITEM...
    setHeaderInfo({
      name: item.name,
      item_name: item.item_name,
      item_original_price: item.custom_original_price,
      standard_rate: item.standard_rate,
      template_thumbnail: item.custom_template_thumbnail,
      height: item.custom_height,
      width: item.custom_width,
      length: item.custom_depth,
    });
  };

  // HANDLE ATTRIBUTE SELECTION...
  const handleAttributeSelection = (payload: ISelectedAttribute) => {
    // console.log("selected Attribute: ", payload);

    const oldSelectedAttrs = selectedAttributes;
    const testval = oldSelectedAttrs.filter(
      (val) => val.attribute !== payload.attribute,
    );
    const newTestVal = [...testval, payload];
    setSelectedAttributes(newTestVal);

    const newSelectableAttrs = attributeLists.reduce(
      (accumulator: ISelectableAttributesList[], attribute) => {
        if (payload.attribute !== attribute.attribute) {
          const newAttr = attribute.attribute;
          const newAttrVal: ISelectedAttributeValue[] = [];

          attribute.attribute_value.forEach((value) => {
            if (
              value.pId.some((val) => payload.attribute_value.pId.includes(val))
            ) {
              newAttrVal.push({ ...value, isDisabled: false });
            } else newAttrVal.push({ ...value, isDisabled: true });
          });

          accumulator.push({
            attribute: newAttr,
            attribute_value: newAttrVal,
            isRequired: attribute.isRequired,
          });
        } else {
          const vl = attribute.attribute_value;
          const attrvals = vl.map((val) => {
            if (val.attrVal === payload.attribute_value.attrVal)
              return { ...val, isSelected: true };
            else return { ...val, isSelected: false };
          });
          accumulator.push({ ...attribute, attribute_value: attrvals });
        }
        // console.log("new accumulator value: ", accumulator);
        return accumulator;
      },
      [],
    );

    setAttributeLists(newSelectableAttrs);

    let productVariantIdList: Array<string> = [];
    newTestVal.forEach((v) => {
      productVariantIdList = productVariantIdList.concat(v.attribute_value.pId);
    });

    const selectedAttrId = getMostRepeatedValueFromArray(productVariantIdList);
    // console.log("productVariantIdList: ", productVariantIdList);
    // console.log("selectedAttrId: ", selectedAttrId);
    setSlectedProductAttributeItemId(selectedAttrId);
  };

  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  // HANDLE WHILE MODAL CLOSE BUTTON TRIGGERED...
  const handleClose = () => {
    dispatch(setSelectedVariant(null));
    setSlectedProductAttributeItemId(undefined);
    setSelectedAttributes([]);
    setAttributeLists([]);
    handleModalClose();
  };

  const onSubmit = async () => {
    const getRemoveItemName = () => {
      if (
        selectedProductAttributeItemId &&
        selectedEditingPVariant &&
        selectedProductAttributeItemId === selectedEditingPVariant.name
      ) {
        return undefined;
      } else return selectedEditingPVariant?.item_name ?? undefined;
    };

    const createInvoice = () => {
      CreateInvoice({
        customer: String(selectedMember?.name),
        doctype: "Sales Invoice",
        custom_branch:
          handleLocalStorage.getValue(USER_RELATED_KEYS.BRANCH_NAME) ?? "",
        is_draft: 1,
        items: [
          {
            custom_display_item: isDisplayItem ? 1 : 0,
            item_code: selectedProductAttributeItemId,
            qty: 1,
          },
        ],
      } as ICreateSaleInvoicePayload);
    };

    const updateInvoice = (qty?: number) => {
      addUpdateInvoiceItem({
        invoice_name: selectedInvoiceId,
        items: [
          {
            item_code: String(selectedProductAttributeItemId),
            item_qty: qty ?? 1,
            // removeItemName: selectedEditingPVariant.item_name ?? undefined,
            remove_item_name: getRemoveItemName(),
            custom_display_item: isDisplayItem ? 1 : 0,
          },
        ],
      } as IUpdateItemOfSaleInvoicePayload);
    };

    // THROW REQUIRED ERRORS...
    if (!selectedMember) {
      alert("Member must be select First");
      return;
    }

    if (
      productDetail?.data.attributes.length &&
      !selectedProductAttributeItemId
    ) {
      window.alert("You Must select Attributes");
      return;
    }

    // CREATE INVOICE IF NOT CREATED TILL...
    if (!selectedInvoiceId) createInvoice();
    else {
      const item = invoiceDetail?.items.filter(
        (item) => item.item_code === selectedProductAttributeItemId,
      );

      // IF PRODUCT ALREADY EXIST IN INVOICE THEN JUST UPDATE QTY, IF NOT ADD AS NEW PRODUCT...
      if (item && item.length) updateInvoice(item[0].qty);
      else updateInvoice();
    }
  };

  // console.log("headerInfo: ", headerInfo);

  return (
    <Dialog maxWidth="md" fullWidth open={isModalOpen}>
      <Box
        sx={{
          minHeight: "250px",
          maxHeight: "80%",
          overflowY: "auto",
          bgcolor: "#F7F7F7",
          py: 3.75,
          px: 2.5,
        }}
      >
        <DefaultLoaderContainer
          height={"100%"}
          isLoading={productDetail && productDetail.data ? false : true}
        >
          {/* MODEL HEADER SECTION */}
          <Box
            aria-label="model-header-section"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <img
                // src={generateRemoteMediaUrl(headerInfo?.template_thumbnail)}
                src={getValidImageUrl(headerInfo?.template_thumbnail)}
                alt={headerInfo?.name ?? `Selected-Variant${Math.random()}`}
                height={70}
                width={70}
                onError={handleImageError}
              />
              <Box>
                <Typography>{headerInfo?.name}</Typography>

                <Typography
                  variant="h4"
                  fontSize={18}
                  fontWeight={700}
                  sx={{ whiteSpace: "break-spaces" }}
                >
                  {headerInfo?.item_name}
                </Typography>

                <Typography fontSize={13} mt={0.3}>
                  {getDimension({
                    height: headerInfo?.height ?? 0,
                    length: headerInfo?.length ?? 0,
                    width: headerInfo?.width ?? 0,
                  })}
                </Typography>
              </Box>
            </Stack>

            <Box textAlign="end" sx={{ whiteSpace: "nowrap" }}>
              <Typography
                sx={{
                  textDecoration: "line-through",
                  fontWeight: 500,
                }}
              >
                HK$ {headerInfo?.item_original_price ?? 0}
              </Typography>
              <Typography variant="h4" fontSize={28} fontWeight="bold">
                HK$ {headerInfo?.standard_rate ?? 0}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ my: 2.5 }} />

          {/* MODEL BODY SECTION */}
          <Box aria-label="model-body-section">
            <Stack spacing={2.5}>
              {attributeLists.map((attr, index) => {
                return (
                  <ProductAttributeSlectionButton
                    key={index}
                    attribute={attr.attribute}
                    attribute_value={attr.attribute_value}
                    isRequired={attr.isRequired}
                    handleSelection={handleAttributeSelection}
                  />
                );
              })}
            </Stack>

            <Stack spacing="10px" mt={2.5}>
              <InputLabel sx={{ fontSize: 18 }}>陳列品</InputLabel>
              <FormControlLabel
                checked={isDisplayItem === true ? true : false}
                onChange={(e, checked) => setIsDisplayItem(checked)}
                control={<Checkbox />}
                label={
                  <Typography color="#5A6070" fontSize={18}>
                    此為陳列品
                  </Typography>
                }
                sx={{
                  width: "fit-content",
                  p: "15px",
                  pl: "6px",
                  borderRadius: "5px",
                  border: "1px solid #BDBDBD",
                }}
              />
            </Stack>

            <Divider sx={{ my: 2.5 }} />

            <Box className="product__modal-form-actions">
              <Button variant="outlined" color="info" onClick={handleClose}>
                取消
              </Button>

              <CustLoadingButton
                loading={isCreateInvoiceLoading || isInvoiceUpdateLoading}
                variant="contained"
                loadingIndicator={"Loading"}
                type="submit"
                onClick={onSubmit}
              >
                {selectedEditingPVariant?.name ? "確認更改" : "加入到帳單"}
              </CustLoadingButton>
            </Box>
          </Box>
        </DefaultLoaderContainer>
      </Box>
    </Dialog>
  );
};

export default ProductDetailModal;

// TYPE DECLERATION...
interface ProductDetailModalProps {
  isModalOpen: boolean;
  selectedProductCode: string;
  isEditing: boolean;
  handleModalClose: (isSuccess?: boolean) => void;
}

interface HeaderInfoStateType {
  template_thumbnail: string | null;
  name: string;
  item_name: string;
  item_original_price: number;
  standard_rate: number;
  height?: number;
  width?: number;
  length?: number;
}
