import { ISupplierList } from "src/interfaces/suppliers";
import { Box, Typography } from "../../ui-library";

type SupplierItemProps = {
  supplier: ISupplierList;
};

const SupplierItem = ({ supplier }: SupplierItemProps) => {
  return (
    <Box
      sx={{
        p: "45.2px",
        borderRadius: "4.524px",
        border: "0.905px solid #BDBDBD",
        background: "#fff",
        textDecoration: "none",
        height: 123.48,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
      }}
    >
      <Typography
        variant="h4"
        color="#5A6070"
        fontSize="24px"
        fontWeight={600}
        whiteSpace="break-spaces"
      >
        {supplier.supplier_name ?? ""} {supplier.custom_supplier_name ?? ""}
      </Typography>
    </Box>
  );
};

export default SupplierItem;
