import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  FormControlLabel,
  FormControlLabelProps,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { ClearIcon } from "@mui/x-date-pickers";
import { CustTextField } from "../form-components/FormInput";
import { useEffect, useState } from "react";

const PaymentSelectionModal = (props: PaymentSelectionModalProps) => {
  const {
    isOpen,
    paymentMethods,
    selectedPaymentObj,
    selectedAddFundOption,
    addFundPercentOptions,
    setSelecteAddFundOption,
    setSelectedPaymentObj,
    handlePMSelection,
    handlePaymentMethodSelection,
    handleAddAmountPercentageOption,
    handleClose,
  } = props;

  const [error, setError] = useState({ mode_of_payment: "", amount: "" });

  useEffect(() => {
    if (!selectedPaymentObj.amount) {
      setError((prevState) => {
        return { ...prevState, amount: "*請選擇金額" };
      });
    } else {
      setError((prevState) => {
        return { ...prevState, amount: "" };
      });
    }
  }, [selectedPaymentObj]);

  const handleSubmit = () => {
    if (!selectedPaymentObj.mode_of_payment) {
      setError((prevState) => {
        return { ...prevState, mode_of_payment: "*請選擇付款方式" };
      });
      return;
    }
    if (!selectedPaymentObj.amount) {
      setError((prevState) => {
        return { ...prevState, amount: "*請選擇金額" };
      });
      return;
    }

    handlePMSelection(true);
  };

  return (
    <Dialog open={isOpen} aria-labelledby="add-payment-dialog" maxWidth="lg">
      <Stack spacing={2.5} p={2.5} width={950}>
        <Stack direction="row" spacing="20px" alignItems="center">
          <Button
            variant="outlined"
            color="inherit"
            sx={{
              p: "14px",
              bgcolor: "#FFF",
              borderRadius: "5px",
              borderColor: "#E0E0E0",
              color: "#DC2427",
            }}
            onClick={handleClose}
          >
            <ClearIcon sx={{ color: "#5A6070" }} />
          </Button>
          <Stack direction="row" spacing={"12.8px"} mb={2.5}>
            <Box
              component="img"
              src="/icons/payment-black.svg"
              width={40}
              height={40}
              alt="payment-icon"
            />
            <Typography fontWeight={700} fontSize={28.8}>
              新增付款方式
            </Typography>
          </Stack>
        </Stack>
        <Divider />

        <DialogContent sx={{ p: 0 }}>
          <Stack spacing={2.5} aria-labelledby="payment-method-selection">
            {paymentMethods && paymentMethods.data.length >= 1 ? (
              <FormControl>
                <FormLabel
                  id="payment--method-selection-buttons-group-label"
                  sx={{
                    fontWeight: 700,
                    mb: "9px",
                    color: "#333 !important",
                  }}
                >
                  付款方式*
                </FormLabel>

                <RadioGroup
                  row
                  aria-labelledby="payment-option-selection-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={selectedPaymentObj.mode_of_payment}
                  onChange={(e, val) => {
                    handlePaymentMethodSelection(e, val);
                    setError((prevState) => {
                      return {
                        ...prevState,
                        mode_of_payment: "",
                      };
                    });
                  }}
                  sx={{ gap: "9px" }}
                >
                  {paymentMethods.data.map((method: any, ind: number) => {
                    return (
                      <StyledFormControlLabel
                        key={`${method}-${ind}`}
                        value={method.mode_of_payment}
                        control={<Radio />}
                        label={method.mode_of_payment}
                        checked={
                          selectedPaymentObj.mode_of_payment ===
                          method.mode_of_payment
                        }
                      />
                    );
                  })}
                </RadioGroup>
              </FormControl>
            ) : null}

            {/* REQUIRED FIELD NOTICE */}
            {error && error.mode_of_payment ? (
              <Typography fontSize={18} color="error.main" fontWeight={700}>
                {error.mode_of_payment}
              </Typography>
            ) : null}

            {/* FUND INPUT... */}
            <Stack spacing="8.33px">
              <Typography fontWeight={700} fontSize={15}>
                金額(HK$)
              </Typography>

              <Stack direction="row" spacing="9px">
                <CustTextField
                  name="amount"
                  placeholder="100"
                  type="number"
                  value={selectedPaymentObj.amount}
                  onChange={(e) => {
                    setSelecteAddFundOption({});
                    setSelectedPaymentObj((prevState: any) => {
                      return {
                        ...prevState,
                        amount: Number(e.target.value),
                      };
                    });
                  }}
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      padding: "10px 10px 10px 20px",
                      height: 40,
                      width: 345,
                    },
                  }}
                />

                {defaultAddFundOptions.map((fund, ind) => {
                  return (
                    <Button
                      key={ind}
                      color="secondary"
                      variant="contained"
                      sx={{
                        fontSize: 18,
                        fontWeight: 700,
                        lineHeight: "24.874px",
                        p: "15px",
                      }}
                      onClick={() => {
                        setSelectedPaymentObj((prevState: any) => {
                          return {
                            ...prevState,
                            amount: prevState.amount + fund,
                          };
                        });
                      }}
                    >
                      + {fund}
                    </Button>
                  );
                })}

                <Button
                  color="inherit"
                  variant="outlined"
                  disableElevation
                  sx={{
                    fontSize: 18,
                    fontWeight: 700,
                    lineHeight: "24.874px",
                    p: "15px",
                    borderColor: "#5A6070",
                    color: "#5A6070",
                  }}
                  onClick={() =>
                    setSelectedPaymentObj((prevState: any) => {
                      return { ...prevState, amount: 0 };
                    })
                  }
                >
                  重設
                </Button>
              </Stack>

              <Stack direction="row" spacing="9px">
                {addFundPercentOptions.map((fund: any, ind: number) => {
                  return (
                    <FormControlLabel
                      key={ind}
                      control={
                        <Checkbox
                          name={fund.label}
                          checked={selectedAddFundOption[fund.label] ?? false}
                          onChange={handleAddAmountPercentageOption}
                        />
                      }
                      label={fund.label}
                    />
                  );
                })}
              </Stack>
            </Stack>

            {/* REQUIRED FIELD NOTICE */}
            {error && error.amount ? (
              <Typography fontSize={18} color="error.main" fontWeight={700}>
                {error.amount}
              </Typography>
            ) : null}
          </Stack>

          {/* CONFIRM FUND... */}
          <Box display="flex" justifyContent="flex-end" marginTop="20px">
            <Stack spacing={"10px"} direction="row">
              <Button
                variant="outlined"
                sx={{ padding: "15px" }}
                onClick={handleClose}
              >
                取消
              </Button>

              <Button
                variant="contained"
                sx={{ padding: "15px 35px" }}
                onClick={handleSubmit}
              >
                確定
              </Button>
            </Stack>
          </Box>
        </DialogContent>
      </Stack>
    </Dialog>
  );
};

export default PaymentSelectionModal;

const defaultAddFundOptions = [50, 100, 500];

// TYPE DECLERATION...
export interface PaymentSelectionModalProps {
  isOpen: boolean;
  paymentMethods: any;
  addFundPercentOptions: any;
  selectedPaymentObj: any;
  setSelecteAddFundOption: any;
  selectedAddFundOption: any;
  setSelectedPaymentObj: any;
  handleClose: () => void;
  handlePaymentMethodSelection: (e: any, mode_of_payment: string) => void;
  handlePMSelection: (isConfirmed: boolean) => void;
  handleAddAmountPercentageOption: (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
}

// STYLE...
export const StyledFormControlLabel = styled(
  FormControlLabel,
)<FormControlLabelProps>(({ theme, checked }) => ({
  padding: "15px",
  borderRadius: "5px",
  border: `1px solid ${checked ? "transparent" : "#E0E0E0"} `,
  margin: 0,
  background: checked ? "#45A492" : "#fff",
  color: checked ? "#fff" : "#828282",
  "& .Mui-checked": {
    color: "#fff",
  },
}));
