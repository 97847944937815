/* eslint-disable no-fallthrough */
export const getInvoiceStatusLabel = (value: any) => {
  switch (value) {
    case 0:
      return "草稿";
    case 1:
      return "已下單";
    case 2:
      return "已退單";
    case 3:
      return "HOLD單";
    default:
      return "-";
  }
};

export const getInvoiceStatusColor = (value: any) => {
  switch (value) {
    case 0:
      return "#3742F9";
    case 1:
      return "#45A492";
    case 2:
      return "#828282";
    case 3:
      return "#F2994A";
    default:
      return;
  }
};

export const getInvoiceStatusDetails = (status: number) => {
  let obj = {
    status: status,
    label: "草稿",
    color: "#3742F9",
    label_en: "Draft",
  };

  switch (status) {
    case 0: {
      obj["color"] = "#3742F9";
      obj["label"] = "草稿";
      obj["label_en"] = "Draft";
      break;
    }
    case 1: {
      obj["color"] = "#45A492";
      obj["label"] = "已下單";
      obj["label_en"] = "Order Placed";
      break;
    }
    case 2: {
      obj["color"] = "#828282";
      obj["label"] = "已退單";
      obj["label_en"] = "Return";
      break;
    }
    case 3: {
      obj["color"] = "#F2994A";
      obj["label"] = "HOLD單";
      obj["label_en"] = "Order HOLD";
      break;
    }
    default: {
      obj["color"] = "#3742F9";
      obj["label"] = "草稿";
      obj["label_en"] = "Draft";
      break;
    }
  }

  return obj;
};
