import * as yup from "yup";

export const createShippingAddressSchema = yup.object({
  delivery_date_choice: yup.string().required().default(""),
  consignee_name: yup.string().required().default(""),
  consignee_phone: yup.string().required().default(""),
  consignee_phone_2: yup.string().default(""),
  upstairs_fee: yup.number().default(0),
  pushing_fee: yup.number().default(0),
  disposable_products: yup.number().default(0),
  far_region: yup.number().default(0),
  delivery_remarks: yup.string().default(""),
  need_abandone_furniture: yup.boolean().default(false),
});

export const createMemberAddressSchema = yup.object({
  region: yup.string().label("Region").required().default(""),
  district: yup.string().label("District").required().default(""),
  address_line1: yup.string().label("Address Line 1").optional().default(""),
  address_line2: yup.string().label("Address Line 1").optional().default(""),

  recipient_name: yup.string().label("Recipient Name").required().default(""),
  phone: yup.string().label("Recipient Primary Phone").required().default(""),
  second_phone: yup
    .string()
    .label("Recipient Second Phone")
    .optional()
    .default(""),
  custom_remote_fee: yup.number().label("Remote Fee").optional().default(0),
  custom_stairs_fee: yup.number().label("Stairs Fee").optional().default(0),
  custom_cart_fee: yup.number().label("Cart Fee").optional().default(0),
  custom_address_remarks: yup
    .string()
    .label("Address Remarks")
    .optional()
    .default(""),
});

export const editShippingAddressSchema = yup.object({
  custom_recipient_name: yup.string().required().default(""),
  custom_phone: yup.string().required().default(""),
  custom_second_phone: yup.string().default(""),

  custom_district: yup.string().required().default(""),
  custom_region: yup.string().required().default(""),
  custom_address_line1: yup.string().default(""),
  custom_address_line2: yup.string().default(""),

  custom_remote_fee: yup.number().default(0),
  custom_stairs_fee: yup.number().default(0),
  custom_cart_fee: yup.number().default(0),
  custom_address_remarks: yup.string().default(""),
});
