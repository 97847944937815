import { Box, Dialog, Typography, Button } from "@mui/material";

// ============================ || INVOICE TRANSFER CONFIRM STATUS DIALOG COMPONENT || ====================================//
const InvoiceTransferStatusDialog = (
  props: InvoiceTransferConfirmDialogProps,
) => {
  const {
    handleModalClose,
    open,
    sourceInvoiceId,
    targetInvoiceId,
    isSuccess,
    error,
  } = props;

  let message = error ? JSON.parse(JSON.stringify(error, null, 2)) : "";
  message = message?.data?.exception;
  const regexMatches = String(message ?? "").match(`(?<=: ).*`);
  message = regexMatches && regexMatches.length ? regexMatches[0] : "";

  return (
    <Dialog
      aria-labelledby="invoice-transfer-confirm-status-dialog"
      maxWidth="sm"
      open={open}
      fullWidth
    >
      <Box p={5}>
        {isSuccess ? (
          <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
            已成功把帳單({sourceInvoiceId})轉單到 帳單({targetInvoiceId}
            )嗎?
          </Typography>
        ) : (
          <Box
            color={(theme) => theme.palette.error.main}
            py={2}
            fontSize={16}
            fontWeight={600}
          >
            {message}
          </Box>
        )}

        <Box
          display={"flex"}
          justifyContent={"center"}
          columnGap={2.5}
          color="grey.500"
          mt={"30px"}
        >
          {" "}
          <Button
            variant="contained"
            onClick={() => handleModalClose(true)}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="primary"
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InvoiceTransferStatusDialog;

export interface InvoiceTransferConfirmDialogProps {
  handleModalClose: (isConfirm: boolean) => void;
  open: boolean;
  sourceInvoiceId: string;
  targetInvoiceId: string;
  isSuccess: boolean;
  error?: any;
}
