import { yupResolver } from "@hookform/resolvers/yup";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogProps,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { CustTextField } from "src/components/form-components/FormInput";
import { CustSelect } from "src/components/form-components/FormSelect";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import {
  useGetAddressStatesAndCitiesQuery,
  useGetDeliveryDateOptionsMutation,
  useUpdateInvoiceShippingAddressMutation,
} from "src/redux/api/saleAndInvoiceApi";
import { editShippingAddressSchema } from "src/services/validations/shippingAddress.validation";
import * as yup from "yup";

export type EditAddressFormData = yup.InferType<
  typeof editShippingAddressSchema
>;

const EditAddressModal = (props: CreateEditAddressModalProps) => {
  const { invoice, handleModalClose, ...restDialogProps } = props;

  // LOCAL STATES...
  const [cities, setCities] = useState<Array<string>>([]);
  const [error, setError] = useState("");
  const [initialFormData, setInitialFormData] = useState<EditAddressFormData>({
    ...editShippingAddressSchema.cast({}),
  });

  const [deliveryInfo, setDeliveryInfo] = useState({
    delivery_date_choice: "",
    disposable_products: 0,
    need_abandone_furniture: 0,
  });

  // REACT-HOOK FORM CONFIGURATION...
  const { handleSubmit, control, setValue, getValues, watch } =
    useForm<EditAddressFormData>({
      // defaultValues: initialFormData,
      resolver: yupResolver(editShippingAddressSchema),
      mode: "onChange",
      values: initialFormData,
    });

  // REDUX MUTATION...
  const [getDeliveryOptions, { data: deliveryDatesOptions }] =
    useGetDeliveryDateOptionsMutation();
  const { data: statesAndCitiesData } = useGetAddressStatesAndCitiesQuery({});
  const [
    updateInvoiceShippingInfo,
    {
      isLoading: isUpdateInvoiceLoading,
      isSuccess: isUpdateInvoiceSuccess,
      isError: isUpdateInvoiceError,
      error: updateInvoiceError,
    },
  ] = useUpdateInvoiceShippingAddressMutation();

  // get delivery data options...
  useEffect(() => {
    getDeliveryOptions({ limit_page_length: 100 });
  }, []);

  // setting form fields if editing mode is on...
  useEffect(() => {
    if (invoice && restDialogProps.open && invoice.delivery) {
      const dinfo = invoice.delivery;

      setDeliveryInfo({
        delivery_date_choice: dinfo.custom_delivery_date,
        disposable_products: dinfo.custom_discard_fee ?? 0,
        need_abandone_furniture: dinfo.custom_discard_old_furniture,
      });
    }

    if (invoice && restDialogProps.open && statesAndCitiesData) {
      const deliveryAddr = invoice.delivery;
      handleStateChange(deliveryAddr.custom_region);

      setInitialFormData((prev) => {
        return {
          custom_recipient_name: deliveryAddr.custom_recipient_name,
          custom_phone: deliveryAddr.custom_phone,
          custom_second_phone: String(deliveryAddr.custom_second_phone ?? ""),
          custom_region: deliveryAddr.custom_region,
          custom_district: deliveryAddr.custom_district,
          custom_address_line1: String(deliveryAddr.custom_address_line1 ?? ""),
          custom_address_line2: String(deliveryAddr.custom_address_line2 ?? ""),
          custom_remote_fee: deliveryAddr.custom_remote_fee,
          custom_cart_fee: deliveryAddr.custom_cart_fee,
          custom_stairs_fee: deliveryAddr.custom_stairs_fee,
          custom_address_remarks: String(
            deliveryAddr.custom_address_remarks ?? "",
          ),
        };
      });
    }
  }, [restDialogProps.open, invoice, statesAndCitiesData]);

  // WATCH STATE CHANGE...
  useEffect(() => {
    const stateVal = getValues("custom_region");
    if (stateVal) {
      handleStateChange(stateVal);
      setError("");
    }
  }, [watch("custom_region")]);

  useEffect(() => {
    if (isUpdateInvoiceSuccess) {
      handleModalClose(true);
    }
  }, [isUpdateInvoiceSuccess]);

  // HANDLE ERROR...
  useEffect(() => {
    if (isUpdateInvoiceError && updateInvoiceError) {
      setError(
        (updateInvoiceError as any)?.message ??
          "Error Occured While Updating Invoice Information",
      );
    }
  }, [updateInvoiceError, isUpdateInvoiceError]);

  // HANDLE ADDRESS STATE VALUE CHANGE...
  const handleStateChange = (stateName: string) => {
    const selectedState = statesAndCitiesData?.data.filter(
      (item) => item.state === stateName,
    );

    if (selectedState && selectedState.length) {
      setCities(selectedState[0].cities);
    }
  };

  const handleCancle = () => {
    setInitialFormData({ ...editShippingAddressSchema.cast({}) });
    handleModalClose(false);
  };

  const submit = (fData: EditAddressFormData) => {
    setError("");

    // UPDATE DELIVERY INFO...
    updateInvoiceShippingInfo({
      invoiceID: String(invoice?.name),
      custom_delivery_date_option: deliveryInfo.delivery_date_choice,
      custom_discard_old_furniture: deliveryInfo.need_abandone_furniture as any,
      custom_discard_fee: deliveryInfo.disposable_products,

      ...fData,
      custom_phone: fData.custom_phone.startsWith("+852")
        ? fData.custom_phone
        : `+852${fData.custom_phone}`,
    });
  };

  return (
    <Dialog
      aria-labelledby="create-shipping-address-modal"
      maxWidth="lg"
      {...restDialogProps}
    >
      <Box
        component={"form"}
        onSubmit={handleSubmit(submit)}
        sx={{
          bgcolor: "#F7F7F7",
          maxHeight: "80vh",
          p: 2.5,
          width: "950px",
          overflowY: "auto",
        }}
        className="modal_wrapper"
      >
        {/* MODAL HEADER SECTION... */}
        <Box aria-labelledby="header-section">
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2.5} direction="row" alignItems="center">
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  p: "14px",
                  bgcolor: "#FFF",
                  borderRadius: "5px",
                  borderColor: "#E0E0E0",
                  color: "#DC2427",
                }}
                onClick={handleCancle}
              >
                <ClearIcon sx={{ color: "#5A6070" }} />
              </Button>

              <Stack spacing={"10px"} direction="row" alignItems="center">
                <Box
                  component="img"
                  src="/icons/delivery-van.svg"
                  alt="deleivery van"
                  height={24}
                  width={30}
                />
                <Typography fontSize={"28.8px"} fontWeight={700}>
                  編輯地址
                </Typography>
              </Stack>
            </Stack>
          </Box>

          <Box my={2.5} aria-labelledby="header-divider">
            <Divider />
          </Box>
        </Box>

        {error.length ? (
          <Box my={4} fontWeight={700} color={red[600]} fontSize={20}>
            {error}
          </Box>
        ) : null}

        {/* MODAL BODY SECTION... */}
        <Stack
          spacing={2.5}
          aria-labelledby="modal-body-content"
          sx={{
            "& .MuiInputBase-root": {
              bgcolor: "#fff",
              borderRadius: "5px",
              fontSize: 18,
            },
          }}
        >
          {/* FIELDS FOR DELIVERY INFO... */}
          <Grid
            container
            columnSpacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-delivery-info"
            sx={{
              alignItems: "flex-end",
              "& .MuiInputBase-root>input": { height: 40 },
            }}
          >
            <Grid item xs={4} pl={"0 !important"}>
              <CustSelect
                id="delivery_date_choice"
                name="delivery_date_choice"
                placeholder="李"
                label="送貨日期"
                value={deliveryInfo.delivery_date_choice}
                onChange={(e) => {
                  setDeliveryInfo((prevInfo) => {
                    return {
                      ...prevInfo,
                      delivery_date_choice: String(e.target.value),
                    };
                  });
                }}
                wrapperStackProps={{
                  sx: {
                    "& .select_label": {
                      fontSize: 12,
                      color: "#333",
                      fontWeight: 700,
                    },
                    "& .MuiSelect-select": {
                      height: "40px !important",
                    },
                    "& .MuiInputBase-root > input": {
                      height: "auto !important",
                    },
                  },
                }}
              >
                {deliveryDatesOptions &&
                  deliveryDatesOptions.data.map((deliveryDate, index) => (
                    <MenuItem
                      key={`delivery-date-${index}`}
                      value={deliveryDate.name}
                    >
                      {deliveryDate.name}
                    </MenuItem>
                  ))}
              </CustSelect>
            </Grid>

            <Grid item xs={3}>
              <FormControlLabel
                id="need_abandone_furniture"
                name="need_abandone_furniture"
                control={<Checkbox sx={{ height: 40 }} />}
                value={
                  deliveryInfo.need_abandone_furniture === 1 ? true : false
                }
                checked={
                  deliveryInfo.need_abandone_furniture === 1 ? true : false
                }
                onChange={(e, checked) => {
                  setDeliveryInfo((prevVal) => {
                    return {
                      ...prevVal,
                      need_abandone_furniture: checked === true ? 1 : 0,
                    };
                  });
                }}
                label={
                  <Typography
                    fontSize={18}
                    color="#5A6070"
                    lineHeight={"24.87px"}
                  >
                    需要棄置舊傢具
                  </Typography>
                }
                sx={{
                  m: 0,
                  p: "15px",
                  borderRadius: "5px",
                  border: "1px solid #BDBDBD",
                }}
              />
            </Grid>

            <Grid item xs={4}>
              <CustTextField
                id="disposable_products"
                name="disposable_products"
                label="棄置產品費(HK$)"
                placeholder="500"
                value={deliveryInfo.disposable_products ?? 0}
                onChange={(e) => {
                  setDeliveryInfo((prevVal) => {
                    return {
                      ...prevVal,
                      disposable_products: Number(e.target.value),
                    };
                  });
                }}
              />
            </Grid>
          </Grid>

          <Box my={2.5}>
            <Divider sx={{ color: "#A2AEBC", bgcolor: "#A2AEBC" }} />
          </Box>

          {/* FIELDS FOR CONSIGNEE... */}
          <Grid
            container
            columnSpacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-consignee"
          >
            <Grid item xs={4} pl={"0 !important"}>
              <Controller
                name="custom_recipient_name"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_recipient_name"
                    label="收貨人姓名"
                    placeholder="陳小明"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="custom_phone"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_phone"
                    label="收貨人電話"
                    placeholder="63958794"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="custom_second_phone"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_second_phone"
                    label="收貨人其他電話"
                    placeholder="63958794"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* FIELDS FOR ADDRESS... */}
          <Grid
            container
            spacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-address"
          >
            <Grid item xs={6} pl={"0 !important"} pt={"0 !important"}>
              <Controller
                name="custom_region"
                control={control}
                render={({ field, fieldState }) => (
                  <CustSelect
                    id="custom_region"
                    label="區域"
                    placeholder="香港島"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("custom_district", ""); // Reset the city value when state changes
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                    wrapperStackProps={{
                      sx: {
                        "& .select_label": {
                          fontSize: 12,
                          color: "#333",
                          fontWeight: 700,
                        },
                      },
                    }}
                  >
                    {statesAndCitiesData &&
                      statesAndCitiesData.data.map((address, index) => (
                        <MenuItem
                          key={`address-state-${index}`}
                          value={address.state}
                        >
                          {address.state}
                        </MenuItem>
                      ))}
                  </CustSelect>
                )}
              />
            </Grid>

            <Grid item xs={6} pt={"0 !important"}>
              <Controller
                name="custom_district"
                control={control}
                render={({ field, fieldState }) => (
                  <CustSelect
                    id="custom_district"
                    label="地區"
                    placeholder="中西區"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                    wrapperStackProps={{
                      sx: {
                        "& .select_label": {
                          fontSize: 12,
                          color: "#333",
                          fontWeight: 700,
                        },
                      },
                    }}
                  >
                    {cities &&
                      cities.map((city, index) => (
                        <MenuItem key={`address-city-${index}`} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                  </CustSelect>
                )}
              />
            </Grid>

            <Grid item xs={6} pl={"0 !important"}>
              <Controller
                name="custom_address_line1"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_address_line1"
                    label="街道號碼及屋苑大廈"
                    placeholder="西營盤第三街"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="custom_address_line2"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_address_line2"
                    label="樓層及單位"
                    placeholder="16樓17室"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* FIELDS FOR EXTRA FEES... */}
          <Grid
            container
            columnSpacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-extra-fees"
          >
            <Grid item xs={3} pl={"0 !important"}>
              <Stack spacing={2.5}>
                <Controller
                  name="custom_stairs_fee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_stairs_fee"
                      label="上樓費(HK$)"
                      placeholder="500"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />

                <Controller
                  name="custom_cart_fee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_cart_fee"
                      label="推路費(HK$)"
                      placeholder="500"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />

                <Controller
                  name="custom_remote_fee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_remote_fee"
                      label="偏遠地區(HK$)"
                      placeholder="500"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={9}>
              <Controller
                name="custom_address_remarks"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_address_remarks"
                    label="地址備註"
                    placeholder="沒有升降機"
                    multiline
                    fullWidth
                    rows={10}
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>

        {/* MODAL FOOTER SECTION... */}
        <Box aria-labelledby="footer-section">
          <Box my={2.5} aria-labelledby="footer-divider">
            <Divider />
          </Box>

          <DialogActions sx={{ p: 0, mt: 3, justifyContent: "flex-start" }}>
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                px: "25px",
                py: "14px",
                bgcolor: "#FFF",
                borderRadius: "5px",
                borderColor: "#E0E0E0",
                color: "#5A6070",
                fontWeight: 700,
              }}
              disableElevation
              onClick={handleCancle}
            >
              取消
            </Button>

            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "unset",
                color: "#fff",
                fontWeight: 700,
                px: "25px",
                py: "14px",
                flexGrow: 1,
              }}
              disabled={isUpdateInvoiceLoading}
              disableElevation
            >
              {isUpdateInvoiceLoading ? "加载中" : "完成"}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default EditAddressModal;

export interface CreateEditAddressModalProps extends DialogProps {
  invoice?: IInvoiceDetail;
  handleModalClose: (isSuccess: boolean) => void;
}
