import * as yup from "yup";

export const loginValidationSchema = yup
  .object({
    usr: yup.string().required("Username is required"),
    pwd: yup
      .string()
      .min(6, "Password must be minimum 6 characters")
      .required(),
  })
  .required();
