import { SvgIcon, SvgIconProps } from "@mui/material";

// ==============================|| MAKE ICON WITH PATH PROPERTY OF THE SVG ||============================== //
export const MakeIcon = (props: SvgIconProps) => {
  return <SvgIcon {...props}> {props.children} </SvgIcon>;
};

export const MaterializedSvgIcon = (props: SvgIconProps) => {
  const { children, ...restProps } = props;

  return <SvgIcon viewBox="0 0 24 24" {...restProps} />;
};
