import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import ProductSelectionStepBreadcrumb from "src/components/member-product-selection-steps/ProductSelectStepBreadcrumb";
import SubmitInvoiceDialog from "src/components/member-product-selection-steps/ReviewInvoiceSubmitDialog";
import { INVOICE_CREATION_STEPS_KEYS } from "src/constants/keys/local-storage.keys";
import invoiceRoutes from "src/constants/routes/invoice.routes";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import { IInvoicePaymentDetail } from "src/interfaces/payment-method.interfaces";
import RootLayout from "src/layout/Layout";
import {
  IConfirmInvoiceWithPaymentAPIPayload,
  useConfirmInvoiceWithPaymentMutation,
  useDeleteInvoiceByIdMutation,
  useGetInvoiceByIdMutation,
  useHoldSalesInvoiceMutation,
  useSubmitSalesInvoiceMutation,
  useUnholdSalesInvoiceMutation,
} from "src/redux/api/saleAndInvoiceApi";
import {
  IMemberState,
  setInvoiceDetails,
  setSelectedInvoiceId,
} from "src/redux/features/memberSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";
import {
  generateRemoteMediaUrl,
  handleLocalStorage,
  handleWordingOfAbandonFurniture,
} from "src/utils/common";
import {
  Box,
  Button,
  Checkbox,
  ChevronLeft,
  Divider,
  FormControlLabel,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "src/ui-library";

const CheckInfoStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // LOCAL STATES...
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedHoldInvoice, setIsNeedHoldInvoice] = useState(false);
  const [isNeedHoldInvoiceDraft, setIsNeedHoldInvoiceDraft] = useState(false);

  const d = handleLocalStorage.getValue(
    INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO || "",
  )
    ? JSON.parse(
        String(
          handleLocalStorage.getValue(
            INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO || "",
          ),
        ),
      )
    : null;
  const localPaymentData: IInvoicePaymentDetail | null = d ?? null;
  // console.log("localPaymentData: ", localPaymentData);

  // REDUX THINGS..
  const { selectedInvoiceId } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );

  const [getInvoiceById, { data: invoiceData }] = useGetInvoiceByIdMutation();
  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();
  const [submitDraftInvoice] = useSubmitSalesInvoiceMutation();
  const [confirmInvoiceWithPayment] = useConfirmInvoiceWithPaymentMutation();
  const [holdSalesInvoice, { isError: isHoldInvoiceError }] =
    useHoldSalesInvoiceMutation();

  const [unholdSalesInvoice, { isError: isunholdInvoiceError }] =
    useUnholdSalesInvoiceMutation();

  useEffect(() => {
    if (selectedInvoiceId) {
      getInvoiceById({ invoice_name: selectedInvoiceId });
    }
  }, []);

  // UPDATE ISHOLD STATE ACCORDING TO RESPONSE...
  useEffect(() => {
    if (isHoldInvoiceError || isunholdInvoiceError) {
      // rollback to previous state
      setIsNeedHoldInvoice(isNeedHoldInvoiceDraft);
    }
  }, [isunholdInvoiceError, isHoldInvoiceError]);

  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      navigate("/");
    }
  }, [isDeleteInvoiceSuccess]);

  // GRAVING INVOICE'S HOLDING STATUS AND SETTING STATE
  useEffect(() => {
    if (invoiceData) {
      setIsNeedHoldInvoice(
        invoiceData.data.custom_is_hold === 1 ? true : false,
      );
    }
  }, [invoiceData]);

  const handleSubmitModalToggle = () => {
    setIsSubmitModalOpen(!isSubmitModalOpen);
  };

  const handleCancelOrder = () => {
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  // HANDLE HOLD AND UNHOLD INVOICE...
  const handleInvoiceHold = (checked: boolean) => {
    setIsNeedHoldInvoiceDraft(isNeedHoldInvoice);
    setIsNeedHoldInvoice(checked);
    if (checked === true) holdSalesInvoice({ invoice_name: selectedInvoiceId });
    else unholdSalesInvoice({ invoice_name: selectedInvoiceId });
  };

  // HANDLE IMAGE LOAD ERROR
  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  const handleSubmitInvoice = async () => {
    setIsLoading(true);
    console.log("confirming invoice.....");

    try {
      if (selectedInvoiceId) {
        let confirmInvoiceRes = undefined as any;
        confirmInvoiceRes = await submitDraftInvoice({
          invoice_name: String(selectedInvoiceId),
        });
        console.log("sumbitDraftInvoiceRes: ", confirmInvoiceRes);

        // getting submitted invoice name...
        if (confirmInvoiceRes && confirmInvoiceRes?.data) {
          // save new invoice response into redux...
          const newData = confirmInvoiceRes.data?.data as IInvoiceDetail;

          const invoiceNewName = newData?.custom_submitted_invoice_ref ?? "";
          dispatch(setInvoiceDetails({ ...newData, name: invoiceNewName }));

          if (localPaymentData && localPaymentData.mode_of_payments.length) {
            // prepare payload for payment addition in invoice...
            const payload = {
              invoice_name: invoiceNewName,
              mode_of_payments: localPaymentData.mode_of_payments,
              custom_payment_remarks: localPaymentData.remarks,
            } as IConfirmInvoiceWithPaymentAPIPayload;

            let paymentRes = undefined as any;
            paymentRes = await confirmInvoiceWithPayment(payload);

            if (paymentRes && paymentRes?.mode_of_payments) {
              handleSubmitModalToggle();
              dispatch(setSelectedInvoiceId(""));
              navigate(
                invoiceRoutes.invoiceDetail(invoiceNewName, {
                  isConfirmed: true,
                }),
              );
            } else {
              dispatch(setSelectedInvoiceId(""));
              navigate(
                invoiceRoutes.invoiceDetail(invoiceNewName, {
                  isConfirmed: true,
                }),
              );
            }
          } else {
            handleSubmitModalToggle();
            dispatch(setSelectedInvoiceId(""));
            navigate(
              invoiceRoutes.invoiceDetail(invoiceNewName, {
                isConfirmed: true,
              }),
            );
            console.log("Payment data not found in localstorage: ");
          }
        } else {
          console.log("Invoice is not submitted properly");
        }
      } else {
        console.log("selected invoice id is not present in context");
      }
    } catch (error) {
      console.error("Error in submitting invoice: ", error);
    }
    setIsLoading(false);
  };

  return (
    <RootLayout>
      {/* HEADER INFORMATION SECTION... */}
      <Stack spacing={2.5}>
        <Box
          aria-label="header-section"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledHeaderBackButton
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => navigate(-1)}
          >
            返回
          </StyledHeaderBackButton>

          <Box>
            <ProductSelectionStepBreadcrumb active={5} />
          </Box>

          <Button
            variant="contained"
            startIcon={
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/cross.svg"
                  alt="cross-file"
                  width={20}
                  style={{ objectFit: "contain" }}
                />
              </Box>
            }
            sx={{ px: 3.125, py: 1.25 }}
            onClick={handleCancelOrder}
          >
            取消下單
          </Button>
        </Box>
      </Stack>

      {/* PAGE BODY INFO SECTION... */}
      <Box
        className="check__info__page__content checkout__process"
        display="flex"
        flexDirection="column"
        gap="20px"
        marginTop="20px"
      >
        <Typography className="page__title">請核對下單資料</Typography>
        {/* SALE PERSON INFO SECTION... */}
        <Box className="salesperson__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/sale-girl.svg"
                alt="sale-girl"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              銷售員信息
            </Typography>
          </Stack>

          <Box
            padding="30px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <InputLabel>主銷售員</InputLabel>
                <Typography>
                  {invoiceData?.data?.sales_team?.[0]?.sales_person_name ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>其他銷售員</InputLabel>
                <Typography>
                  {invoiceData?.data?.sales_team?.[1]?.sales_person_name ?? "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* PRODUCT INFORMATION SECTION...  */}
        <Box className="product__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/info.svg"
                alt="sale-girl"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              商品信息
            </Typography>
          </Stack>

          <Box
            display="flex"
            flexDirection="column"
            gap="20px"
            padding="20px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
            className="MuiGrid-item"
          >
            <Box
              aria-labelledby="hold-invoice-section"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              fontSize={20}
            >
              <Box>
                <Box component="span" fontWeight={700}>
                  供應商
                </Box>
                <Box component="span" fontWeight={500} ml={1.1}>
                  {invoiceData?.data?.supplier}
                </Box>
              </Box>

              <Box
                sx={{
                  "& .form_input_checkbox": {
                    marginRight: 0,
                    padding: "15px",
                    borderRadius: "5px",
                    border: `1px solid ${
                      isNeedHoldInvoice ? "transparent" : "#BDBDBD"
                    }`,
                  },
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      name="need_hold_order"
                      value={isNeedHoldInvoice}
                      onChange={(e, checked) => {
                        handleInvoiceHold(checked);
                      }}
                      sx={{
                        color: isNeedHoldInvoice
                          ? "#FFF !important"
                          : "#828282",
                        "&.Mui-checked": {
                          color: isNeedHoldInvoice ? "#FFF" : "#828282",
                        },
                      }}
                    />
                  }
                  checked={isNeedHoldInvoice}
                  label="需要HOLD單"
                  sx={{
                    marginRight: 0,
                    padding: "15px",
                    borderRadius: "5px",
                    border: `1px solid ${
                      isNeedHoldInvoice ? "transparent" : "#BDBDBD"
                    }`,
                    bgcolor: isNeedHoldInvoice ? "#45A492" : "#fff",
                    color: isNeedHoldInvoice ? "#FFF !important" : "#5A6070",
                  }}
                />
              </Box>
            </Box>

            <Box aria-label="product-table">
              <Stack
                aria-label="header-and-data-section"
                spacing={5}
                sx={{
                  border: "1.125px solid #E0E0E0",
                  borderRadius: "5px",
                  padding: "20px 12px",
                }}
              >
                <Grid
                  width={"100%"}
                  container
                  aria-label="table-header"
                  columnSpacing={3}
                  sx={{
                    height: 70,
                    fontWeight: 500,
                    fontSize: 20,
                    borderBottom: "1px solid #BDBDBD",
                    py: "10px",
                    "& .table_header": {
                      "&:not(:last-child)": {
                        borderRight: "1px solid #BDBDBD",
                      },
                    },
                  }}
                >
                  <Grid item xs={6} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      商品資料
                    </Box>
                  </Grid>

                  <Grid item xs={2} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      單價(HKD)
                    </Box>
                  </Grid>

                  <Grid item xs={2} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      折扣(HKD)
                    </Box>
                  </Grid>

                  <Grid item xs={2} className="table_header">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      height="100%"
                    >
                      金額(HKD)
                    </Box>
                  </Grid>
                </Grid>

                {/* START: Table content (Product) */}
                {invoiceData?.data?.items?.map((product, index: number) => (
                  <Grid
                    key={`invoice-item-${index}`}
                    width={"100%"}
                    container
                    columnSpacing={3}
                    aria-label={`product-item-section`}
                    sx={{
                      "& .MuiGrid-item": {
                        "&:not(:last-child)": {
                          borderRight: "1px solid #BDBDBD",
                        },
                      },
                    }}
                  >
                    <Grid item xs={6}>
                      <Box
                        display="flex"
                        alignItems="center"
                        gap="20px"
                        aria-label="invoice-product-item"
                      >
                        <img
                          // src={"/images/NoImage.svg"}
                          src={generateRemoteMediaUrl(
                            product.custom_template_thumbnail,
                          )}
                          alt={product.item_name ?? "product image"}
                          width={158.625}
                          onError={handleImageError}
                          style={{ objectFit: "contain" }}
                        />

                        <Stack gap="10px">
                          <Box display="flex" flexDirection="column" gap="5px">
                            <Typography
                              sx={{
                                padding: "0 !important",
                              }}
                            >
                              {product?.item_code || "-"}
                            </Typography>
                            <Typography
                              sx={{
                                fontSize: "20px !important",
                                fontWeight: "700 !important",
                                padding: "0 !important",
                              }}
                            >
                              {product?.item_name || "-"}
                            </Typography>
                            <Typography
                              sx={{
                                padding: "0 !important",
                              }}
                            >
                              {product.custom_width ? (
                                <span>{product.custom_width}cm(W)</span>
                              ) : null}
                              {product.custom_height ? (
                                <span> x {product.custom_height}cm(H)</span>
                              ) : null}
                              {product.custom_size_remarks ? (
                                <span>[{product.custom_size_remarks}]</span>
                              ) : null}
                            </Typography>
                          </Box>
                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "18px !important",
                            }}
                          >
                            {product?.sales_invoice_custom_item_remarks || "-"}
                          </Typography>
                          {product.custom_display_item === 1 && (
                            <Typography
                              sx={{
                                color: "#3742F9 !important",
                                fontSize: "16px !important",
                                fontWeight: "700  !important",
                                padding: "0 !important",
                              }}
                            >
                              陳列品
                            </Typography>
                          )}
                          {product.attributes.length ? (
                            product.attributes.map((attr, index: number) => {
                              return (
                                <Typography
                                  id={attr.attribute}
                                  key={`product-attribute-${index}`}
                                  sx={{
                                    display: "inline-flex",
                                    flexDirection: "column",
                                    padding: "8px 0 !important",
                                    fontSize: "15px !important",
                                  }}
                                >
                                  <Box component="span">{attr.attribute}: </Box>
                                  <Box component="span" fontWeight={400}>
                                    {attr.attribute_value}
                                  </Box>
                                </Typography>
                              );
                            })
                          ) : (
                            <Typography
                              sx={{
                                padding: "8px 0 !important",
                                fontSize: "15px !important",
                                fontWeight: "500 !important",
                              }}
                            >
                              No attributes
                            </Typography>
                          )}

                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "20px !important",
                              fontWeight: "700 !important",
                            }}
                          >
                            x{product?.qty || 1}
                          </Typography>
                        </Stack>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        height="100%"
                      >
                        <Typography
                          sx={{
                            padding: "0 !important",
                            fontSize: "14px !important",
                            fontWeight: "500 !important",
                            textDecorationLine: "line-through",
                          }}
                        >
                          $ {product?.custom_original_price || null}
                        </Typography>

                        <Typography
                          sx={{
                            padding: "0 !important",
                            fontSize: "14px !important",
                            fontStyle: "italic !important",
                            color: "#DC2427 !important",
                          }}
                        >
                          {product?.custom_markup_discount}% off
                        </Typography>

                        <InputLabel>$ {product?.rate}</InputLabel>
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        {product.applied_coupons.length ? (
                          <Stack spacing={1}>
                            {product.applied_coupons.map((d) => {
                              return (
                                <Box key={d.coupon_code}>
                                  <Typography
                                    sx={{
                                      padding: "0 !important",
                                      fontSize: "20px !important",
                                      fontWeight: "700 !important",
                                    }}
                                  >
                                    -$ {d.amount}
                                  </Typography>
                                  <Button
                                    sx={{
                                      background: "#828282",
                                      color: "#fff !important",
                                    }}
                                    disabled
                                  >
                                    {d.coupon_name}
                                  </Button>
                                </Box>
                              );
                            })}
                          </Stack>
                        ) : (
                          <>-</>
                        )}
                      </Box>
                    </Grid>

                    <Grid item xs={2}>
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        <Typography
                          sx={{
                            padding: "0 !important",
                            fontSize: "24px !important",
                            fontWeight: "700 !important",
                          }}
                        >
                          $ {product.custom_discounted_amount}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                ))}
                {/* END: Table content (Product) */}
              </Stack>
            </Box>

            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              padding="30px 20px 20px 20px"
              border="1.13px solid #E0E0E0"
              borderRadius="5px"
            >
              <Stack direction="row" justifyContent="space-between">
                <InputLabel>產品累計</InputLabel>
                <Typography sx={{ padding: "0px !important" }}>
                  ${" "}
                  {invoiceData?.data?.custom_total_item_discounted_amount ||
                    "-"}
                </Typography>
              </Stack>
              {invoiceData?.data?.applied_coupons?.map(
                (coupon, index: number) => (
                  <Stack
                    key={`applied-coupon-${index}`}
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <InputLabel>全單折扣</InputLabel>
                    <Box
                      sx={{
                        display: "flex",
                        gap: "60px",
                        alignItems: "center",
                      }}
                    >
                      <Button
                        sx={{ background: "#828282", color: "#fff !important" }}
                        disabled
                      >
                        {coupon?.coupon_name}
                      </Button>
                      <Typography sx={{ padding: "0px !important" }}>
                        -$ {coupon?.amount}
                      </Typography>
                    </Box>
                  </Stack>
                ),
              )}
              <Divider />
              <Stack direction="row" justifyContent="space-between">
                <InputLabel>全單總計</InputLabel>
                <Typography sx={{ padding: "0px !important" }}>
                  $ {invoiceData?.data?.rounded_total}
                </Typography>
              </Stack>
            </Box>
          </Box>
        </Box>

        {/* PAYMENT INFORMATION SECTION... */}
        <Box
          className="payment__info"
          display={localPaymentData?.mode_of_payments.length ? "block" : "none"}
        >
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/payment-card.svg"
                alt="payment-card"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              付款信息
            </Typography>
          </Stack>

          <Box
            padding="20px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              padding="30px 20px 20px 20px"
              borderRadius="5px"
              border="1px solid #E0E0E0"
              className="MuiGrid-item"
            >
              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ padding: "0px !important" }}>
                  全單總計
                </Typography>
                <InputLabel>$ {invoiceData?.data?.rounded_total}</InputLabel>
              </Stack>
              <Divider />

              <Fragment>
                <Stack direction="row">
                  <Typography sx={{ padding: "0px !important" }}>
                    付款於
                    {localPaymentData?.date
                      ? `${new Date(
                          localPaymentData.date,
                        ).toLocaleDateString()}`
                      : "-"}
                  </Typography>
                </Stack>
                {localPaymentData?.mode_of_payments.map(
                  (
                    mode: { mode_of_payment: string; amount: number },
                    index: number,
                  ) => (
                    <Stack
                      key={`payment-mode-${index}`}
                      direction="row"
                      justifyContent="space-between"
                    >
                      <Button
                        sx={{
                          background: "#828282",
                          color: "#fff !important",
                        }}
                        disabled
                      >
                        {mode.mode_of_payment}
                      </Button>
                      <InputLabel>-$ {mode.amount}</InputLabel>
                    </Stack>
                  ),
                )}

                <Stack direction="row" alignItems="center">
                  <InputLabel>備註 : </InputLabel>&nbsp;
                  <Typography sx={{ padding: "0px !important" }}>
                    {localPaymentData?.remarks || "-"}
                  </Typography>
                </Stack>
              </Fragment>
              <Divider />

              <Stack direction="row" justifyContent="space-between">
                <Typography sx={{ padding: "0px !important" }}>餘額</Typography>
                <InputLabel>{localPaymentData?.due_amt}</InputLabel>
              </Stack>
            </Box>
          </Box>
        </Box>

        {/* MEMBER INFORMATION SECTION... */}
        <Box className="member__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/account-box.svg"
                alt="account-box"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              會員
            </Typography>
          </Stack>
          <Box
            padding="30px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Grid container rowGap="20px">
              <Grid item xs={6}>
                <InputLabel>會員編號</InputLabel>
                <Typography>{invoiceData?.data?.customer?.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>生日月份</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.custom_birth_month}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>稱謂</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.salutation || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>姓名</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.customer_name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>電郵地址</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.custom_email || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>手機號碼</InputLabel>
                <Typography>
                  {invoiceData?.data?.customer?.custom_unique_phone || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* SHIPPING INFORMATION SECTION... */}
        <Box className="shipping__info">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/shipping-truck.svg"
                alt="shipping-truck"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              送貨資料
            </Typography>
          </Stack>
          <Box
            padding="30px 20px 20px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            <Grid container aria-labelledby="invoice-deliveryin-info">
              <Grid item xs={3}>
                <InputLabel>收貨日期</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_delivery_date || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>需要棄置舊傢具</InputLabel>
                <Typography>
                  {handleWordingOfAbandonFurniture(
                    invoiceData?.data?.delivery?.custom_discard_old_furniture,
                  )}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>棄置產品費(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_discard_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                {" "}
              </Grid>

              <Grid item xs={12} my={2.5}>
                <Divider />
              </Grid>
            </Grid>

            <Grid
              container
              rowGap="20px"
              aria-labelledby="invoice-delivery-info"
            >
              <Grid item xs={3}>
                <InputLabel>收貨人姓名</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_recipient_name || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>收貨人電話</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_phone || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>收貨人其他電話</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_second_phone || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>區域</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_region || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>地區</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_district || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>街道號碼及屋苑大廈</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_address_line1 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>樓層及單位</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_address_line2 || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>上樓費(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_stairs_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>推路費(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_cart_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <InputLabel>偏遠地區(HK$)</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_remote_fee || "-"}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <InputLabel>地址備註</InputLabel>
                <Typography>
                  {invoiceData?.data?.delivery?.custom_address_remarks || "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* SHIPPING ADDRESS SUBMIT BUTTON... */}
        <Button
          className="shipping_address_submit_btn"
          type="submit"
          variant="contained"
          onClick={() => setIsSubmitModalOpen(true)}
        >
          確認銷售單
        </Button>
      </Box>

      <SubmitInvoiceDialog
        isLoading={isLoading}
        isModalOpen={isSubmitModalOpen}
        title="你確定要送出訂單嗎?"
        handleModalClose={handleSubmitModalToggle}
        handleConfirm={handleSubmitInvoice}
      />
    </RootLayout>
  );
};

export default CheckInfoStep;
