import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DefaultLoaderContainer from "src/components/loader";
import SalesInvoiceTable from "src/components/member/SalesInvoiceTable";
import { invoiceStatusData } from "src/data/invoiceStatusData";
import RootLayout from "src/layout/Layout";
import {
  IGetSaleInvoiceParams,
  useGetSalesInvoiceListMutation,
} from "src/redux/api/saleAndInvoiceApi";
import {
  setInvoiceEndDate,
  setInvoiceOrderBy,
  setInvoiceStartDate,
  setInvoiceStatus,
  setPage,
  setRowsPerPage,
  setSearchKeyword,
} from "src/redux/features/invoiceSlice";
import { formatDate } from "src/utils/formatDateTime";
import { Box, Button, MuiTextField, Stack, Typography } from "../../ui-library";
import { buildValidObject } from "src/utils/common";

const InvoiceListPage = () => {
  const dispatch = useDispatch();

  const {
    searchKeyword,
    invoiceStatus,
    invoiceStartDate,
    invoiceEndDate,
    page,
    rowsPerPage,
    invoiceOrderBy,
  } = useSelector((state: any) => state.invoiceState);

  const [getSalesInvoice, { isLoading: isInvoiceLoading, data: invoiceData }] =
    useGetSalesInvoiceListMutation();

  useEffect(() => {
    let timer = setTimeout(() => {
      getSalesInvoice(buildPayload());
    }, 1000);

    return () => clearTimeout(timer);
  }, [
    invoiceStatus,
    invoiceOrderBy,
    page,
    rowsPerPage,
    invoiceStartDate,
    invoiceEndDate,
  ]);

  // handle search
  useEffect(() => {
    let timer = setTimeout(() => {
      getSalesInvoice(buildPayload({ page: 1 }));
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchKeyword]);

  const buildPayload = (custQuery?: IGetSaleInvoiceParams) => {
    let obj = {
      page_size: rowsPerPage ?? 10,
      page: page + 1,
      status: invoiceStatus ?? undefined,
      query: searchKeyword ?? undefined,
      posting_date_start: invoiceStartDate ?? undefined,
      posting_date_end: invoiceEndDate ?? undefined,
      order_by: invoiceOrderBy ?? undefined,
    } as IGetSaleInvoiceParams;

    if (custQuery) {
      obj = { ...obj, ...custQuery };
    }
    return buildValidObject(obj);
  };

  const handleStatusSelection = (status: string | null) => {
    dispatch(setInvoiceStatus(status));
    handleResetFilter();
  };

  const handleResetFilter = () => {
    dispatch(setSearchKeyword(""));
    dispatch(setInvoiceStartDate(null));
    dispatch(setInvoiceEndDate(null));
    dispatch(setPage(0));
    dispatch(setRowsPerPage(10));
    dispatch(setInvoiceOrderBy("posting_date DESC"));
  };

  return (
    <RootLayout>
      <Stack spacing={"15px"}>
        {/* HEADER SECTION... */}
        <Box display="flex" justifyContent="space-between">
          <Box className="page__header-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="40"
              height="40"
              viewBox="0 0 40 40"
              fill="none"
            >
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M8.65 4C8.21239 4 7.79271 4.17384 7.48327 4.48327C7.17384 4.79271 7 5.21239 7 5.65V32.05C7 33.3628 7.52152 34.6219 8.44982 35.5502C9.37813 36.4785 10.6372 37 11.95 37H28.45C29.7628 37 31.0219 36.4785 31.9502 35.5502C32.8785 34.6219 33.4 33.3628 33.4 32.05V13.9C33.3999 13.4624 33.226 13.0428 32.9166 12.7334L24.6665 4.48345C24.3572 4.17399 23.9376 4.00009 23.5 4H8.65ZM23.5 7.9831L29.4169 13.9H23.5V7.9831ZM13.6 22.15C13.6 21.7124 13.7738 21.2927 14.0833 20.9833C14.3927 20.6738 14.8124 20.5 15.25 20.5H25.15C25.5876 20.5 26.0073 20.6738 26.3167 20.9833C26.6262 21.2927 26.8 21.7124 26.8 22.15C26.8 22.5876 26.6262 23.0073 26.3167 23.3167C26.0073 23.6262 25.5876 23.8 25.15 23.8H15.25C14.8124 23.8 14.3927 23.6262 14.0833 23.3167C13.7738 23.0073 13.6 22.5876 13.6 22.15ZM13.6 28.75C13.6 28.3124 13.7738 27.8927 14.0833 27.5833C14.3927 27.2738 14.8124 27.1 15.25 27.1H25.15C25.5876 27.1 26.0073 27.2738 26.3167 27.5833C26.6262 27.8927 26.8 28.3124 26.8 28.75C26.8 29.1876 26.6262 29.6073 26.3167 29.9167C26.0073 30.2262 25.5876 30.4 25.15 30.4H15.25C14.8124 30.4 14.3927 30.2262 14.0833 29.9167C13.7738 29.6073 13.6 29.1876 13.6 28.75Z"
                fill="#333333"
              />
            </svg>
            <Typography variant="h2" className="title">
              帳單紀錄
            </Typography>
          </Box>
        </Box>
      </Stack>

      <Box>
        <ul className="product-categories">
          {invoiceStatusData?.map((status, index: number) => (
            <li
              key={`status-${index}`}
              className={invoiceStatus === status.value ? "active" : ""}
              onClick={() => handleStatusSelection(status.value)}
            >
              {status.label}
            </li>
          ))}
        </ul>
        <Box className="page__content product__list__page">
          <Stack direction="row" spacing="20px" alignItems="center">
            <MuiTextField
              fullWidth
              placeholder="可按帳單號碼、會員姓名或電話號碼作搜尋..."
              value={searchKeyword}
              onChange={(e) => {
                dispatch(setSearchKeyword(e.target.value));
              }}
            />
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="建立日期"
                value={invoiceStartDate}
                onChange={(newValue) =>
                  dispatch(setInvoiceStartDate(formatDate(newValue)))
                }
              />
            </LocalizationProvider>
            <Typography>至</Typography>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="建立日期結束"
                value={invoiceEndDate}
                onChange={(newValue) =>
                  dispatch(setInvoiceEndDate(formatDate(newValue)))
                }
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              sx={{
                background: "#5A6070",
                padding: "15px",
                "&:hover": {
                  background: "#6B7383", // Change color on hover
                },
              }}
              onClick={handleResetFilter}
            >
              重設
            </Button>
          </Stack>

          <DefaultLoaderContainer isLoading={isInvoiceLoading}>
            <SalesInvoiceTable data={invoiceData} />
          </DefaultLoaderContainer>
        </Box>
      </Box>
    </RootLayout>
  );
};

export default InvoiceListPage;
