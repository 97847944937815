import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import memberRoutes from "src/constants/routes/member.routes";

const MemberNotSelectedAlert = (props: DialogProps) => {
  const { onClose, ...restProps } = props;

  const navigate = useNavigate();

  return (
    <Dialog {...restProps} aria-labelledby="member-not-selected-alert">
      <DialogTitle id="invoice-not-created-dialog-title">
        Member Not Selected
      </DialogTitle>

      <DialogContent>
        <DialogContentText id="invoice-not-created-dialog-description">
          Without selecting Member we cannot go further. <br />
          So, Please select Member First
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button
          onClick={() => navigate("/")}
          variant="outlined"
          sx={{ textTransform: "unset" }}
        >
          Go To Member Selection
        </Button>

        <Button
          variant="outlined"
          sx={{ textTransform: "unset" }}
          onClick={() => navigate("/")}
          autoFocus
        >
          Go To Home
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default MemberNotSelectedAlert;
