// INIT dotenv...
const envData = process.env;
const API_BASE_URL = envData.REACT_APP_API_BASE_URL ?? "https://erp.fb-erp.com";

export const ENV_CONFIG = {
  NODE_ENV: process.env.NODE_ENV,
  API_BASE_URL,
  API_URL: `${API_BASE_URL}/api`,
  // PRINTING_SERVER: envData.REACT_APP_PRINT_SERVER,
  PRINTING_SERVER: API_BASE_URL,
};
