import { Stack, Typography } from "@mui/material";
import { ReportIcon } from "src/assets/icons";

export const reportBreadcrumb = {
  indexPage: (
    <Stack
      direction={"row"}
      spacing={"10px"}
      borderBottom={"1px solid #A2AEBC"}
      pb="15px"
      mb={"15px"}
      alignItems={"center"}
    >
      <ReportIcon sx={{ color: "#000", fontWeight: 500, fontSize: 34 }} />
      <Typography fontWeight={700} fontSize={30.68}>
        報告
      </Typography>
    </Stack>
  ),
};
