import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  Divider,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import DefaultLoaderContainer from "src/components/loader";
import { IInvoiceCouponItem } from "src/interfaces/invoice.interfaces";
import {
  useApplyCouponsOnInvoiceMutation,
  useGetInvoiceCouponsMutation,
} from "src/redux/api/saleAndInvoiceApi";

const ApplyCouponModal = (props: IApplyCouponModalProps) => {
  const { open, handleClose, invoiceCode, handleCouponApplySuccess } = props;

  // LOCAL STATES...
  const [modifiedCouponData, setModifiedCouponData] =
    useState<IModifiedCouponDataState>({
      amount: [],
      item: [],
      percentage: [],
    });

  // REDUX THINGS...
  const [
    getInvoiceCoupons,
    { isSuccess: isCouponSuccess, data: couponData, isLoading },
  ] = useGetInvoiceCouponsMutation();

  const [
    applyCouponsOnInvoice,
    { isSuccess: isApplyCouponSuccess, isLoading: isCouponApplyLoading },
  ] = useApplyCouponsOnInvoiceMutation();

  // GET COUPONS DATA...
  useEffect(() => {
    if (open) getInvoiceCoupons({ invoice_name: invoiceCode });
  }, [invoiceCode, open]);

  // SET COUPONS DATA IN STATE...
  useEffect(() => {
    if (isCouponSuccess && couponData) {
      const couponD = couponData.data;

      const newData = {
        item: addDisabled(couponD.item),
        amount: addDisabled(couponD.amount),
        percentage: addDisabled(couponD.percentage),
      } as IModifiedCouponDataState;

      setModifiedCouponData(newData);
      //   console.log("new Modified data: ", newData);
    }
  }, [isCouponSuccess]);

  // HANDLE SUCCESS...
  useEffect(() => {
    if (isApplyCouponSuccess) handleCouponApplySuccess();
  }, [isApplyCouponSuccess]);

  const addDisabled = (item: IInvoiceCouponItem[]) => {
    return item.map((d) => {
      return {
        ...d,
        is_disabled: d.is_disabled,
        disabled_reason: d.minimum_item_required
          ? `(全單未滿${d.minimum_item_required}件貨品)`
          : d.minimum_amount
          ? `(全單未滿$ ${d.minimum_amount})`
          : null,
      } as IModifiedCouponDataStateItem;
    });
  };

  const handleOptionSelection = (params: IHandleOptionSelecctionParams) => {
    const oldState = modifiedCouponData;
    const parentArr = oldState[params.parent];
    // console.log("parent: ", params.parent);
    // console.log("selected item: ", params.couponItem);

    const index = parentArr.findIndex((d) => d.name === params.couponItem.name);
    if (index >= 0) {
      parentArr.splice(index, 1, params.couponItem);
      setModifiedCouponData((prevData) => {
        return { ...prevData, [params.parent]: parentArr };
      });
    }
  };

  // HANDLE SELECTION...
  const applyCoupon = () => {
    const getIds = (item: IModifiedCouponDataStateItem[]) => {
      const ids: Array<string> = [];

      item.forEach((d) => {
        if (d.is_used === true) ids.push(d.name);
      });

      return ids;
    };

    const couponCodes = getIds(modifiedCouponData.item).concat(
      getIds(modifiedCouponData.amount).concat(
        getIds(modifiedCouponData.percentage),
      ),
    );

    applyCouponsOnInvoice({
      invoice_name: invoiceCode,
      coupon_names: couponCodes,
    });
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="apply-coupon-modal"
      maxWidth="md"
      fullWidth
      sx={{ "& .MuiDialog-paper": { maxWidth: 950 } }}
    >
      <DefaultLoaderContainer
        isLoading={isLoading}
        minHeight={300}
        loaderBoxProps={{ minHeight: 300 }}
      >
        <Box
          sx={{ bgcolor: "#F7F7F7", p: 2.5 }}
          aria-labelledby="modal-content"
        >
          {/* MODAL HEADER SECTION... */}
          <Box aria-labelledby="modal-header">
            <Stack spacing={1.5} direction="row" alignItems={"center"}>
              <img
                src="/icons/discount-icon.svg"
                alt="discount-icon"
                width={40}
                height={40}
              />
              <Typography fontSize={20.8} fontWeight={700}>
                優惠券
              </Typography>
            </Stack>

            <Box my={2.5}>
              <Divider />
            </Box>
          </Box>

          {/* COUPON LIST CONTAINER... */}
          <Box
            maxHeight={"80vh"}
            sx={{ overflowY: "auto" }}
            aria-labelledby="coupon-list-container"
          >
            <Stack spacing={2.5}>
              {/* item -> Applicable product discounts */}
              <Stack spacing={"10px"}>
                <Typography fontSize={18}>適用產品折扣</Typography>

                {modifiedCouponData.item.length ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2.5}
                    flexWrap="wrap"
                  >
                    {modifiedCouponData.item.map((coupon) => {
                      return (
                        <StyledFormControlLabel
                          key={coupon.name}
                          disabled={coupon.is_disabled}
                          checked={coupon.is_used}
                          onChange={(e, checked) => {
                            handleOptionSelection({
                              parent: "item",
                              couponItem: { ...coupon, is_used: checked },
                            });
                          }}
                          control={<Checkbox />}
                          label={
                            <Box>
                              <Typography fontSize={18} lineHeight="normal">
                                {coupon.coupon_name}
                              </Typography>

                              {coupon.is_disabled ? (
                                <Typography fontSize={12} lineHeight="normal">
                                  {coupon.disabled_reason}
                                </Typography>
                              ) : null}
                            </Box>
                          }
                        />
                      );
                    })}
                  </Box>
                ) : (
                  <Box pl={2}>沒有可用的優惠券</Box>
                )}
              </Stack>

              {/* amount -> Whole order discount */}
              <Stack spacing={"10px"}>
                <Typography fontSize={18}>全單減價</Typography>

                {modifiedCouponData.amount.length ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2.5}
                    flexWrap="wrap"
                  >
                    {modifiedCouponData.amount.map((coupon) => {
                      return (
                        <StyledFormControlLabel
                          key={coupon.name}
                          disabled={coupon.is_disabled}
                          checked={coupon.is_used}
                          onChange={(e, checked) => {
                            handleOptionSelection({
                              parent: "amount",
                              couponItem: { ...coupon, is_used: checked },
                            });
                          }}
                          control={<Checkbox />}
                          label={
                            <Box>
                              <Typography fontSize={18} lineHeight="normal">
                                {coupon.coupon_name}
                              </Typography>

                              {coupon.is_disabled ? (
                                <Typography fontSize={12} lineHeight="normal">
                                  {coupon.disabled_reason}
                                </Typography>
                              ) : null}
                            </Box>
                          }
                        />
                      );
                    })}
                  </Box>
                ) : (
                  <Box pl={2}>沒有可用的優惠券</Box>
                )}
              </Stack>

              {/* percentage -> Whole order discount */}
              <Stack spacing={"10px"}>
                <Typography fontSize={18}>全單折扣</Typography>

                {modifiedCouponData.percentage.length ? (
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={2.5}
                    flexWrap="wrap"
                  >
                    {modifiedCouponData.percentage.map((coupon) => {
                      return (
                        <StyledFormControlLabel
                          key={coupon.name}
                          disabled={coupon.is_disabled}
                          checked={coupon.is_used}
                          onChange={(e, checked) => {
                            handleOptionSelection({
                              parent: "percentage",
                              couponItem: { ...coupon, is_used: checked },
                            });
                          }}
                          control={<Checkbox />}
                          label={
                            <Box>
                              <Typography fontSize={18} lineHeight="normal">
                                {coupon.coupon_name}
                              </Typography>

                              {coupon.is_disabled ? (
                                <Typography fontSize={12} lineHeight="normal">
                                  {coupon.disabled_reason}
                                </Typography>
                              ) : null}
                            </Box>
                          }
                        />
                      );
                    })}
                  </Box>
                ) : (
                  <Box pl={2}>沒有可用的優惠券</Box>
                )}
              </Stack>
            </Stack>
          </Box>

          {/* MODAL FOOTER SECTION... */}
          <Box aria-labelledby="modal-footer">
            <Box my={2.5}>
              <Divider />
            </Box>

            <DialogActions>
              <Button
                variant="outlined"
                sx={{
                  mr: 2.5,
                  minHeight: 60,
                  py: "14px",
                  px: "25px",
                  whiteSpace: "nowrap",
                }}
                onClick={handleClose}
              >
                取消
              </Button>

              <Button
                variant="contained"
                fullWidth
                sx={{ flexGrow: 1, textTransform: "none", minHeight: 60 }}
                onClick={applyCoupon}
                disabled={isCouponApplyLoading}
              >
                {isCouponApplyLoading ? "載入中" : "完成"}
              </Button>
            </DialogActions>
          </Box>
        </Box>
      </DefaultLoaderContainer>
    </Dialog>
  );
};

export default ApplyCouponModal;

export interface IApplyCouponModalProps {
  open: boolean;
  handleClose: () => void;
  invoiceCode: string;
  handleCouponApplySuccess: () => void;
}

interface IModifiedCouponDataStateItem extends IInvoiceCouponItem {
  is_disabled: boolean;
  disabled_reason?: string;
}

interface IModifiedCouponDataState {
  item: Array<IModifiedCouponDataStateItem>;
  amount: Array<IModifiedCouponDataStateItem>;
  percentage: Array<IModifiedCouponDataStateItem>;
}

interface IHandleOptionSelecctionParams {
  parent: "item" | "amount" | "percentage";
  couponItem: IModifiedCouponDataStateItem;
}

// STYLES...
const StyledFormControlLabel = styled(FormControlLabel)(
  ({ theme, checked, disabled }) => ({
    margin: 0,
    height: 60,
    padding: "15px",
    borderRadius: "5px",
    border: `1px solid ${checked ? "transparent" : "#E0E0E0"}`,
    background: checked ? "#E26F71" : disabled ? "#EAEAEA" : "white",
    color: checked ? "#fff" : "#5A6070",
    fontWeight: checked ? 700 : 500,
    "& .MuiTypography-root": { fontSize: 18 },
    "& .Mui-checked": {
      color: "#fff !important",
      //   color: "#E26F71 ",
    },
    "& .Mui-disabled": {
      color: "#959595 !important",
    },
  }),
);
