import { yupResolver } from "@hookform/resolvers/yup";
import ClearIcon from "@mui/icons-material/Clear";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Grid,
  MenuItem,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { Stack } from "@mui/system";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { CustTextField } from "src/components/form-components/FormInput";
import { CustSelect } from "src/components/form-components/FormSelect";
import { IMemberAddress } from "src/interfaces/member";
import {
  useCreateMemberAddressMutation,
  useUpdateMemberAddressMutation,
} from "src/redux/api/memberApi";
import { useGetAddressStatesAndCitiesQuery } from "src/redux/api/saleAndInvoiceApi";
import { IMemberState } from "src/redux/features/memberSlice";
import { createMemberAddressSchema } from "src/services/validations/shippingAddress.validation";
import * as yup from "yup";

type FormData = yup.InferType<typeof createMemberAddressSchema>;

const CreateEditAddressModal = (props: CreateEditAddressModalProps) => {
  const {
    modalConfig,
    handleModalSubmit,
    handleModalClose,
    editingAddressItem,
  } = props;

  // LOCAL STATES...
  const [cities, setCities] = useState<Array<string>>([]);
  const [error, setError] = useState("");
  const [initialFormData, setInitialFormData] = useState<FormData>({
    ...createMemberAddressSchema.cast({}),
  });
  const [editingId, setEditingId] = useState("");
  const [isDeleteFired, setIsDeleteFired] = useState(false);

  // REACT-HOOK FORM CONFIGURATION...
  const { handleSubmit, control, setValue, getValues, watch } =
    useForm<FormData>({
      defaultValues: initialFormData,
      resolver: yupResolver(createMemberAddressSchema),
      mode: "onChange",
      values: initialFormData,
    });

  // REDUX THINGS...
  const { selectedMember } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );

  // REDUX MUTATION...
  const { data: statesAndCitiesData } = useGetAddressStatesAndCitiesQuery({});
  const [
    createMemberAddress,
    {
      data: newCreatedAddress,
      isSuccess: isCreateAddressSuccess,
      isError: isCreateAddressError,
      error: createAddressError,
      isLoading: isCreateAddressLoading,
    },
  ] = useCreateMemberAddressMutation();
  const [
    updateMemberAddress,
    {
      data: updatedAddress,
      isSuccess: isUpdateAddressSuccess,
      isError: isUpdateAddressError,
      error: updateAddressError,
      isLoading: isUpdateAddressLoading,
    },
  ] = useUpdateMemberAddressMutation();

  // setting form fields if editing mode is on...
  useEffect(() => {
    if (
      modalConfig.isOpen &&
      modalConfig.isEditing &&
      editingAddressItem &&
      statesAndCitiesData
    ) {
      setEditingId(editingAddressItem.name);
      handleStateChange(editingAddressItem.state);

      setInitialFormData((prev) => {
        return {
          recipient_name: editingAddressItem.recipient_name,
          phone: editingAddressItem.phone,
          second_phone: editingAddressItem.second_phone,
          region: editingAddressItem.state,
          district: editingAddressItem.city,
          address_line1: editingAddressItem.address_line1,
          address_line2: editingAddressItem.address_line2,
          custom_remote_fee: editingAddressItem.custom_remote_fee,
          custom_cart_fee: editingAddressItem.custom_cart_fee,
          custom_stairs_fee: editingAddressItem.custom_stairs_fee,
          custom_address_remarks: editingAddressItem.custom_address_remarks,
        };
      });
    }
  }, [modalConfig, editingAddressItem, statesAndCitiesData]);

  // WATCH STATE CHANGE...
  useEffect(() => {
    const stateVal = getValues("region");
    if (stateVal) {
      handleStateChange(stateVal);
      setError("");
    }
  }, [watch("region")]);

  useEffect(() => {
    if (isCreateAddressSuccess && newCreatedAddress) {
      handleModalSubmit({
        action: "add new",
        addressItem: newCreatedAddress.data,
      });
    }
  }, [isCreateAddressSuccess, newCreatedAddress]);

  // HANDLE ADDRESS CREATE, EDIT, DELETE SUCCESS...
  useEffect(() => {
    // handle delete address...
    if (isUpdateAddressSuccess && updatedAddress && isDeleteFired) {
      handleModalSubmit({
        action: "delete",
        addressItem: updatedAddress.data,
      });
      setInitialFormData({ ...createMemberAddressSchema.cast({}) });
      setEditingId("");
      setIsDeleteFired(false);
    }

    // handle update address...
    if (isUpdateAddressSuccess && updatedAddress && !isDeleteFired) {
      handleModalSubmit({
        action: "edit",
        addressItem: updatedAddress.data,
      });
      setInitialFormData({ ...createMemberAddressSchema.cast({}) });
      setEditingId("");
    }
  }, [isUpdateAddressSuccess]);

  // HANDLE ERROR...
  useEffect(() => {
    if (isCreateAddressError && createAddressError) {
      const jsonString = (createAddressError as any)?.data?._server_messages;
      const formattedJsonString = jsonString.slice(1, -1);
      const parsedError = JSON.parse(formattedJsonString);
      setError(
        JSON.parse(parsedError).message ||
          "Error Occured While Creating Address",
      );
    }

    if (isUpdateAddressError && updateAddressError) {
      const jsonString = (updateAddressError as any)?.data?._server_messages;
      const formattedJsonString = jsonString.slice(1, -1);
      const parsedError = JSON.parse(formattedJsonString);
      setError(
        JSON.parse(parsedError).message ||
          "Error Occured While Updating Address",
      );
    }
  }, [isCreateAddressError, isUpdateAddressError]);

  // HANDLE ADDRESS STATE VALUE CHANGE...
  const handleStateChange = (stateName: string) => {
    const selectedState = statesAndCitiesData?.data.filter(
      (item) => item.state === stateName,
    );

    if (selectedState && selectedState.length) {
      setCities(selectedState[0].cities);
    }
  };

  const handleCancle = () => {
    setInitialFormData({ ...createMemberAddressSchema.cast({}) });
    handleModalClose();
  };

  // HANDLE DELETE ADDRESS...
  const handleDeleteAddress = () => {
    if (modalConfig.isEditing && editingId) {
      setIsDeleteFired(true);
      updateMemberAddress({ addressId: editingId, disabled: 1 });
    }
  };

  const submit = (fData: FormData) => {
    const addressPayload = {
      ...fData,
      consignee_phone: fData.phone.startsWith("+852")
        ? fData.phone
        : `+852${fData.phone}`,
    };
    setError("");
    if (selectedMember) {
      if (modalConfig.isEditing) {
        if (!isDeleteFired) {
          updateMemberAddress({ addressId: editingId, ...addressPayload });
        }
      } else {
        createMemberAddress({
          ...addressPayload,
          memberId: selectedMember.name,
        });
      }
    } else {
      setError("Member Is Not Selected, Please Select Member First");
    }

    // console.log("form data: ", fData);
  };

  return (
    <Dialog
      open={modalConfig.isOpen}
      aria-labelledby="create-shipping-address-modal"
      maxWidth="lg"
    >
      <Box
        component={"form"}
        onSubmit={handleSubmit(submit)}
        sx={{
          bgcolor: "#F7F7F7",
          maxHeight: "80vh",
          p: 2.5,
          width: "950px",
          overflowY: "auto",
        }}
      >
        {/* MODAL HEADER SECTION... */}
        <Box aria-labelledby="header-section">
          <Box display="flex" justifyContent="space-between">
            <Stack spacing={2.5} direction="row" alignItems="center">
              <Button
                variant="outlined"
                color="inherit"
                sx={{
                  p: "14px",
                  bgcolor: "#FFF",
                  borderRadius: "5px",
                  borderColor: "#E0E0E0",
                  color: "#DC2427",
                }}
                onClick={handleCancle}
              >
                <ClearIcon sx={{ color: "#5A6070" }} />
              </Button>

              <Stack spacing={"10px"} direction="row" alignItems="center">
                <Box
                  component="img"
                  src="/icons/delivery-van.svg"
                  alt="deleivery van"
                  height={24}
                  width={30}
                />
                <Typography fontSize={"28.8px"} fontWeight={700}>
                  編輯地址
                </Typography>
              </Stack>
            </Stack>

            {modalConfig.isEditing ? (
              <Button
                variant="contained"
                color="inherit"
                sx={{
                  px: "25px",
                  py: "14px",
                  bgcolor: "#FFF",
                  borderRadius: "5px",
                  borderColor: "#E0E0E0",
                  color: "#DC2427",
                }}
                disableElevation
                onClick={handleDeleteAddress}
              >
                刪除地址
              </Button>
            ) : null}
          </Box>

          <Box my={2.5} aria-labelledby="header-divider">
            <Divider />
          </Box>
        </Box>

        {error.length ? (
          <Box my={4} fontWeight={700} color={red[600]} fontSize={20}>
            <Typography
              variant="body1"
              dangerouslySetInnerHTML={{ __html: error }}
            />
          </Box>
        ) : null}

        {/* MODAL BODY SECTION... */}
        <Stack
          spacing={2.5}
          aria-labelledby="modal-body-content"
          sx={{
            "& .MuiInputBase-root": {
              bgcolor: "#fff",
              borderRadius: "5px",
              fontSize: 18,
            },
          }}
        >
          {/* FIELDS FOR CONSIGNEE... */}
          <Grid
            container
            columnSpacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-consignee"
          >
            <Grid item xs={4} pl={"0 !important"}>
              <Controller
                name="recipient_name"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="recipient_name"
                    label="收貨人姓名"
                    placeholder="陳小明"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="phone"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="phone"
                    label="收貨人電話"
                    placeholder="9843750574"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={4}>
              <Controller
                name="second_phone"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="second_phone"
                    label="收貨人其他電話"
                    placeholder="63958794"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* FIELDS FOR ADDRESS... */}
          <Grid
            container
            spacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-address"
          >
            <Grid item xs={6} pl={"0 !important"} pt={"0 !important"}>
              <Controller
                name="region"
                control={control}
                render={({ field, fieldState }) => (
                  <CustSelect
                    id="region"
                    label="區域"
                    placeholder="香港島"
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      setValue("district", ""); // Reset the city value when state changes
                    }}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                    wrapperStackProps={{
                      sx: {
                        "& .select_label": {
                          fontSize: 12,
                          color: "#333",
                          fontWeight: 700,
                        },
                      },
                    }}
                  >
                    {statesAndCitiesData &&
                      statesAndCitiesData.data.map((address, index) => (
                        <MenuItem
                          key={`address-state-${index}`}
                          value={address.state}
                        >
                          {address.state}
                        </MenuItem>
                      ))}
                  </CustSelect>
                )}
              />
            </Grid>

            <Grid item xs={6} pt={"0 !important"}>
              <Controller
                name="district"
                control={control}
                render={({ field, fieldState }) => (
                  <CustSelect
                    id="district"
                    label="地區"
                    placeholder="中西區"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                    wrapperStackProps={{
                      sx: {
                        "& .select_label": {
                          fontSize: 12,
                          color: "#333",
                          fontWeight: 700,
                        },
                      },
                    }}
                  >
                    {cities &&
                      cities.map((city, index) => (
                        <MenuItem key={`address-city-${index}`} value={city}>
                          {city}
                        </MenuItem>
                      ))}
                  </CustSelect>
                )}
              />
            </Grid>

            <Grid item xs={6} pl={"0 !important"}>
              <Controller
                name="address_line1"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="address_line1"
                    label="街道號碼及屋苑大廈"
                    placeholder="西營盤第三街"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <Controller
                name="address_line2"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="address_line2"
                    label="樓層及單位"
                    placeholder="16樓17室"
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>

          {/* FIELDS FOR EXTRA FEES... */}
          <Grid
            container
            columnSpacing={2.5}
            width={"100%"}
            m={0}
            aria-labelledby="form-field-section-for-extra-fees"
          >
            <Grid item xs={3} pl={"0 !important"}>
              <Stack spacing={2.5}>
                <Controller
                  name="custom_stairs_fee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_stairs_fee"
                      label="上樓費(HK$)"
                      placeholder="500"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />

                <Controller
                  name="custom_cart_fee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_cart_fee"
                      label="推路費(HK$)"
                      placeholder="500"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />

                <Controller
                  name="custom_remote_fee"
                  control={control}
                  render={({ field, fieldState }) => (
                    <CustTextField
                      id="custom_remote_fee"
                      label="偏遠地區(HK$)"
                      placeholder="500"
                      {...field}
                      error={fieldState.invalid}
                      helperText={fieldState.error?.message ?? ""}
                    />
                  )}
                />
              </Stack>
            </Grid>

            <Grid item xs={9}>
              <Controller
                name="custom_address_remarks"
                control={control}
                render={({ field, fieldState }) => (
                  <CustTextField
                    id="custom_address_remarks"
                    label="地址備註"
                    placeholder="沒有升降機"
                    multiline
                    fullWidth
                    rows={10}
                    {...field}
                    error={fieldState.invalid}
                    helperText={fieldState.error?.message ?? ""}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Stack>

        {/* MODAL FOOTER SECTION... */}
        <Box aria-labelledby="footer-section">
          <Box my={2.5} aria-labelledby="footer-divider">
            <Divider />
          </Box>

          <DialogActions sx={{ p: 0, mt: 3, justifyContent: "flex-start" }}>
            <Button
              variant="outlined"
              color="inherit"
              sx={{
                px: "25px",
                py: "14px",
                bgcolor: "#FFF",
                borderRadius: "5px",
                borderColor: "#E0E0E0",
                color: "#5A6070",
                fontWeight: 700,
              }}
              disableElevation
              onClick={handleCancle}
            >
              取消
            </Button>

            <Button
              variant="contained"
              type="submit"
              sx={{
                textTransform: "unset",
                color: "#fff",
                fontWeight: 700,
                px: "25px",
                py: "14px",
                flexGrow: 1,
              }}
              disabled={isCreateAddressLoading || isUpdateAddressLoading}
              disableElevation
            >
              {isCreateAddressLoading || isUpdateAddressLoading
                ? "加载中"
                : "完成"}
            </Button>
          </DialogActions>
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateEditAddressModal;

export interface CreateEditAddressModalProps {
  modalConfig: { isEditing: boolean; isOpen: boolean };
  editingAddressItem?: IMemberAddress;
  handleModalClose: () => void;
  handleModalSubmit: (payload: HandleAddressModalSubmitType) => void;
}

export interface HandleAddressModalSubmitType {
  action: "edit" | "delete" | "add new";
  addressItem: IMemberAddress;
}
