import { Fragment, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import CustLoadingButton from "src/components/form-components/CustLoadingButton";
import {
  ManagerCredentialsForReturnDialog,
  SaleReturnStatusDialog,
  SelectReturnPersonDialog,
} from "src/components/invoice/InvoiceReturnDialogs";
import LoadingModal from "src/components/loader/LoadingModal";
import invoiceRoutes from "src/constants/routes/invoice.routes";
import { IInvoiceDetail } from "src/interfaces/invoice.interfaces";
import RootLayout from "src/layout/Layout";
import {
  ICreatePaymentEntryPayload,
  useConfirmSaleReturnWithPaymentMutation,
  useGetInvoiceByIdMutation,
  useTransferSaleInvoiceMutation,
} from "src/redux/api/saleAndInvoiceApi";
import { setIsInvoiceDrawerOpen } from "src/redux/features/invoiceSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";
import { setSelectedSupplierId } from "src/redux/features/invoiceSlice";
import {
  setSelectedInvoiceId,
  setSelectedMember,
} from "src/redux/features/memberSlice";
import {
  generateRemoteMediaUrl,
  handleWordingOfAbandonFurniture,
} from "src/utils/common";
import { printInvoiceIntoBrowser } from "src/utils/print-invoice";
import {
  Box,
  Button,
  ChevronLeft,
  CircularProgress,
  Divider,
  Grid,
  InputLabel,
  Stack,
  Typography,
} from "../../../ui-library";
import { InvoicePreviewDialog } from "./_components/InvoicePreviewDialog";
import InvoiceTransferConfirmDialog from "./_components/InvoiceTransferConfirmDialog";
import TransferInvoiceDialog from "./_components/InvoiceTransferDialog";
import RefundPaymentModal from "./_components/RefundPaymentModal";
import { SelectBranchNameDialog } from "./_components/SelectBranchDialog";
import InvoiceTransferStatusDialog from "./_components/InvoiceTransferStatusDialog";
import memberRoutes from "src/constants/routes/member.routes";

const InvoiceReturnDetailPage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { invoiceId } = useParams();

  // LOCAL STATES...
  const [isLoading, setIsLoading] = useState(false);
  const [isReSelectLoading, setIsReSelectLoading] = useState(false);
  const [isRefundPaymentModalOpen, setIsRefundPaymentModalOpen] =
    useState(false);
  const [addPaymentPayload, setAddPaymentPayload] =
    useState<ICreatePaymentEntryPayload>();
  const [returnPersonDialogConfig, setReturnPersonDialogConfig] = useState({
    open: false,
    sale_person: "",
  });
  const [saleReturnManagerCredDialogConfig, setSaleManagerCredDialogConfig] =
    useState({
      open: false,
      is_success: true,
      message: {} as any,
    });
  const [saleReturnStatusDialog, setSaleReturnStatusDialog] = useState(false);
  const [returnType, setReturnType] = useState<"transfer" | "return">("return");
  const [selectBranchDialogConfig, setSelectBranchDialogConfig] = useState({
    open: false,
    new_invoice_name: "",
  });
  const [selectedInvoicePreviewDialog, setSelectedInvoicePreviewDialog] =
    useState(false);
  const [selectedTransferIntoInvoice, setSelectedTransferIntoInvoice] =
    useState<IInvoiceDetail>();
  const [transferInvoiceDialogConfig, setTransferInvoiceDialogConfig] =
    useState({ transfer_amt: 0, custom_journal_notes: "", open: false });
  const [
    invoiceTransferConfirmDialogConfig,
    setInvoiceTransferConfirmDialogConfig,
  ] = useState({ isConfirm: false, open: false });

  const [isTransferInvoiceStatusModal, setIsTransferInvoiceStatusModal] =
    useState(false);

  // REDUX THINGS..
  const [getInvoiceById, { data: invoiceData, isLoading: getInvoiceLoading }] =
    useGetInvoiceByIdMutation();

  const [
    confirmSaleReturnWithPayment,
    {
      // isLoading: isConfirmSaleReturnLoading,
      isSuccess: isConfirmSaleReturnSuccess,
      isError: isConfirmSaleReturnError,
      error: confirmSaleReturnError,
    },
  ] = useConfirmSaleReturnWithPaymentMutation();

  const [
    transferSaleInvoice,
    {
      isLoading: isTransferInvoiceLoading,
      isSuccess: isTransferInvoiceSuccess,
      isError: isTransferInvoiceError,
      error: transferSaleInvoiceError,
    },
  ] = useTransferSaleInvoiceMutation();

  // INIT STATE CHECKS...
  useEffect(() => {
    if (invoiceId) {
      getInvoiceById({ invoice_name: invoiceId });
    }
  }, [invoiceId]);

  // track the confirm sale return invoice...
  useEffect(() => {
    setSaleManagerCredDialogConfig((oldState) => {
      return {
        ...oldState,
        open: false,
        message: JSON.stringify(confirmSaleReturnError ?? "{}", null, 2),
        is_success: isConfirmSaleReturnSuccess ? true : false,
      };
    });
    if (isConfirmSaleReturnSuccess) {
      getInvoiceById({ invoice_name: String(invoiceId) });
    }

    if (isConfirmSaleReturnError || isConfirmSaleReturnSuccess) {
      setSaleReturnStatusDialog(true);
    }
  }, [isConfirmSaleReturnSuccess, isConfirmSaleReturnError]);

  // track transfer invoice response
  useEffect(() => {
    if (isTransferInvoiceLoading) setIsLoading(true);
    if (isTransferInvoiceSuccess || isTransferInvoiceError) {
      setIsLoading(false);
      setIsTransferInvoiceStatusModal(true);
    }
  }, [
    isTransferInvoiceLoading,
    isTransferInvoiceSuccess,
    isTransferInvoiceError,
    transferSaleInvoiceError,
  ]);

  const handleInvoiceDrawerClose = () => {
    dispatch(setIsInvoiceDrawerOpen(false));
    navigate(invoiceRoutes.base);
  };

  // HANDLE PRINT INVOICE...
  const handlePrintInvoice = async () => {
    setIsLoading(true);
    if (invoiceData && invoiceData.data && invoiceData.data.name) {
      await printInvoiceIntoBrowser(invoiceData.data);
    }
    setIsLoading(false);
  };

  // HANDLE PRINT INVOICE...
  const handleReselect = async () => {
    setIsReSelectLoading(true);
    if (invoiceData && invoiceData.data) {
      console.log(invoiceData.data);
      dispatch(setSelectedMember(invoiceData.data.customer));
      dispatch(setSelectedSupplierId(invoiceData.data.supplier));
      dispatch(setSelectedInvoiceId(invoiceData.data.name));
    }
    setIsReSelectLoading(false);
    navigate(memberRoutes.productSelectionStep.couponInfo);
  };

  // HANDLE IMAGE LOAD ERROR
  const handleImageError = (e: any) => {
    e.target.src = "/images/NoImage.svg"; // Replace with the path to your default image
  };

  const getSalePersonInfo = () => {
    let main_person_count = 0;
    let main_person = "";

    let other_person_count = 0;
    let other_person = "";

    if (invoiceData?.data?.sales_team?.length) {
      invoiceData.data.sales_team.forEach((p, ind) => {
        if (p.type === "main") {
          let name = p.sales_person_name;
          if (p?.custom_sales_code) name = `${name} ${p.custom_sales_code}`;
          if (main_person_count > 1) main_person = `${main_person}/${name}`;
          else main_person = name;
          main_person_count += 1;
          //
        } else if (p.type === "other") {
          let name2 = p.sales_person_name;
          if (p?.custom_sales_code) name2 = `${name2} ${p.custom_sales_code}`;
          if (other_person_count > 1) other_person = `${other_person}/${name2}`;
          else other_person = name2;
        }
      });
    }

    return {
      main_person,
      other_person,
    };
  };

  const handleConfirmReturn = (managerCred: { pwd: string; usr: string }) => {
    if (
      addPaymentPayload &&
      invoiceData?.data?.name &&
      returnPersonDialogConfig.sale_person
    ) {
      confirmSaleReturnWithPayment({
        custom_handler: returnPersonDialogConfig.sale_person,
        invoice_name: String(invoiceData?.data?.name),
        mode_of_payments: addPaymentPayload.mode_of_payments.map((item) => {
          return {
            amount: item.amount,
            mode_of_payment: item.mode_of_payment,
          };
        }) as any,
        pwd: managerCred.pwd,
        usr: managerCred.usr,
        custom_payment_remarks: addPaymentPayload.payment_remarks,
      });
    }
  };

  return (
    <RootLayout>
      <Box aria-label="invoice-return-detail-page">
        <Stack spacing={2.5}>
          <Box aria-label="header-section" display="flex" alignItems="center">
            <StyledHeaderBackButton
              variant="outlined"
              startIcon={<ChevronLeft />}
              onClick={handleInvoiceDrawerClose}
            >
              返回
            </StyledHeaderBackButton>

            <Box flexGrow={1} aria-labelledby="invoice-name">
              <Stack
                spacing={"10px"}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                <Box
                  component="img"
                  src="/icons/docs.svg"
                  width={40}
                  height={40}
                  alt="doc icon"
                />

                <Typography fontSize={"30px"} fontWeight={700}>
                  {`銷售單#${invoiceData?.data?.name ?? ""} - ${
                    invoiceData?.data?.posting_date ?? ""
                  }`}
                </Typography>
              </Stack>
            </Box>

            <Box aria-label="invoice-status-box">
              <Box
                p={"15px"}
                fontSize={18}
                fontWeight={700}
                color="#DC2427"
                border="1px solid #DC2427"
                bgcolor="#F7F7F7"
                borderRadius={"6px"}
              >
                已退單
              </Box>
            </Box>
          </Box>
        </Stack>

        <Box
          className="check__info__page__content checkout__process"
          display="flex"
          flexDirection="column"
          gap="20px"
          marginTop="20px"
        >
          {/* SALE RETURN HANDLER INTO... */}
          <Box aria-label="return-handeler-info" className="salesperson__info">
            <Stack
              direction="row"
              spacing={1}
              py="20.5px"
              px="30px"
              borderRadius="5px 5px 0 0"
              bgcolor="#DC2427"
              width="fit-content"
              color="#fff"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/sale-girl.svg"
                  alt="sale-girl"
                  width={24}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography fontSize={18} fontWeight={700}>
                退單信息
              </Typography>
            </Stack>

            <Box
              padding="30px 20px 20px 20px"
              borderRadius="0 5px 5px 5px"
              border="1px solid #E0E0E0"
              bgcolor="#fff"
            >
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <InputLabel>退單員</InputLabel>
                  <Typography>
                    {invoiceData?.data?.custom_return_handler}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>退貨日期</InputLabel>
                  <Typography>
                    {invoiceData?.data?.return_posting_date}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* SALE PERSON INFORMATION SECTION... */}
          <Box aria-label="return-handeler-info" className="salesperson__info">
            <Stack
              direction="row"
              spacing={1}
              py="20.5px"
              px="30px"
              borderRadius="5px 5px 0 0"
              bgcolor="#5A6070"
              width="fit-content"
              color="#fff"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/sale-girl.svg"
                  alt="sale-girl"
                  width={24}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography fontSize={18} fontWeight={700}>
                銷售員信息
              </Typography>
            </Stack>

            <Box
              padding="30px 20px 20px 20px"
              borderRadius="0 5px 5px 5px"
              border="1px solid #E0E0E0"
              bgcolor="#fff"
            >
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  <InputLabel>主銷售員</InputLabel>
                  <Typography>
                    {getSalePersonInfo().main_person ?? "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>其他銷售員</InputLabel>
                  <Typography>
                    {getSalePersonInfo().other_person ?? "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* PRODUCT INFORMATION SECTION... */}
          <Box className="product__info">
            <Stack
              direction="row"
              spacing={1}
              py="20.5px"
              px="30px"
              borderRadius="5px 5px 0 0"
              bgcolor="#5A6070"
              width="fit-content"
              color="#fff"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/info.svg"
                  alt="sale-girl"
                  width={24}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography fontSize={18} fontWeight={700}>
                商品信息
              </Typography>
            </Stack>

            <Box
              display="flex"
              flexDirection="column"
              gap="20px"
              padding="20px 20px 20px 20px"
              borderRadius="0 5px 5px 5px"
              border="1px solid #E0E0E0"
              bgcolor="#fff"
              className="MuiGrid-item"
            >
              <Stack direction="row" alignItems="center">
                <InputLabel>供應商</InputLabel>&nbsp;
                <Typography sx={{ padding: "0px !important" }}>
                  {invoiceData?.data?.supplier || "-"}
                </Typography>
              </Stack>
              <Box aria-label="product-table">
                <Stack
                  aria-label="header-and-data-section"
                  spacing={5}
                  sx={{
                    border: "1.125px solid #E0E0E0",
                    borderRadius: "5px",
                    padding: "20px 12px",
                  }}
                >
                  <Grid
                    width={"100%"}
                    container
                    aria-label="table-header"
                    columnSpacing={3}
                    sx={{
                      height: 70,
                      fontWeight: 500,
                      fontSize: 20,
                      borderBottom: "1px solid #BDBDBD",
                      py: "10px",
                      "& .table_header": {
                        "&:not(:last-child)": {
                          borderRight: "1px solid #BDBDBD",
                        },
                      },
                    }}
                  >
                    <Grid item xs={6} className="table_header">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        商品資料
                      </Box>
                    </Grid>

                    <Grid item xs={2} className="table_header">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        單價(HKD)
                      </Box>
                    </Grid>

                    <Grid item xs={2} className="table_header">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        折扣(HKD)
                      </Box>
                    </Grid>

                    <Grid item xs={2} className="table_header">
                      <Box
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                        height="100%"
                      >
                        金額(HKD)
                      </Box>
                    </Grid>
                  </Grid>

                  {/* START: Table content (Product) */}
                  {invoiceData?.data?.items?.map((product, index: number) => (
                    <Grid
                      key={`invoice-item-${index}`}
                      width={"100%"}
                      container
                      columnSpacing={3}
                      aria-label={`product-item-section`}
                      sx={{
                        "& .MuiGrid-item": {
                          "&:not(:last-child)": {
                            borderRight: "1px solid #BDBDBD",
                          },
                        },
                      }}
                    >
                      <Grid item xs={6}>
                        <Box
                          display="flex"
                          alignItems="center"
                          gap="20px"
                          aria-label="invoice-product-item"
                        >
                          <img
                            src={generateRemoteMediaUrl(
                              product.custom_template_thumbnail,
                            )}
                            alt={product.item_name ?? "product image"}
                            width={158.625}
                            style={{ objectFit: "contain" }}
                            onError={handleImageError}
                          />

                          <Stack gap="10px">
                            <Box
                              display="flex"
                              flexDirection="column"
                              gap="5px"
                            >
                              <Typography
                                sx={{
                                  padding: "0 !important",
                                }}
                              >
                                {product?.item_code || "-"}
                              </Typography>
                              <Typography
                                sx={{
                                  fontSize: "20px !important",
                                  fontWeight: "700 !important",
                                  padding: "0 !important",
                                }}
                              >
                                {product?.item_name || "-"}
                              </Typography>
                              <Typography
                                sx={{
                                  padding: "0 !important",
                                }}
                              >
                                {product.custom_width ? (
                                  <span>{product.custom_width}cm(W)</span>
                                ) : null}
                                {product.custom_height ? (
                                  <span> x {product.custom_height}cm(H)</span>
                                ) : null}
                                {product.custom_size_remarks ? (
                                  <span>[{product.custom_size_remarks}]</span>
                                ) : null}
                              </Typography>
                            </Box>
                            {/* <Typography
                              sx={{
                                padding: "0 !important",
                                fontSize: "18px !important",
                              }}
                            >
                              {product?.custom_item_remarks || "-"}
                            </Typography> */}
                            <Typography
                              sx={{
                                padding: "0 !important",
                                fontSize: "18px !important",
                              }}
                            >
                              {product?.sales_invoice_custom_item_remarks ||
                                "-"}
                            </Typography>
                            {product.attributes.length ? (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexDirection: "column",
                                  padding: "8px 0px",
                                }}
                              >
                                {product.attributes.map(
                                  (attr, index: number) => {
                                    return (
                                      <Typography
                                        id={attr.attribute}
                                        key={`product-attribute-${index}`}
                                        sx={{
                                          display: "inline-flex",
                                          flexDirection: "row",
                                          padding: "0 !important",
                                          fontSize: "15px !important",
                                        }}
                                      >
                                        <Box component="span">
                                          {attr.attribute}:{" "}
                                        </Box>
                                        <Box component="span" fontWeight={400}>
                                          {attr.attribute_value}
                                        </Box>
                                      </Typography>
                                    );
                                  },
                                )}
                              </Box>
                            ) : (
                              <Typography
                                sx={{
                                  padding: "8px 0 !important",
                                  fontSize: "15px !important",
                                  fontWeight: "500 !important",
                                }}
                              >
                                產品沒有選項
                              </Typography>
                            )}

                            <Typography
                              sx={{
                                padding: "0 !important",
                                fontSize: "20px !important",
                                fontWeight: "700 !important",
                              }}
                            >
                              x{product?.qty || 1}
                            </Typography>
                          </Stack>
                        </Box>
                      </Grid>

                      <Grid item xs={2}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          alignItems="center"
                          justifyContent="center"
                          height="100%"
                        >
                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "14px !important",
                              fontWeight: "500 !important",
                              textDecorationLine: "line-through",
                            }}
                          >
                            $ {product?.custom_original_price || null}
                          </Typography>

                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "14px !important",
                              fontStyle: "italic !important",
                              color: "#DC2427 !important",
                            }}
                          >
                            {product?.custom_markup_discount}% off
                          </Typography>

                          <InputLabel>$ {product?.rate}</InputLabel>
                        </Box>
                      </Grid>

                      <Grid item xs={2}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          {product.applied_coupons.length ? (
                            <Stack spacing={1}>
                              {product.applied_coupons.map((d, index) => {
                                return (
                                  <Box key={`${d.coupon_code}-coupon-${index}`}>
                                    <Typography
                                      sx={{
                                        padding: "0 !important",
                                        fontSize: "20px !important",
                                        fontWeight: "700 !important",
                                      }}
                                    >
                                      -$ {d.amount}
                                    </Typography>
                                    <Button
                                      sx={{
                                        background: "#828282",
                                        color: "#fff !important",
                                      }}
                                      disabled
                                    >
                                      {d.coupon_name}
                                    </Button>
                                  </Box>
                                );
                              })}
                            </Stack>
                          ) : (
                            <>-</>
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={2}>
                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                          height="100%"
                        >
                          <Typography
                            sx={{
                              padding: "0 !important",
                              fontSize: "24px !important",
                              fontWeight: "700 !important",
                            }}
                          >
                            {product.custom_discounted_amount}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  ))}
                  {/* END: Table content (Product) */}
                </Stack>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                gap="20px"
                padding="30px 20px 20px 20px"
                border="1.13px solid #E0E0E0"
                borderRadius="5px"
              >
                <Stack direction="row" justifyContent="space-between">
                  <InputLabel>產品累計</InputLabel>
                  <Typography
                    sx={{
                      padding: "0px !important",
                      fontWeight: "600 !important",
                    }}
                  >
                    ${" "}
                    {invoiceData?.data?.custom_total_item_discounted_amount ||
                      "-"}
                  </Typography>
                </Stack>
                {invoiceData?.data?.applied_coupons?.map(
                  (coupon, index: number) => (
                    <Stack
                      key={`applied-coupon-${index}`}
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <InputLabel>全單折扣</InputLabel>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "60px",
                          alignItems: "center",
                        }}
                      >
                        <Button
                          sx={{
                            background: "#828282",
                            color: "#fff !important",
                          }}
                          disabled
                        >
                          {coupon?.coupon_name}
                        </Button>
                        <Typography
                          sx={{
                            padding: "0px !important",
                            fontWeight: "600 !important",
                          }}
                        >
                          -$ {coupon?.amount}
                        </Typography>
                      </Box>
                    </Stack>
                  ),
                )}
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                  <InputLabel>全單總計</InputLabel>
                  <Typography
                    sx={{
                      padding: "0px !important",
                      fontWeight: "600 !important",
                    }}
                  >
                    $ {invoiceData?.data?.rounded_total}
                  </Typography>
                </Stack>
              </Box>
            </Box>
          </Box>

          {/* PAYMENT INFORMATION SECTION... */}
          <Box className="payment__info">
            <Stack
              direction="row"
              spacing={1}
              py="20.5px"
              px="30px"
              borderRadius="5px 5px 0 0"
              bgcolor="#5A6070"
              width="fit-content"
              color="#fff"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/payment-card.svg"
                  alt="payment-card"
                  width={24}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography fontSize={18} fontWeight={700}>
                付款信息
              </Typography>
            </Stack>

            <Stack
              spacing={2.5}
              padding="20px 20px 20px 20px"
              borderRadius="0 5px 5px 5px"
              border="1px solid #E0E0E0"
              bgcolor="#fff"
            >
              <Box
                display="flex"
                flexDirection="column"
                gap="20px"
                padding="30px 20px 20px 20px"
                borderRadius="5px"
                border="1px solid #E0E0E0"
                className="MuiGrid-item"
              >
                {/* TOTAL PAID FOR INVOICE BY USER PAYMENT INFO... */}
                {invoiceData?.data?.payments &&
                  invoiceData.data.payments.map((payment, index) => (
                    <Fragment key={`payment-${index}`}>
                      <Stack direction="row">
                        <Typography sx={{ padding: "0px !important" }}>
                          <Box component={"span"} mr={1}>
                            付款於
                          </Box>
                          <Box component={"span"}>{payment?.date ?? "-"}</Box>
                        </Typography>
                      </Stack>

                      <Stack direction="row" justifyContent="space-between">
                        <Button
                          sx={{
                            background: "#828282",
                            color: "#fff !important",
                          }}
                          disabled
                        >
                          {payment.mode_of_payment ?? "-"}
                        </Button>
                        <InputLabel>
                          -$ {payment?.total_allocated_amount ?? 0}
                        </InputLabel>
                      </Stack>

                      {payment?.custom_payment_remarks ? (
                        <Stack direction="row" alignItems="center">
                          <InputLabel>備註 : </InputLabel>&nbsp;
                          <Typography sx={{ padding: "0px !important" }}>
                            {payment.custom_payment_remarks || "-"}
                          </Typography>
                        </Stack>
                      ) : null}
                    </Fragment>
                  ))}

                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ padding: "0px !important" }}>
                    已付
                  </Typography>
                  <InputLabel>$ {invoiceData?.data?.paid_total}</InputLabel>
                </Stack>

                {/* REFUND PAYMENT INFO SECTION... */}
                {(invoiceData && invoiceData.data.return_payments) ||
                invoiceData?.data.transfer_payments ? (
                  <Divider />
                ) : null}
                <Stack spacing={2.5} aria-label="refund-payment-info">
                  {invoiceData &&
                    invoiceData.data.return_payments.map((payment, index) => (
                      <Stack spacing={2.5} key={`refund-payment-${index}`}>
                        <Stack direction="row">
                          <Typography sx={{ padding: "0px !important" }}>
                            <Box component={"span"} mr={1}>
                              退款於
                            </Box>
                            <Box component={"span"}>
                              {payment?.date} (
                              {payment.custom_handler_custom_sales_code}{" "}
                              {payment.custom_handler_name})
                            </Box>
                          </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                          <Button
                            sx={{
                              background: "#DC2427",
                              color: "#fff !important",
                            }}
                            disabled
                          >
                            {payment.mode_of_payment ?? "-"}
                          </Button>
                          <InputLabel>
                            -$ {payment?.total_allocated_amount ?? 0}
                          </InputLabel>
                        </Stack>

                        {payment?.custom_payment_remarks ? (
                          <Stack direction="row" alignItems="center">
                            <InputLabel>備註 : </InputLabel>&nbsp;
                            <Typography sx={{ padding: "0px !important" }}>
                              {payment.custom_payment_remarks || "-"}
                            </Typography>
                          </Stack>
                        ) : null}
                      </Stack>
                    ))}

                  {invoiceData &&
                    invoiceData.data.transfer_payments.map((payment, index) => (
                      <Stack spacing={2.5} key={`transfer-payment-${index}`}>
                        <Stack direction="row">
                          <Typography sx={{ padding: "0px !important" }}>
                            <Box component={"span"} mr={1}>
                              轉單於
                            </Box>
                            <Box component={"span"}>
                              {payment?.date} (
                              {payment.custom_handler_custom_sales_code}{" "}
                              {payment.custom_handler_name})
                            </Box>
                          </Typography>
                        </Stack>

                        <Stack direction="row" justifyContent="space-between">
                          <Button
                            sx={{
                              background: "#DC2427",
                              color: "#fff !important",
                            }}
                            disabled
                          >
                            {payment.invoice_name ?? "-"}
                          </Button>
                          <InputLabel>-$ {payment?.amount ?? 0}</InputLabel>
                        </Stack>

                        {payment?.custom_journal_notes ? (
                          <Stack direction="row" alignItems="center">
                            <InputLabel>備註 : </InputLabel>&nbsp;
                            <Typography sx={{ padding: "0px !important" }}>
                              {payment.custom_journal_notes || "-"}
                            </Typography>
                          </Stack>
                        ) : null}
                      </Stack>
                    ))}
                </Stack>

                {/* RETURN TOTAL OUTSTANDING... */}
                <Divider />
                <Stack direction="row" justifyContent="space-between">
                  <Typography sx={{ padding: "0px !important" }}>
                    餘額
                  </Typography>
                  <InputLabel>
                    $ {invoiceData?.data?.return_outstanding_total}
                  </InputLabel>
                </Stack>
              </Box>

              {invoiceData?.data?.return_outstanding_total &&
              invoiceData?.data?.return_outstanding_total > 0 ? (
                <Stack direction={"row"} spacing={"30px"} flexWrap={"wrap"}>
                  <Button
                    color="error"
                    variant="contained"
                    onClick={() => {
                      setReturnType("return");
                      setIsRefundPaymentModalOpen(true);
                    }}
                    sx={{
                      px: "30px",
                      py: "12px",
                      background: "#DC2427 !important",
                      width: "calc(50% - 15px)",
                    }}
                  >
                    退款
                  </Button>

                  <Button
                    color="secondary"
                    variant="contained"
                    sx={{ px: "30px", py: "12px", width: "calc(50% - 15px)" }}
                    onClick={() => {
                      setReturnType("transfer");
                      setReturnPersonDialogConfig({
                        sale_person: "",
                        open: true,
                      });
                    }}
                  >
                    轉單
                  </Button>
                </Stack>
              ) : null}
            </Stack>
          </Box>

          {/* MEMBER INFORMATION SECTION... */}
          <Box className="member__info">
            <Stack
              direction="row"
              spacing={1}
              py="20.5px"
              px="30px"
              borderRadius="5px 5px 0 0"
              bgcolor="#5A6070"
              width="fit-content"
              color="#fff"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/account-box.svg"
                  alt="account-box"
                  width={24}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography fontSize={18} fontWeight={700}>
                會員
              </Typography>
            </Stack>
            <Box
              padding="30px 20px 20px 20px"
              borderRadius="0 5px 5px 5px"
              border="1px solid #E0E0E0"
              bgcolor="#fff"
            >
              <Grid container rowGap="20px">
                <Grid item xs={6}>
                  <InputLabel>會員編號</InputLabel>
                  <Typography>{invoiceData?.data?.customer?.name}</Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>生日月份</InputLabel>
                  <Typography>
                    {invoiceData?.data?.customer?.custom_birth_month}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>稱謂</InputLabel>
                  <Typography>
                    {invoiceData?.data?.customer?.salutation || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>姓名</InputLabel>
                  <Typography>
                    {invoiceData?.data?.customer?.customer_name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>電郵地址</InputLabel>
                  <Typography>
                    {invoiceData?.data?.customer?.custom_email || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>手機號碼</InputLabel>
                  <Typography>
                    {invoiceData?.data?.customer?.custom_unique_phone || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </Box>

          {/* SHIPPING INFORMATION SECTION... */}
          <Box className="shipping__info">
            <Stack
              direction="row"
              spacing={1}
              py="20.5px"
              px="30px"
              borderRadius="5px 5px 0 0"
              bgcolor="#5A6070"
              width="fit-content"
              color="#fff"
              alignItems="center"
            >
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/shipping-truck.svg"
                  alt="shipping-truck"
                  width={24}
                  style={{ objectFit: "contain" }}
                />
              </Box>
              <Typography fontSize={18} fontWeight={700}>
                送貨資料
              </Typography>
            </Stack>

            <Stack
              spacing={2.5}
              padding="30px 20px 20px 20px"
              borderRadius="0 5px 5px 5px"
              border="1px solid #E0E0E0"
              bgcolor="#fff"
            >
              <Grid container rowGap="20px">
                <Grid item container>
                  <Grid item xs={3}>
                    <InputLabel>收貨日期</InputLabel>
                    <Typography>
                      {invoiceData?.data?.delivery?.custom_delivery_date || "-"}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel>需要棄置舊傢具</InputLabel>
                    <Typography>
                      {handleWordingOfAbandonFurniture(
                        invoiceData?.data?.delivery
                          ?.custom_discard_old_furniture,
                      )}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <InputLabel>棄置產品費(HK$)</InputLabel>
                    <Typography>
                      {invoiceData?.data?.delivery?.custom_discard_fee || "-"}
                    </Typography>
                  </Grid>

                  <Grid item xs={3}>
                    {" "}
                  </Grid>

                  <Grid item xs={12} my={2.5}>
                    <Divider />
                  </Grid>
                </Grid>

                <Grid item xs={3}>
                  <InputLabel>收貨人姓名</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_recipient_name || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>收貨人電話</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_phone || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>收貨人其他電話</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_second_phone || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>區域</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_region || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>地區</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_district || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>街道號碼及屋苑大廈</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_address_line1 || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <InputLabel>樓層及單位</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_address_line2 || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>上樓費(HK$)</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_stairs_fee || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>推路費(HK$)</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_cart_fee || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <InputLabel>偏遠地區(HK$)</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_remote_fee || "-"}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <InputLabel>地址備註</InputLabel>
                  <Typography>
                    {invoiceData?.data?.delivery?.custom_address_remarks || "-"}
                  </Typography>
                </Grid>
              </Grid>
            </Stack>
          </Box>

          {/* INFO CONTROL SECTION... */}
          <CustLoadingButton
            loading={isLoading}
            variant="contained"
            onClick={handlePrintInvoice}
            loadingIndicator={
              <Stack spacing={2} direction={"row"} alignItems={"center"}>
                <Typography>列印帳單</Typography>
                <CircularProgress size={25} />
              </Stack>
            }
          >
            加载中
          </CustLoadingButton>

          {/* PURCHASE SAME ORDER FOR CUSTOMER... */}
          <Button
            variant="outlined"
            disabled={isReSelectLoading}
            onClick={handleReselect}
          >
            重新為客人選購同樣產品
          </Button>
        </Box>

        {/* --------------------- DIALOGS ------------------- */}
        <LoadingModal open={getInvoiceLoading || isLoading} />

        <RefundPaymentModal
          isOpen={isRefundPaymentModalOpen}
          invoiceDetail={invoiceData?.data ?? null}
          handleClose={() => setIsRefundPaymentModalOpen(false)}
          // handleSubmit={handleAddPaymentSubmit}
          handleSubmit={(paymentObj) => {
            setIsRefundPaymentModalOpen(false);
            setAddPaymentPayload(paymentObj);
            setReturnPersonDialogConfig((prevVal) => {
              return { sale_person: "", open: true };
            });
          }}
        />

        <SelectReturnPersonDialog
          open={returnPersonDialogConfig.open}
          handleModalClose={() => {
            setReturnPersonDialogConfig((prevVal) => {
              return { ...prevVal, open: false };
            });
          }}
          onSelectReturnPerson={(person) => {
            setReturnPersonDialogConfig({ open: false, sale_person: person });
            if (returnType === "return") {
              setSaleManagerCredDialogConfig((oldState) => {
                return { ...oldState, open: true };
              });
            } else {
              setSelectBranchDialogConfig({
                open: true,
                new_invoice_name: "",
              });
            }
          }}
        />

        <ManagerCredentialsForReturnDialog
          open={saleReturnManagerCredDialogConfig.open}
          handleModalClose={() => {
            setSaleManagerCredDialogConfig((oldState) => {
              return { ...oldState, open: false };
            });
          }}
          onSubmit={(cred) => {
            if (returnType === "return") {
              handleConfirmReturn(cred);
            } else {
              setSaleManagerCredDialogConfig((oldState) => {
                return { ...oldState, open: false };
              });

              transferSaleInvoice({
                source_invoice_name: String(invoiceData?.data.name),
                target_invoice_name: String(selectedTransferIntoInvoice?.name),
                amount: transferInvoiceDialogConfig.transfer_amt,
                custom_journal_notes:
                  transferInvoiceDialogConfig.custom_journal_notes,
                custom_handler: returnPersonDialogConfig.sale_person,
                usr: cred.usr,
                pwd: cred.pwd,
              });
            }
          }}
        />

        <SaleReturnStatusDialog
          open={saleReturnStatusDialog}
          invoice_id={String(invoiceId)}
          is_success={saleReturnManagerCredDialogConfig.is_success}
          message={saleReturnManagerCredDialogConfig.message}
          handleModalClose={() => {
            setSaleReturnStatusDialog(false);
          }}
        />

        {/* ------------ INVOICE TRANSFER RELATED DIALOGS ------------ */}
        <SelectBranchNameDialog
          open={selectBranchDialogConfig.open}
          handleModalClose={() => {
            setSelectBranchDialogConfig({ new_invoice_name: "", open: false });
          }}
          onSubmit={(new_invoice_name) => {
            setSelectBranchDialogConfig({ new_invoice_name, open: false });
            setSelectedInvoicePreviewDialog(true);
          }}
        />

        <InvoicePreviewDialog
          open={selectedInvoicePreviewDialog}
          invoice_name={selectBranchDialogConfig.new_invoice_name}
          handleModalClose={() => setSelectedInvoicePreviewDialog(false)}
          onSubmit={(invoice) => {
            setSelectedTransferIntoInvoice(invoice);
            setTransferInvoiceDialogConfig({
              transfer_amt: 0,
              custom_journal_notes: "",
              open: true,
            });
            setSelectedInvoicePreviewDialog(false);
          }}
        />

        {invoiceData?.data && selectedTransferIntoInvoice ? (
          <>
            <TransferInvoiceDialog
              open={transferInvoiceDialogConfig.open}
              sourceInvoice={invoiceData?.data}
              targetInvoice={selectedTransferIntoInvoice}
              handleModalClose={() =>
                setTransferInvoiceDialogConfig((prev) => {
                  return { ...prev, open: false };
                })
              }
              onSubmit={(transfer_amt, journal_note) => {
                setTransferInvoiceDialogConfig({
                  transfer_amt,
                  custom_journal_notes: journal_note,
                  open: false,
                });
                setInvoiceTransferConfirmDialogConfig({
                  isConfirm: false,
                  open: true,
                });
              }}
            />

            <InvoiceTransferConfirmDialog
              open={invoiceTransferConfirmDialogConfig.open}
              sourceInvoiceId={invoiceData.data.name}
              targetInvoiceId={selectedTransferIntoInvoice.name}
              handleModalClose={(isConfirmed) => {
                setInvoiceTransferConfirmDialogConfig({
                  isConfirm: isConfirmed,
                  open: false,
                });
                if (isConfirmed) {
                  setSaleManagerCredDialogConfig({
                    is_success: false,
                    message: "",
                    open: true,
                  });
                }
              }}
            />

            <InvoiceTransferStatusDialog
              open={isTransferInvoiceStatusModal}
              handleModalClose={() => {
                setIsTransferInvoiceStatusModal(false);
                getInvoiceById({ invoice_name: String(invoiceId) });
              }}
              sourceInvoiceId={invoiceData.data.name}
              targetInvoiceId={selectedTransferIntoInvoice.name}
              isSuccess={isTransferInvoiceSuccess}
              error={transferSaleInvoiceError}
            />
          </>
        ) : null}
      </Box>
    </RootLayout>
  );
};

export default InvoiceReturnDetailPage;
