import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DefaultLoaderContainer from "src/components/loader";
import memberRoutes from "src/constants/routes/member.routes";
import { IGetMemberListPayload } from "src/interfaces/member";
import { useGetMembersMutation } from "src/redux/api/memberApi";
import MemberTable from "../../components/member/MemberTable";
import RootLayout from "../../layout/Layout";
import { setSearchKeyword } from "../../redux/features/memberSlice";
import {
  AddCircle,
  Box,
  Button,
  InputAdornment,
  MuiTextField,
  SearchIcon,
  Stack,
  Typography,
} from "../../ui-library";
import { buildValidObject } from "src/utils/common";

const MemberList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { searchKeyword, page, rowsPerPage, memberOrderBy } = useSelector(
    (state: any) => state.memberState,
  );

  const [getMembers, { isLoading, data: membersData }] =
    useGetMembersMutation();

  useEffect(() => {
    const timer = setTimeout(() => {
      getMembers(buildPayload);
    }, 1000);

    return () => {
      clearTimeout(timer);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, memberOrderBy]);

  useEffect(() => {
    const timer = setTimeout(() => {
      getMembers(buildPayload({ page: 1 }));
    }, 1000);

    return () => {
      clearTimeout(timer);
    };
  }, [searchKeyword]);

  const buildPayload = (custQuery?: IGetMemberListPayload) => {
    let obj = {
      page_size: rowsPerPage,
      page: page + 1,
      query: searchKeyword,
      order_by: memberOrderBy,
    } as IGetMemberListPayload;

    if (custQuery) {
      obj = { ...obj, ...custQuery };
    }
    return buildValidObject(obj);
  };

  const handleSearch = async (e: any) => {
    const keyword = e.currentTarget.value || e.target.value;
    dispatch(setSearchKeyword(keyword));
  };

  return (
    <RootLayout>
      <Stack spacing={"15px"}>
        {/* HEADER SECTION... */}
        <Box display="flex" justifyContent="space-between">
          <Box className="page__header-title">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="39"
              height="40"
              viewBox="0 0 39 40"
              fill="none"
            >
              <path
                d="M9 28.5286C9 25.1953 15.6667 23.362 19 23.362C22.3333 23.362 29 25.1953 29 28.5286V30.1953H9M24 15.1953C24 16.5214 23.4732 17.7932 22.5355 18.7308C21.5979 19.6685 20.3261 20.1953 19 20.1953C17.6739 20.1953 16.4021 19.6685 15.4645 18.7308C14.5268 17.7932 14 16.5214 14 15.1953C14 13.8692 14.5268 12.5975 15.4645 11.6598C16.4021 10.7221 17.6739 10.1953 19 10.1953C20.3261 10.1953 21.5979 10.7221 22.5355 11.6598C23.4732 12.5975 24 13.8692 24 15.1953ZM4 8.52865V31.862C4 32.746 4.35119 33.5939 4.97631 34.219C5.60143 34.8441 6.44928 35.1953 7.33333 35.1953H30.6667C31.5507 35.1953 32.3986 34.8441 33.0237 34.219C33.6488 33.5939 34 32.746 34 31.862V8.52865C34 7.64459 33.6488 6.79674 33.0237 6.17162C32.3986 5.5465 31.5507 5.19531 30.6667 5.19531H7.33333C6.44928 5.19531 5.60143 5.5465 4.97631 6.17162C4.35119 6.79674 4 7.64459 4 8.52865Z"
                fill="#333333"
              />
            </svg>
            <Typography variant="h2" className="title">
              會員列表
            </Typography>
          </Box>

          <Button
            onClick={() => navigate(memberRoutes.createMember)}
            variant="contained"
            startIcon={<AddCircle />}
          >
            創建新會員
          </Button>
        </Box>

        {/* BODY SECTION... */}
        <Box className="page__content">
          <MuiTextField
            type="search"
            fullWidth
            autoComplete="off"
            placeholder="搜尋會員編號、姓名、電話號碼或電郵地址"
            name="search"
            value={searchKeyword}
            onBlur={handleSearch}
            onChange={handleSearch}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />

          <DefaultLoaderContainer isLoading={isLoading}>
            <MemberTable data={membersData} />
          </DefaultLoaderContainer>
        </Box>
      </Stack>
    </RootLayout>
  );
};

export default MemberList;
