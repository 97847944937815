import EditIcon from "@mui/icons-material/Edit";
import { Divider, FormControlLabelProps, FormHelperText } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustTextField } from "src/components/form-components/FormInput";
import { CustSelect } from "src/components/form-components/FormSelect";
import CreateEditAddressModal, {
  HandleAddressModalSubmitType,
} from "src/components/member-product-selection-steps/CreateEditAddressModal";
import ProductSelectionStepBreadcrumb from "src/components/member-product-selection-steps/ProductSelectStepBreadcrumb";
import MemberNotSelectedAlert from "src/components/member/MemberNotSelectedAlert";
import memberRoutes from "src/constants/routes/member.routes";
import { IMemberAddress } from "src/interfaces/member";
import { useGetMemberAddressesMutation } from "src/redux/api/memberApi";
import {
  IUpdateMemberAddressOfInvoicePayload,
  useDeleteInvoiceByIdMutation,
  useGetDeliveryDateOptionsMutation,
  useGetInvoiceByIdMutation,
  useUpdateMemberAddressOfInvoiceMutation,
} from "src/redux/api/saleAndInvoiceApi";
import { IMemberState } from "src/redux/features/memberSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";
import {
  AddCircle,
  Box,
  Button,
  Checkbox,
  ChevronLeft,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "src/ui-library";

const ShippingStep = () => {
  const navigate = useNavigate();

  // LOCAL STATES...
  const [selectedAddress, setSelectedAddress] = useState<IMemberAddress | null>(
    null,
  );
  const [addressList, setAddressList] = useState<IMemberAddress[]>([]);
  const [isDeliveryDateRequired, setIsDeliveryDateRequired] = useState(false);
  const [addressModalConfig, setAdressModalConfig] = useState({
    isEditing: false,
    isOpen: false,
  });
  const [formData, setFormData] = useState<FormDataStateType>({
    need_abandone_furniture: false,
    disposable_products: null,
    delivery_date_choice: "",
  });

  // REDUX THINGS...
  const { selectedMember, selectedInvoiceId } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );
  const [getInvoiceById, { data: invoiceData }] = useGetInvoiceByIdMutation();
  const [getDeliveryOptions, { data: deliveryDatesOptions }] =
    useGetDeliveryDateOptionsMutation();
  const [
    getMemberAddresses,
    { data: addressData, isSuccess: isAddressDataSuccess },
  ] = useGetMemberAddressesMutation();
  const [
    updateMemberAddressOfInvoice,
    {
      data: updateShippingAddressRes,
      isSuccess: isUpdateAddressSuccess,
      isLoading: isUpdateAddressLoading,
    },
  ] = useUpdateMemberAddressOfInvoiceMutation();
  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();

  // get delivery data options...
  useEffect(() => {
    getDeliveryOptions({ limit_page_length: 100 });
  }, []);

  // get invoice...
  useEffect(() => {
    getInvoiceById({ invoice_name: selectedInvoiceId });
  }, []);

  useEffect(() => {
    if (invoiceData) {
      setFormData((prevData) => {
        return {
          ...prevData,
          need_abandone_furniture:
            invoiceData?.data?.delivery?.custom_discard_old_furniture === 1
              ? true
              : false,
          disposable_products: invoiceData?.data?.delivery?.custom_discard_fee,
          delivery_date_choice:
            invoiceData?.data?.delivery?.custom_delivery_date,
        };
      });
    }
  }, [invoiceData]);

  // SAVING MEMBER ADDRESS LIST INTO STATE...
  useEffect(() => {
    if (isAddressDataSuccess && addressData?.data.length) {
      const filteredAddress = addressData.data.filter(
        (address) => address.disabled === 0,
      );

      setAddressList(filteredAddress);
      setSelectedAddress(filteredAddress[0]);
    }
  }, [isAddressDataSuccess]);

  // GET MEMBER ADDRESS AND UPDATE NEW ADDRESS...
  useEffect(() => {
    if (selectedMember && selectedMember.name) {
      getMemberAddresses({ memberId: selectedMember.name });
    }
  }, [selectedMember]);

  // HANDLING UPDATE ADDRESS SUCCESS...
  useEffect(() => {
    if (isUpdateAddressSuccess && updateShippingAddressRes?.data) {
      console.log(
        "update invoice address success: ",
        updateShippingAddressRes.data,
      );
      navigate(memberRoutes.productSelectionStep.paymentInfo);
    }
  }, [isUpdateAddressSuccess]);

  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      navigate("/");
    }
  }, [isDeleteInvoiceSuccess]);

  const handleAddressSelection = (
    event: React.ChangeEvent<HTMLInputElement>,
    value: string,
  ) => {
    const obj = addressList.filter((v) => v.name === value);

    if (obj && obj.length) {
      setSelectedAddress(obj[0]);
    }
  };

  const handleCreateAddressModalSubmit = (
    payload: HandleAddressModalSubmitType,
  ) => {
    if (payload.action === "add new") {
      setAddressList((prevState) => {
        return [...prevState, payload.addressItem];
      });
      setSelectedAddress(payload.addressItem);
    } else if (payload.action === "edit") {
      const oldList = [...addressList];

      // finding index of edited item...
      const indx = addressList.findIndex(
        (v) => v.name === payload.addressItem.name,
      );
      if (indx >= 0) {
        oldList.splice(indx, 1, payload.addressItem);

        setAddressList(oldList);
        setSelectedAddress(payload.addressItem);
      } else {
        setSelectedAddress(oldList[0]);
      }
    } else if (payload.action === "delete") {
      const oldList = [...addressList];

      // finding index of deleted item...
      const indx = addressList.findIndex(
        (v) => v.name === payload.addressItem.name,
      );

      if (indx >= 0) {
        oldList.splice(indx, 1);
        setAddressList(oldList);
      }
      setSelectedAddress(oldList[0]);
    }
    setAdressModalConfig({ isEditing: false, isOpen: false });
  };

  const handleCancelOrder = () => {
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  const handleNext = () => {
    if (selectedInvoiceId && selectedAddress) {
      if (!formData.delivery_date_choice) {
        setIsDeliveryDateRequired(true);
        return;
      }
      const payload = {
        invoiceId: selectedInvoiceId,
        shipping_address_name: selectedAddress.name,
        custom_delivery_date_option: formData.delivery_date_choice,
        custom_discard_old_furniture:
          formData.need_abandone_furniture === true ? 1 : 0,
        custom_discard_fee: formData.disposable_products,
      } as IUpdateMemberAddressOfInvoicePayload;

      updateMemberAddressOfInvoice(payload);
    }
  };

  return (
    <>
      <Stack spacing={2.5} p="30px">
        {/* HEADER SECTION...  */}
        <Stack spacing={2.5} aria-labelledby="header-section">
          <Box
            aria-label="header-section"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <StyledHeaderBackButton
              variant="outlined"
              startIcon={<ChevronLeft />}
              onClick={() => navigate(-1)}
            >
              返回
            </StyledHeaderBackButton>

            <Box>
              <ProductSelectionStepBreadcrumb active={4} />
            </Box>

            <Button
              variant="contained"
              startIcon={
                <Box display="flex" justifyContent="center" alignItems="center">
                  <img
                    src="/icons/cross.svg"
                    alt="cross-file"
                    width={20}
                    style={{ objectFit: "contain" }}
                  />
                </Box>
              }
              sx={{ px: 3.125, py: 1.25 }}
              onClick={handleCancelOrder}
            >
              取消下單
            </Button>
          </Box>
        </Stack>

        {/* USER INFO SECTION... */}
        <Box aria-labelledby="user-info-section">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/account-box.svg"
                alt="account-box"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              會員
            </Typography>
          </Stack>

          <Box
            padding="20px 20px 30px 20px"
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
            aria-labelledby="user-info-section"
          >
            <Grid container spacing={5}>
              <Grid item xs={6}>
                <InputLabel>會員編號</InputLabel>
                <Typography>
                  {invoiceData?.data.customer?.name ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>生日月份</InputLabel>
                <Typography>
                  {invoiceData?.data.customer?.custom_birth_month ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>稱謂</InputLabel>
                <Typography>
                  {invoiceData?.data.customer?.salutation ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>姓名</InputLabel>
                <Typography>
                  {invoiceData?.data.customer?.customer_name ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>電郵地址</InputLabel>
                <Typography>
                  {invoiceData?.data.customer?.custom_email ?? "-"}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <InputLabel>手機號碼</InputLabel>
                <Typography>
                  {invoiceData?.data.customer?.custom_unique_phone ?? "-"}
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Box>

        {/* SHIPPING ADDRESS SECTION... */}
        <Box aria-labelledby="shipping-address-section">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/shipping-truck.svg"
                alt="shipping-truck"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              送貨資料
            </Typography>
          </Stack>

          <Stack
            spacing={2.5}
            padding={5}
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
            aria-labelledby="address-selection-container"
          >
            {/* DELIVERY EXTRA INFO SECTION... */}
            <Box aria-labelledby="extra-form-fields-section">
              <Stack
                direction="row"
                spacing={5}
                alignItems={"flex-end"}
                sx={{
                  "& .MuiInputBase-root": {
                    bgcolor: "#fff",
                    borderRadius: "5px",
                    fontSize: 18,
                  },
                  "& .MuiInputBase-input": {
                    py: 3,
                  },
                }}
              >
                <Box width={"44%"}>
                  <CustSelect
                    id="delivery_date_choice"
                    name="delivery_date_choice"
                    placeholder="李"
                    label="送貨日期"
                    value={formData.delivery_date_choice}
                    error={isDeliveryDateRequired}
                    helperText="Required"
                    onChange={(e) => {
                      setIsDeliveryDateRequired(false);
                      setFormData((prev) => {
                        return {
                          ...prev,
                          delivery_date_choice: String(e.target.value),
                        };
                      });
                    }}
                    wrapperStackProps={{
                      sx: {
                        "& .select_label": {
                          fontSize: 12,
                          color: "#333",
                          fontWeight: 700,
                        },
                      },
                    }}
                  >
                    {deliveryDatesOptions &&
                      deliveryDatesOptions.data.map((deliveryDate, index) => (
                        <MenuItem
                          key={`delivery-date-${index}`}
                          value={deliveryDate.name}
                        >
                          {deliveryDate.name}
                        </MenuItem>
                      ))}
                  </CustSelect>
                </Box>

                <Box width={"16%"}>
                  <FormControlLabel
                    control={<Checkbox name="need_abandone_furniture" />}
                    value={formData.need_abandone_furniture}
                    checked={formData.need_abandone_furniture}
                    onChange={(e, checked) => {
                      setFormData((prev) => {
                        return { ...prev, need_abandone_furniture: checked };
                      });
                    }}
                    label={
                      <Typography
                        fontSize={18}
                        color="#5A6070"
                        lineHeight={"24.87px"}
                      >
                        需要棄置舊傢俱
                      </Typography>
                    }
                    sx={{
                      m: 0,
                      p: "15px",
                      borderRadius: "5px",
                      border: "1px solid #BDBDBD",
                    }}
                  />
                </Box>

                <Box width={"40%"}>
                  <CustTextField
                    id="disposable_products"
                    name="disposable_products"
                    type="number"
                    label="棄置產品費(HK$)"
                    placeholder="500"
                    value={formData.disposable_products}
                    onChange={(e) => {
                      setFormData((prev) => {
                        return {
                          ...prev,
                          disposable_products: Number(e.target.value),
                        };
                      });
                    }}
                  />
                </Box>
              </Stack>
              <Box mt={5} mb={2.5}>
                <Divider />
              </Box>
            </Box>

            {/* ADD NEW ADDRESS OR SELECT AVAILABLE ADDRESS... */}
            <Box
              display="flex"
              gap={2.5}
              flexWrap={"wrap"}
              aria-labelledby="select-or-create-address"
            >
              {addressList && addressList.length > 0 ? (
                <RadioGroup
                  row
                  aria-labelledby="select-address-option-radio-group-button"
                  value={selectedAddress?.name ?? ""}
                  onChange={handleAddressSelection}
                  sx={{ gap: "20px" }}
                >
                  {addressList.map((address, index) => (
                    <StyledFormControlLabel
                      key={`member-address-${index}`}
                      value={address.name}
                      control={<Radio />}
                      label={`${address.region} ${address.district} ${
                        address.address_line1 ?? ""
                      } ${address.address_line1 ?? ""}`}
                      checked={
                        selectedAddress && selectedAddress.name === address.name
                          ? true
                          : false
                      }
                    />
                  ))}
                </RadioGroup>
              ) : null}

              <Button
                startIcon={<AddCircle />}
                variant={"contained"}
                sx={{
                  borderRadius: "5px",
                  background: "#E26F71",
                  color: "#fff",
                  padding: "15px 20px",
                  "&:hover": {
                    background: "#E26F71",
                  },
                }}
                disableElevation
                onClick={() => {
                  setAdressModalConfig({
                    isEditing: false,
                    isOpen: true,
                  });
                }}
              >
                增加新地址
              </Button>
            </Box>

            {/* SELECTED ADDRESS INFO...*/}
            <Grid
              container
              columnSpacing={2.5}
              aria-labelledby="selected-address-info-section"
            >
              <Grid item xs={10} md={11} pl="0px !important">
                <Stack spacing={2.5} flexGrow={1}>
                  <Grid
                    container
                    columnSpacing={5}
                    width="100%"
                    aria-labelledby="selected-consignee-info"
                  >
                    <Grid item xs={3} pl="0px !important">
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          收貨人姓名
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.recipient_name ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={3}>
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          收貨人電話
                        </Typography>
                        <Box fontSize={18}>{selectedAddress?.phone ?? "-"}</Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={6}>
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          收貨人其他電話
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.second_phone ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    columnSpacing={5}
                    rowSpacing={2.5}
                    width="100%"
                    aria-labelledby="selected-address-info"
                  >
                    <Grid item xs={6} pl="0px !important" pt="0px !important">
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          區域
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.region ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={6} pt="0px !important">
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          地區
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.district ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={6} pl="0px !important">
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          街道號碼及屋苑大廈
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.address_line1 ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={6}>
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          樓層及單位
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.address_line2 ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    columnSpacing={5}
                    width="100%"
                    aria-labelledby="selected-other-info"
                    rowSpacing={2.5}
                  >
                    <Grid item xs={3} pl="0px !important">
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          上樓費(HK$)
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.custom_stairs_fee ?? "0"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={3}>
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          推路費(HK$)
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.custom_cart_fee ?? "0"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={6}>
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          偏遠地區(HK$)
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.custom_remote_fee ?? "0"}
                        </Box>
                      </Stack>
                    </Grid>

                    <Grid item xs={6} pl="0px !important">
                      <Stack spacing={"8.83px"}>
                        <Typography fontWeight={700} fontSize={16}>
                          地址備註
                        </Typography>
                        <Box fontSize={18}>
                          {selectedAddress?.custom_address_remarks ?? "-"}
                        </Box>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>

              <Grid item xs={2} md={1}>
                <Button
                  variant="outlined"
                  color="inherit"
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: 80,
                    p: 0.5,
                    borderRadius: "5px",
                    border: "1px solid #E0E0E0",
                  }}
                  disabled={addressList.length < 1}
                  onClick={() => {
                    setAdressModalConfig({ isEditing: true, isOpen: true });
                  }}
                >
                  <EditIcon />
                </Button>
              </Grid>
            </Grid>

            {!selectedAddress ? (
              <FormHelperText
                error={true}
                sx={{ textAlign: "center", fontWeight: 700, fontSize: 16 }}
              >
                地址 必须选择
              </FormHelperText>
            ) : null}
          </Stack>
        </Box>

        <Button
          // type="submit"
          variant="contained"
          disabled={isUpdateAddressLoading}
          onClick={handleNext}
          disableElevation
          fullWidth
          sx={{ p: 2, fontWeight: 700, fontSize: 16 }}
        >
          {isUpdateAddressLoading ? "加载中" : "下一步"}
        </Button>
      </Stack>

      <CreateEditAddressModal
        editingAddressItem={
          addressModalConfig.isEditing && selectedAddress
            ? selectedAddress
            : undefined
        }
        modalConfig={addressModalConfig}
        handleModalSubmit={handleCreateAddressModalSubmit}
        handleModalClose={() =>
          setAdressModalConfig({ isEditing: false, isOpen: false })
        }
      />

      {/* MEMBER NOT SELECTED ERROR ALERT... */}
      <MemberNotSelectedAlert open={!selectedMember ? true : false} />
    </>
  );
};

export default ShippingStep;

interface FormDataStateType {
  need_abandone_furniture: boolean;
  disposable_products: number | null;
  delivery_date_choice: string;
}

// STYLE
export const StyledFormControlLabel = styled(
  FormControlLabel,
)<FormControlLabelProps>(({ theme, checked }) => ({
  padding: "15px",
  borderRadius: "5px",
  border: `1px solid ${checked ? "transparent" : "#E0E0E0"} `,
  margin: 0,
  background: checked ? "#45A492" : "#fff",
  color: checked ? "#fff" : "#828282",
  "& .Mui-checked": {
    color: "#fff !important",
  },
}));
