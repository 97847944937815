import { Checkbox, CheckboxProps, FormControlLabel } from "@mui/material";
import React from "react";

const HowToKnowCheckBox = (props: CustCheckBoxProps) => {
  const { optionArray, label, name, sx, value, ...rest } = props;
  const [isChecked, setIsChecked] = React.useState(false);

  React.useEffect(() => {
    if (optionArray.includes(String(name))) setIsChecked(true);
    else setIsChecked(false);
  }, [optionArray, name]);

  return (
    <FormControlLabel
      className="form-input-checkbox"
      control={
        <Checkbox
          name={name}
          value={value}
          checked={isChecked}
          {...rest}
          sx={{
            ...sx,
            color: isChecked ? "#FFF !important" : "#828282",
            "&.Mui-checked": {
              color: isChecked ? "#FFF" : "#828282",
            },
          }}
        />
      }
      label={label}
      sx={{
        bgcolor: isChecked ? "#45A492" : "#fff",
        color: isChecked ? "#FFF !important" : "#828282",
      }}
    />
  );
};

export default HowToKnowCheckBox;

interface CustCheckBoxProps extends CheckboxProps {
  label: string;
  optionArray: Array<string>;
}
