import {
  Box,
  Button,
  Dialog,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useGetSalePersonListMutation } from "src/redux/api/saleAndInvoiceApi";
import { CustTextField, PasswordTextField } from "../form-components/FormInput";
import { CustSelect } from "../form-components/FormSelect";
import DefaultLoaderContainer from "../loader";

// ============================ || SELECT RETURN PERSON DIALOG || ====================================//
export interface SelectReturnPersonDialogProps {
  open: boolean;
  handleModalClose: (isSuccess: boolean) => void;
  onSelectReturnPerson: (salePerson: string) => void;
}
export const SelectReturnPersonDialog = (
  props: SelectReturnPersonDialogProps,
) => {
  const { handleModalClose, open, onSelectReturnPerson } = props;

  // LOCAL STATES...
  const [selectedSalePerson, setSelectedSalePerson] = useState("");

  // REDUX THINGS..
  const [getSalePersonList, { data, isLoading }] =
    useGetSalePersonListMutation();

  // INIT STATE CHECKS...
  useEffect(() => {
    getSalePersonList({});
  }, []);

  return (
    <Dialog
      aria-labelledby="select-return-person-dialog"
      maxWidth="sm"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
          請選擇退單員
        </Typography>

        <Box sx={{ my: "30px" }}>
          <DefaultLoaderContainer isLoading={isLoading} minHeight={"10vh"}>
            <CustSelect
              label={"退單員"}
              value={selectedSalePerson}
              onChange={(e) => {
                const val = e.target.value as string;
                setSelectedSalePerson(val ?? "");
              }}
            >
              {data?.data.map((item, index) => {
                return (
                  <MenuItem
                    key={`sale-person-option-${index}`}
                    value={item.sales_person_name}
                  >
                    {item.sales_person_name}
                  </MenuItem>
                );
              })}
            </CustSelect>
          </DefaultLoaderContainer>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={2.5}
          color="grey.500"
        >
          <Button
            variant="outlined"
            onClick={() => handleModalClose(false)}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="inherit"
          >
            取消
          </Button>

          <Button
            variant="contained"
            onClick={() => {
              if (selectedSalePerson.length > 0) {
                onSelectReturnPerson(selectedSalePerson);
              }
            }}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

// ============================ || MANAGER CREDENTIALS FOR SALE RETURN DIALOG || ====================================//
export interface ManagerCredentialsForReturnDialogProps {
  open: boolean;
  handleModalClose: () => void;
  onSubmit: (managerCred: { usr: string; pwd: string }) => void;
}
export const ManagerCredentialsForReturnDialog = (
  props: ManagerCredentialsForReturnDialogProps,
) => {
  const { handleModalClose, open, onSubmit } = props;

  // LOCAL STATES...
  const [managerCredentials, setManagerCredentials] = useState({
    pwd: "",
    usr: "",
  });

  // handle sale return
  const handleCredSubmit = async () => {
    if (managerCredentials.usr && managerCredentials.pwd)
      onSubmit({
        usr: managerCredentials.usr,
        pwd: managerCredentials.pwd,
      });
  };

  return (
    <Dialog
      aria-labelledby="select-return-manager-credentials-dialog"
      maxWidth="sm"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
          請輸入帳戶密碼來進行退單動作
        </Typography>

        <Box sx={{ my: "30px" }}>
          {/* <DefaultLoaderContainer isLoading={isLoading} minHeight={"10vh"}> */}
          <Stack spacing={"30px"}>
            <CustTextField
              label="帳號名稱"
              placeholder="Manager Name"
              onChange={(e) => {
                setManagerCredentials((oldState) => {
                  return { ...oldState, usr: e.target.value };
                });
              }}
            />

            <PasswordTextField
              label="密碼"
              placeholder="Password"
              onChange={(e) => {
                setManagerCredentials((oldState) => {
                  return { ...oldState, pwd: e.target.value };
                });
              }}
            />
          </Stack>
          {/* </DefaultLoaderContainer> */}
        </Box>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={2.5}
          color="grey.500"
        >
          <Button
            variant="outlined"
            onClick={handleModalClose}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="inherit"
            // disabled={isLoading}
          >
            取消
          </Button>

          <Button
            variant="contained"
            onClick={handleCredSubmit}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            // disabled={isLoading}
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

// ============================ || SELECT RETURN PERSON DIALOG || ====================================//
export interface SaleReturnStatusDialogProps {
  open: boolean;
  is_success: boolean;
  invoice_id: string;
  message: any;
  handleModalClose: (isSuccess: boolean) => void;
}
export const SaleReturnStatusDialog = (props: SaleReturnStatusDialogProps) => {
  const { handleModalClose, open, invoice_id, is_success, message } = props;

  return (
    <Dialog
      aria-labelledby="select-return-status-dialog"
      maxWidth="sm"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
          {is_success
            ? `已成功為 帳單(${invoice_id}) 進行退單`
            : `處理退貨時發生錯誤 帳單 (${invoice_id})`}
        </Typography>

        <Box
          display={"flex"}
          justifyContent={"center"}
          columnGap={2.5}
          color="grey.500"
          mt={"30px"}
        >
          <Button
            variant="contained"
            onClick={() => handleModalClose(false)}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="primary"
          >
            完成
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
