import { Outlet } from "react-router-dom";
import DefaultThemeProvider from "./theme";
import Cookies from "js-cookie";
import { useEffect, useState } from "react";
import { COOKIES_KEYS } from "./constants/keys/cookie.keys";

function App() {
  const [cookieValue, setCookieValue] = useState("");

  useEffect(() => {
    const sid = Cookies.get(COOKIES_KEYS.SID);
    if (sid) setCookieValue(COOKIES_KEYS.SID);
  }, []);

  // console.log("SID Cookie", cookieValue);

  return (
    <DefaultThemeProvider>
      <Outlet />
    </DefaultThemeProvider>
  );
}

export default App;
