import { DialogActions, DialogContentText } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import memberRoutes from "src/constants/routes/member.routes";
import { IInvoiceProductItemDetail } from "src/interfaces/invoice.interfaces";
import {
  useAddUpdateInvoiceItemMutation,
  useDeleteInvoiceByIdMutation,
  useDeleteInvoiceItemMutation,
  useGetInvoiceByIdMutation,
} from "src/redux/api/saleAndInvoiceApi";
import {
  setHasInvoiceItem,
  setIsInvoiceDrawerOpen,
} from "src/redux/features/invoiceSlice";
import {
  IMemberState,
  setInvoiceDetails,
  setSelectedInvoiceId,
  setSelectedVariant,
} from "src/redux/features/memberSlice";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from "../../ui-library";
import CustomDrawer from "../CustomDrawer";
import DrawerInvoiceItem from "./DrawerInvoiceItem";

const InvoiceDrawer = (props: InvoiceDrawerProps) => {
  const navigate = useNavigate();

  // PROPS DESTRUCTURE...
  const {
    isDrawerOpen,
    toggleDrawer,
    // setSelectedProductCode,
    handleEditInvoiceItem,
  } = props;

  // LOCAL STATES...
  const [isLoading, setIsLoading] = useState(true);
  const [isDeleteModalConfirmOpen, setIsDeleteModalConfirmOpen] =
    useState(false);

  // REDUX THINGS..
  const dispatch = useDispatch();
  const { selectedInvoiceId, needRefeatchInvoice, invoiceDetail } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );

  const [
    getInvoiceById,
    {
      isSuccess: getInvoiceSuccess,
      data: invoiceData,
      isError: isInvoiceError,
    },
  ] = useGetInvoiceByIdMutation();
  const [deleteInvoiceItem, { isSuccess: isDeleteSuccess }] =
    useDeleteInvoiceItemMutation();
  const [addUpdateInvoiceItem, { isSuccess: isUpdateSuccess }] =
    useAddUpdateInvoiceItemMutation();
  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();

  useEffect(() => {
    setIsLoading(true);
    if (selectedInvoiceId && isDrawerOpen) {
      getInvoiceById({ invoice_name: selectedInvoiceId });
    }
  }, [
    isDeleteSuccess,
    isUpdateSuccess,
    selectedInvoiceId,
    needRefeatchInvoice,
    isDrawerOpen,
  ]);

  useEffect(() => {
    if (invoiceData) {
      dispatch(setInvoiceDetails(invoiceData.data));
      if (invoiceData?.data?.items?.length > 0) {
        dispatch(setHasInvoiceItem(true));
      } else {
        dispatch(setHasInvoiceItem(false));
      }
    }
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1500);

    return () => clearTimeout(timer);
  }, [getInvoiceSuccess, isInvoiceError]);

  // handle if delete invoice is success...
  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      dispatch(setHasInvoiceItem(false));
      dispatch(setSelectedInvoiceId(""));
      toggleDrawer();
      setIsDeleteModalConfirmOpen(false);
    }
  }, [isDeleteInvoiceSuccess]);

  const deleteInvoice = () => {
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  const handleDeleteInvoiceItem = (itemId: string) => {
    if (invoiceDetail && invoiceDetail.items.length > 1) {
      const data = {
        invoice_name: selectedInvoiceId,
        items: [
          {
            item_code: itemId,
          },
        ],
      };

      deleteInvoiceItem(data);
    } else {
      setIsDeleteModalConfirmOpen(true);
    }
  };

  const handleEditInvoiceItemInternal = (data: IInvoiceProductItemDetail) => {
    const payload = {
      item_name: data.name,
      name: data.item_code,
      parent_item_code: data.parent_item_name,
    };

    dispatch(setSelectedVariant(payload));
    handleEditInvoiceItem(payload);
    // setSelectedProductCode(
    //   data.attributes.length ? String(data.parent_item_name) : data.item_code,
    // );
    toggleDrawer();
  };

  const handleUpdateItemQuantity = (
    item: IInvoiceProductItemDetail,
    isIncrease: boolean,
  ) => {
    const remainingQty = isIncrease ? item.qty + 1 : item.qty - 1;
    // console.log("remaining qty: ", remainingQty);
    // console.log("invoiceDetail.items.length: ", invoiceDetail?.items.length);

    if (invoiceDetail && invoiceDetail.items.length >= 1 && remainingQty >= 1) {
      if (remainingQty >= 1) {
        const payload = {
          invoice_name: selectedInvoiceId,
          items: [
            {
              item_code: item.item_code,
              item_qty: remainingQty,
            },
          ],
        };
        addUpdateInvoiceItem(payload);
      } else {
        handleDeleteInvoiceItem(item.item_code);
      }
    } else {
      // IF USER DECREASE ITEM QTY FROM 1 TO 0 OR HE WANT TO DELETE LAST ITEM OF INVOICE...
      setIsDeleteModalConfirmOpen(true);
    }
  };

  return (
    <>
      <CustomDrawer
        anchor="right"
        open={isDrawerOpen}
        onOpen={toggleDrawer}
        onClose={toggleDrawer}
        isInvoiceDrawer={true}
        sx={{
          "& .drawer_root_container": {
            paddingBottom: "0 !important",
          },
        }}
      >
        {isLoading ? (
          <Box
            height={"100%"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <CircularProgress color="inherit" />
          </Box>
        ) : (
          <Stack
            spacing={"10px"}
            height="100%"
            justifyContent={"space-between"}
            aria-label="this is test "
          >
            <Stack
              spacing={"20px"}
              // sx={{ overflowY: "auto" }}
            >
              {invoiceDetail && invoiceDetail.items.length ? (
                invoiceDetail.items.map((invoice, index) => (
                  <DrawerInvoiceItem
                    key={`invoice-item-${index}`}
                    product={invoice}
                    handleDeleteItem={handleDeleteInvoiceItem}
                    handleEditItem={(e) => handleEditInvoiceItemInternal(e)}
                    handleUpdateQuantity={handleUpdateItemQuantity}
                  />
                ))
              ) : (
                <Box>
                  <Typography color="#828282" fontSize={20} fontWeight={700}>
                    沒有商品
                  </Typography>
                </Box>
              )}
            </Stack>

            <Box mb={"30px !important"} aria-label="submit-button-section">
              <Box className="cart_drawer-footer-detail">
                <Typography className="subtitle">總計</Typography>
                <Typography className="price">
                  HK${invoiceDetail?.rounded_total ?? 0}
                </Typography>
              </Box>

              <Button
                variant="contained"
                fullWidth
                onClick={() => {
                  dispatch(setIsInvoiceDrawerOpen(false));
                  navigate(memberRoutes.productSelectionStep.couponInfo);
                }}
              >
                下一步
              </Button>
            </Box>
          </Stack>
        )}
      </CustomDrawer>

      {/* INVOICE DELETE ALERT MODAL...  */}
      <Dialog
        open={isDeleteModalConfirmOpen}
        aria-labelledby="invoice-delete-alert"
      >
        <DialogTitle id="invoice-delete-alert-title">
          刪除全部銷售項目
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="invoice-delete-alert-description">
            刪除全部銷售項目將導致整個銷售單被刪除，且無法恢復。確認刪除？
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={() => deleteInvoice()}
            variant="outlined"
            sx={{ textTransform: "unset" }}
          >
            好的，刪除
          </Button>

          <Button
            variant="outlined"
            sx={{ textTransform: "unset" }}
            onClick={() => setIsDeleteModalConfirmOpen(false)}
            autoFocus
          >
            取消
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceDrawer;

// TYPE DECLERATION...
export interface InvoiceDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  // setSelectedProductCode: (value: string) => void;
  handleEditInvoiceItem: (item: HandleEditInvoiceItemParams) => void;
}

interface HandleEditInvoiceItemParams {
  item_name: string;
  name: string;
  parent_item_code: string | null;
}
