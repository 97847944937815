import { Box, Button, Dialog, MenuItem, Typography } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { CustTextField } from "src/components/form-components/FormInput";
import { CustSelect } from "src/components/form-components/FormSelect";
import DefaultLoaderContainer from "src/components/loader";
import { useGetBranchListMutation } from "src/redux/api/commonApi";

// ============================ || SELECT BRANCH NAME DIALOG || ====================================//
export interface SelectBranchNameDialogProps {
  open: boolean;
  handleModalClose: () => void;
  onSubmit: (salePerson: string) => void;
}
export const SelectBranchNameDialog = (props: SelectBranchNameDialogProps) => {
  const { handleModalClose, open, onSubmit } = props;

  // LOCAL STATES...
  const [selectedBranchName, setSelectedBranchName] = useState("");
  const [newOrderNumber, setNewOrderNumber] = useState("");

  // REDUX THINGS..
  const [getBranchList, { data, isLoading }] = useGetBranchListMutation();

  // INIT STATE CHECKS...
  useEffect(() => {
    getBranchList({});
  }, []);

  const handleSubmit = () => {
    if (selectedBranchName && newOrderNumber) {
      onSubmit(`${selectedBranchName}-${newOrderNumber}`);
    }
  };

  return (
    <Dialog
      aria-labelledby="select-return-person-dialog"
      maxWidth="md"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
          請輸入新單號碼
        </Typography>

        <Box sx={{ my: "30px" }}>
          <DefaultLoaderContainer isLoading={isLoading} minHeight={"10vh"}>
            <Grid2 width={"100%"} container rowSpacing={2} columnSpacing={3}>
              <Grid2 xs={12} sm={6} md={4}>
                <CustSelect
                  label={"分店"}
                  value={selectedBranchName}
                  onChange={(e) => {
                    const val = e.target.value as string;
                    setSelectedBranchName(val ?? "");
                  }}
                  fullWidth
                >
                  {data?.data.map((item, index) => {
                    return (
                      <MenuItem
                        key={`branch-options-${index}`}
                        value={item.name}
                        sx={{ textTransform: "capitalize" }}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </CustSelect>
              </Grid2>

              <Grid2 xs={12} sm={6} md={8}>
                <CustTextField
                  name="new_order_number"
                  label={"新單號碼"}
                  value={newOrderNumber}
                  onChange={(e) => {
                    const val = e.target.value as string;
                    setNewOrderNumber(val ?? "");
                  }}
                  fullWidth
                />
              </Grid2>
            </Grid2>
          </DefaultLoaderContainer>
        </Box>

        <Box
          display={"flex"}
          justifyContent={"flex-end"}
          columnGap={2.5}
          color="grey.500"
        >
          <Button
            variant="outlined"
            onClick={() => handleModalClose()}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="inherit"
          >
            取消
          </Button>

          <Button
            variant="contained"
            onClick={handleSubmit}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};
