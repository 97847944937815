import React from "react";
import { useNavigate } from "react-router-dom";
import memberRoutes from "src/constants/routes/member.routes";
import {
  AccountBox,
  Box,
  Button,
  ChevronLeft,
  Divider,
  Grid,
  Stack,
  SwipeableDrawer,
  Typography,
} from "../../ui-library";
import DefaultLoaderContainer from "../loader";
import { IMember } from "src/interfaces/member";
import { buildFullPhoneNumber } from "src/utils/common";

const MemberDetailDrawer = (props: MemberDetailDrawerProps) => {
  const { isDrawerOpen, memberData, toggleDrawer } = props;
  const navigate = useNavigate();

  // LOCAL STATES...
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  React.useEffect(() => {
    if (memberData) {
      setIsLoading(false);
    }
  }, [memberData]);

  return (
    <SwipeableDrawer
      anchor="right"
      open={isDrawerOpen}
      onOpen={toggleDrawer}
      onClose={toggleDrawer}
    >
      <Stack
        spacing={"10px"}
        width={450}
        px={"20px"}
        py="30px"
        role="presentation"
        height="100%"
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Button
            variant="outlined"
            color="info"
            sx={{ width: "max-content", px: "25px", py: "14px" }}
            onClick={toggleDrawer}
            startIcon={<ChevronLeft />}
          >
            返回
          </Button>

          <Button
            variant="contained"
            sx={{ width: "max-content", px: "25px", py: "14px" }}
            onClick={() => {
              toggleDrawer();
              navigate(memberRoutes.editMember(memberData?.name ?? ""));
            }}
          >
            编辑
          </Button>
        </Box>

        <DefaultLoaderContainer
          height="100%"
          isLoading={isLoading}
          loaderBoxProps={{ height: "100%" }}
        >
          <Stack spacing={2.5}>
            <Box>
              <Typography
                py={"11.89px"}
                variant="h3"
                fontSize={18}
                fontWeight={700}
                textAlign="center"
              >
                會員 {memberData?.customer_name} ({memberData?.name})
              </Typography>

              <Divider />
            </Box>

            <Button
              fullWidth
              variant="contained"
              sx={{ py: "12px !important" }}
              startIcon={<AccountBox />}
              onClick={() => {
                navigate(memberRoutes.productSelectionStep.selectSupplier);
              }}
            >
              為此客人用戶選購產品
            </Button>

            <Grid container rowGap={2.5}>
              <Grid item xs={6}>
                <Stack spacing={"8.3px"}>
                  <Typography fontWeight={700}>稱謂</Typography>
                  <Typography fontSize={18}>
                    {memberData?.salutation || "-"}
                  </Typography>
                </Stack>
              </Grid>

              <Grid item xs={6}>
                <Typography fontWeight={700}>生日月份</Typography>
                <Typography fontSize={18}>
                  {memberData?.custom_birth_month || "-"}
                </Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography fontWeight={700}>名字</Typography>
                <Typography fontSize={18}>
                  {memberData?.customer_name || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography fontWeight={700}>手機號碼</Typography>
                <Typography fontSize={18}>
                  {buildFullPhoneNumber(
                    memberData?.custom_unique_phone ?? "",
                    memberData?.custom_country_calling_code,
                  )}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography fontWeight={700}>電郵地址</Typography>
                <Typography fontSize={18}>
                  {memberData?.custom_email || "-"}
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography fontWeight={700}>如何認識我們</Typography>
                <Typography fontSize={18}>
                  {memberData?.custom_how_to_know_us &&
                  memberData.custom_how_to_know_us.length
                    ? memberData.custom_how_to_know_us
                        .map((discover: any) => discover.how_to_know_us)
                        .join("、")
                    : "-"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </DefaultLoaderContainer>
      </Stack>
    </SwipeableDrawer>
  );
};

export default MemberDetailDrawer;

// TYPE DECLERATION...
export interface MemberDetailDrawerProps {
  isDrawerOpen: boolean;
  toggleDrawer: () => void;
  memberData?: IMember;
}
