import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "src/config/env.config";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { ILoginResponse } from "src/interfaces/auth/login";
import { handleLocalStorage } from "src/utils/common";
import { setUserBranch } from "../features/userSlice";

// AUTH APIS...
export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_CONFIG.API_URL}/method/`,
  }),

  endpoints: (builder) => ({
    loginUser: builder.mutation<ILoginResponse, ILoginPayload>({
      query(data) {
        return {
          url: "login",
          method: "POST",
          body: data,
          credentials: "include",
        };
      },

      // async onQueryStarted(args, { dispatch, queryFulfilled }) {
      //   try {
      //     const res = await queryFulfilled;
      //     if (res && res.data) {
      //       await dispatch(
      //         userApi.endpoints.getApiKey.initiate({ user: args.usr }),
      //       );
      //     }
      //   } catch (error) {
      //     console.error(error);
      //   }
      // },
    }),

    logoutUser: builder.mutation({
      query() {
        return {
          url: "logout",
          credentials: "include",
        };
      },

      async onQueryStarted(args, { dispatch }) {
        try {
          dispatch(setUserBranch({ branchName: "" }));
          handleLocalStorage.removeValue(USER_RELATED_KEYS.BRANCH_NAME);
          handleLocalStorage.removeValue(USER_RELATED_KEYS.AUTH_KEY);
        } catch (error) {
          console.error(error);
        }
      },
    }),
  }),
});

export const { useLoginUserMutation, useLogoutUserMutation } = authApi;

export interface ILoginPayload {
  usr: string;
  pwd: string;
}
