import AddCircleIcon from "@mui/icons-material/AddCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CustTextField } from "src/components/form-components/FormInput";
import PaymentSelectionModal from "src/components/invoice/PaymentSelectionModal";
import ProductSelectionStepBreadcrumb from "src/components/member-product-selection-steps/ProductSelectStepBreadcrumb";
import { INVOICE_CREATION_STEPS_KEYS } from "src/constants/keys/local-storage.keys";
import memberRoutes from "src/constants/routes/member.routes";
import {
  IInvoicePaymentDetail,
  IPaymentMode,
} from "src/interfaces/payment-method.interfaces";

import { IMemberState } from "src/redux/features/memberSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";
import { StyledAddPaymentButton } from "src/styles/StyledButtons";
import { handleLocalStorage } from "src/utils/common";
import {
  Box,
  Button,
  ChevronLeft,
  Divider,
  Stack,
  Typography,
} from "src/ui-library";
import {
  useDeleteInvoiceByIdMutation,
  useGetPaymentMethodsMutation,
} from "src/redux/api/saleAndInvoiceApi";

const PaymentInfoStep = () => {
  const navigate = useNavigate();

  // LOCAL STATES...
  const [openAddPaymentModal, setOpenAddPaymentModal] = useState(false);
  const [selectedAddFundOption, setSelecteAddFundOption] = useState<{
    [key: string]: boolean;
  }>({});
  const [remarksInput, setRemarksInput] = useState("");
  const [selectedPaymentObj, setSelectedPaymentObj] = useState<IPaymentMode>({
    mode_of_payment: "",
    type: "",
    amount: 0,
  });

  const [selectedPaymentObjList, setSelectedPaymentObjList] = useState<
    Array<IPaymentMode>
  >([]);

  // REDUX THINGS...
  const { selectedInvoiceId, invoiceDetail } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );
  const [getPaymentMethods, { data: paymentMethods }] =
    useGetPaymentMethodsMutation();
  const [deleteInvoiceById, { isSuccess: isDeleteInvoiceSuccess }] =
    useDeleteInvoiceByIdMutation();

  const totalOutstandingAmt =
    (invoiceDetail && invoiceDetail.rounded_total) ?? 0;

  useEffect(() => {
    getPaymentMethods({});
  }, []);

  useEffect(() => {
    const savedPaymentData: any | null = handleLocalStorage.getValue(
      INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO,
    );

    if (savedPaymentData) {
      const formattedData = JSON.parse(savedPaymentData);
      setRemarksInput(formattedData.remarks);
      setSelectedPaymentObjList(formattedData.mode_of_payments);
    }
  }, []);

  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      navigate("/");
    }
  }, [isDeleteInvoiceSuccess]);

  const handleAddPaymentModalToggle = () => {
    setOpenAddPaymentModal(!openAddPaymentModal);
  };

  // HANDLE PAYMENT METHOD SELECTION...
  const handlePaymentMethodSelection = (
    e: React.ChangeEvent<HTMLInputElement>,
    mode_of_payment: string,
  ) => {
    const obj = paymentMethods?.data.filter(
      (v) => v.mode_of_payment === mode_of_payment,
    );

    if (obj && obj.length) {
      const ob = obj[0];
      setSelectedPaymentObj((prevState) => {
        return {
          ...prevState,
          mode_of_payment: ob.mode_of_payment,
          type: ob.type,
        };
      });
    }
  };

  // HANDLE ADD AMOUNT PERCENTAGE...
  const handleAddAmountPercentageOption = (
    e: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    const { name } = e.target;
    if (checked === true) {
      const obj = addFundPercentOptions.filter((o) => o.label === name);

      if (obj.length) {
        const percent = obj[0].value;
        setSelectedPaymentObj((prevState) => {
          return {
            ...prevState,
            amount: Math.floor(totalOutstandingAmt * (percent / 100)),
          };
        });
      }
    }

    setSelecteAddFundOption((prev) => {
      return { [name]: checked };
    });
  };

  // HANDLE PAYMENT METHOD SELECTION CONFIRM...
  const handlePMSelection = (isConfirmed: boolean = false) => {
    if (
      !selectedPaymentObj.amount ||
      !selectedPaymentObj.mode_of_payment ||
      !selectedPaymentObj.type
    ) {
      return;
    }

    if (isConfirmed === true) {
      const oldState = selectedPaymentObjList;
      const index = selectedPaymentObjList.findIndex(
        (v) => v.mode_of_payment === selectedPaymentObj.mode_of_payment,
      );

      if (index >= 0) {
        oldState.splice(index, 1, selectedPaymentObj);
        setSelectedPaymentObjList(oldState);
      } else {
        setSelectedPaymentObjList((prevState) => {
          return [...prevState, selectedPaymentObj];
        });
      }

      // RESETTING PREV STATES...
      setSelectedPaymentObj({ amount: 0, mode_of_payment: "", type: "" });
      setSelecteAddFundOption({ dot: false });
    } else {
      setSelectedPaymentObj({ amount: 0, mode_of_payment: "", type: "" });
    }

    setOpenAddPaymentModal(false);
  };

  // GET BALANCE AMOUNT AFTER APPLYING PAYMENT METHOD AND PAYMENT AMOUNT...
  const getBalanceAmount = () => {
    if (selectedPaymentObjList.length) {
      const t = selectedPaymentObjList.reduce(
        (accumulator, currentValue) => accumulator + currentValue.amount,
        0,
      );

      return `$ ${totalOutstandingAmt - t}`;
    } else return `$ ${totalOutstandingAmt}`;
  };

  // HANDLE REMOVE PAYMENT METHOD...
  const handleRemovePaymentMethod = (item: IPaymentMode) => {
    const oldState = selectedPaymentObjList;
    const index = selectedPaymentObjList.findIndex(
      (v) => v.mode_of_payment === item.mode_of_payment,
    );

    if (index >= 0) {
      oldState.splice(index, 1);
      setSelectedPaymentObjList((prevState) => {
        return [...oldState];
      });
    }
  };

  const handleCancelOrder = () => {
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  const handleNexStep = () => {
    const paymentPayload = {
      name: selectedInvoiceId,
      date: new Date(),
      // mode_of_payments: selectedPaymentObjList,
      mode_of_payments: selectedPaymentObjList.map((item) => {
        return {
          ...item,
          doctype: "Sales Invoice Payment",
        };
      }),

      remarks: remarksInput,
      due_amt: getBalanceAmount(),
    } as IInvoicePaymentDetail;

    handleLocalStorage.setValue(
      INVOICE_CREATION_STEPS_KEYS.PAYMENT_INFO,
      JSON.stringify(paymentPayload),
    );
    // console.log("paymentPayload: ", paymentPayload);
    navigate(memberRoutes.productSelectionStep.checkInfo);
  };

  return (
    <>
      <Stack spacing={2.5} sx={{ p: 3.75 }}>
        {/* HEADER SECTION... */}
        <Box
          aria-label="header-section"
          display="flex"
          justifyContent="space-between"
          alignItems="center"
        >
          <StyledHeaderBackButton
            variant="outlined"
            startIcon={<ChevronLeft />}
            onClick={() => navigate(-1)}
          >
            返回
          </StyledHeaderBackButton>

          <Box>
            <ProductSelectionStepBreadcrumb active={5} />
          </Box>

          <Button
            variant="contained"
            startIcon={
              <Box display="flex" justifyContent="center" alignItems="center">
                <img
                  src="/icons/cross.svg"
                  alt="cross-file"
                  width={20}
                  style={{ objectFit: "contain" }}
                />
              </Box>
            }
            sx={{ px: 3.125, py: 1.25 }}
            onClick={handleCancelOrder}
          >
            取消下單
          </Button>
        </Box>

        {/* PAYMENT SELECTION CONTAINER... */}
        <Box aria-label="payment-selection-container">
          <Stack
            direction="row"
            spacing={1}
            py="20.5px"
            px="30px"
            borderRadius="5px 5px 0 0"
            bgcolor="#5A6070"
            width="fit-content"
            color="#fff"
            alignItems="center"
          >
            <Box display="flex" justifyContent="center" alignItems="center">
              <img
                src="/icons/payment.svg"
                alt="sale-girl"
                width={24}
                style={{ objectFit: "contain" }}
              />
            </Box>
            <Typography fontSize={18} fontWeight={700}>
              付款信息
            </Typography>
          </Stack>

          <Stack
            spacing={2.5}
            p={2.5}
            borderRadius="0 5px 5px 5px"
            border="1px solid #E0E0E0"
            bgcolor="#fff"
          >
            {/* PAYMENT OPTIONS SECTION... */}
            <Box aria-labelledby="payment-method-options-container">
              <StyledAddPaymentButton
                variant={"contained"}
                fullWidth
                startIcon={<AddCircleIcon />}
                onClick={handleAddPaymentModalToggle}
              >
                新增付款方式
              </StyledAddPaymentButton>
            </Box>

            {/* TOTAL PAYMENT SECTION... */}
            <Box
              aria-labelledby="total-payment-container"
              sx={{
                p: 2.5,
                pt: "30px",
                borderRadius: "5px",
                border: "1.13px solid #E0E0E0",
              }}
            >
              <Box>
                <Box
                  display={selectedPaymentObjList.length ? "none" : "block"}
                  mb={"15px"}
                >
                  <Divider />
                </Box>

                <Box
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography fontSize={20}> 全單總計</Typography>
                  <Typography fontSize={30} fontWeight={700} mr={2}>
                    $ {totalOutstandingAmt}
                  </Typography>
                </Box>
              </Box>

              {selectedPaymentObjList.length > 0 ? (
                <Stack mt="20px" spacing={2.5}>
                  {selectedPaymentObjList.map((obj, ind) => {
                    return (
                      <Box
                        key={ind}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                      >
                        <Stack spacing="10px" direction="row">
                          <Box
                            sx={{
                              borderRadius: "5px",
                              background: "#45A492",
                              p: "12px 29.72px",
                              color: "#fff",
                              fontSize: 18,
                            }}
                          >
                            {obj.mode_of_payment}
                          </Box>

                          <Button
                            variant="contained"
                            sx={{
                              bgcolor: "#333",
                              color: "#fff",
                              "&:hover": {
                                bgcolor: "#333",
                              },
                            }}
                            onClick={() => {
                              handleRemovePaymentMethod(obj);
                            }}
                          >
                            <DeleteIcon />
                          </Button>
                        </Stack>

                        <Typography fontSize={25} fontWeight={700} pr={"18px"}>
                          -$ {obj.amount}
                        </Typography>
                      </Box>
                    );
                  })}

                  <Box>
                    <Divider />

                    <Box
                      display="flex"
                      justifyContent="space-between"
                      alignItems="center"
                      mt="15px"
                    >
                      <Typography fontSize={20}>餘額</Typography>

                      <Typography fontSize={25} fontWeight={700} pr={"18px"}>
                        {getBalanceAmount()}
                      </Typography>
                    </Box>
                  </Box>
                </Stack>
              ) : null}
            </Box>

            {/* REMARKS SECTION... */}
            <Box
              aria-labelledby="remarks-section"
              sx={{
                p: 2.5,
                borderRadius: "5px",
                border: "1.13px solid #E0E0E0",
              }}
            >
              <CustTextField
                name="remarks"
                value={remarksInput}
                onChange={(e) => setRemarksInput(e.target.value)}
                label={<Box fontWeight={700}>備註</Box>}
                placeholder="八達通付訂金"
                sx={{
                  "& .MuiOutlinedInput-input": {
                    padding: "10px 120px 10px 20px",
                    height: 25,
                  },
                }}
                fullWidth
              />
            </Box>
          </Stack>
        </Box>

        {/* NEXT BUTTON... */}
        <Button variant="contained" fullWidth onClick={handleNexStep}>
          下一步
        </Button>
      </Stack>

      {/* MODALS... */}
      <PaymentSelectionModal
        isOpen={openAddPaymentModal}
        handleClose={handleAddPaymentModalToggle}
        addFundPercentOptions={addFundPercentOptions}
        selectedAddFundOption={selectedAddFundOption}
        paymentMethods={paymentMethods}
        selectedPaymentObj={selectedPaymentObj}
        handlePaymentMethodSelection={handlePaymentMethodSelection}
        handlePMSelection={handlePMSelection}
        setSelectedPaymentObj={setSelectedPaymentObj}
        setSelecteAddFundOption={setSelecteAddFundOption}
        handleAddAmountPercentageOption={handleAddAmountPercentageOption}
      />
    </>
  );
};

export default PaymentInfoStep;

// CONSTANT DATA...
const addFundPercentOptions = [
  { label: "付3成", value: 30 },
  { label: "付五成", value: 50 },
  { label: "付七成", value: 70 },
  { label: "付全數", value: 100 },
];
