import { useController } from "react-hook-form";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectProps,
  Stack,
} from "../../ui-library";
import { FormHelperText, StackOwnProps } from "@mui/material";
import { FC } from "react";

export const CustSelect: FC<CustSelectProps> = (props) => {
  const { wrapperStackProps, label, error, helperText, ...rest } = props;

  return (
    <Stack
      spacing={wrapperStackProps?.spacing ?? "8.83px"}
      {...wrapperStackProps}
    >
      {label ? <InputLabel className="select_label">{label}</InputLabel> : null}

      <Select {...rest} error={error} />

      {error && helperText ? (
        <FormHelperText sx={{ marginTop: 0, color: "red" }}>
          {helperText}
        </FormHelperText>
      ) : null}
    </Stack>
  );
};

interface Option {
  label: string;
  value: string;
}

const FormSelect = (props: any) => {
  const { label, options } = props;
  const { field, fieldState } = useController(props);

  return (
    <Box className="form-input-group">
      <InputLabel>{label}</InputLabel>
      <FormControl fullWidth>
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          {...field}
        >
          {options?.map((option: Option, index: number) => {
            return (
              <MenuItem key={`salutation-option-${index}`} value={option.value}>
                {option.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </Box>
  );
};

export default FormSelect;

interface CustSelectCustProps {
  wrapperStackProps?: StackOwnProps;
  helperText?: string;
}
type CustSelectProps = SelectProps & CustSelectCustProps;
