import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useGetBranchNameMutation } from "src/redux/api/userApi";
import logo from "../../assets/logo.png";
import AlertDialog from "../../components/AlertDialog";
import FormInput from "../../components/form-components/FormInput";
import { useLoginUserMutation } from "../../redux/api/authApi";
import { loginValidationSchema } from "../../services/validations";
import { Box, Button, Typography } from "../../ui-library/index";
import Cookies from "js-cookie";
import { COOKIES_KEYS } from "src/constants/keys/cookie.keys";
import { handleLocalStorage } from "src/utils/common";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";

const LoginPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [cookieValue, setCookieValue] = useState("");

  useEffect(() => {
    const sid = Cookies.get(COOKIES_KEYS.SID);
    if (sid) setCookieValue(COOKIES_KEYS.SID);
  }, []);

  console.log("SID Cookie", cookieValue);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      usr: "",
      pwd: "",
    },
    resolver: yupResolver(loginValidationSchema),
  });

  const [loginUser, { isError }] = useLoginUserMutation();
  const [getBranchName] = useGetBranchNameMutation();

  const handlePasswordVisibility = () => {
    setIsPasswordVisible((prevState) => !prevState);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const onSubmit = async (data: any) => {
    const res = await loginUser(data);
    const branchRes = await getBranchName({});

    if (res && (res as any)?.data && branchRes && (branchRes as any)?.data) {
      window.location.href = "/invoice";
      handleLocalStorage.setValue(USER_RELATED_KEYS.USER_LOGIN_STATUS, "true");
    } else {
      setIsModalOpen(true);
      handleLocalStorage.removeValue(USER_RELATED_KEYS.USER_LOGIN_STATUS);
    }
  };

  return (
    <Box className="login__wrapper">
      <Box className="login__header">
        <img src={logo} alt="Furniture Station Logo" height={180} width={214} />
        <Typography variant="h1">POS系統</Typography>
      </Box>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Box display="flex" flexDirection="column" gap="24px">
          <FormInput
            control={control}
            name="usr"
            label="分店帳戶"
            rules={{ required: true }}
            error={errors.usr}
          />
          <FormInput
            control={control}
            type={isPasswordVisible ? "text" : "password"}
            name="pwd"
            label="密碼"
            isPasswordVisible={isPasswordVisible}
            handlePasswordVisibility={handlePasswordVisibility}
            rules={{ required: true }}
            error={errors.pwd}
          />
        </Box>
        {isError && (
          <Typography
            sx={{
              color: "#DC2427",
              fontSize: "18px",
              fontWeight: 800,
              textAlign: "center",
            }}
          >
            分店帳戶及密碼組合不正確，請重新嘗試。
          </Typography>
        )}
        <Button type="submit" variant="contained">
          登入
        </Button>
      </form>
      <AlertDialog
        isModalOpen={isModalOpen}
        isAddSuccessModal={true}
        title="系統連接錯誤"
        content="請稍後再試"
        handleModalClose={handleModalClose}
      />
    </Box>
  );
};

export default LoginPage;
