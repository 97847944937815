import { buildQuery } from "src/utils/common";

const invoiceBaseRoute = "/invoice";

const invoiceRoutes = {
  base: invoiceBaseRoute,
  invoiceDetail: (invoiceId: string, query?: { [key: string]: any }) => {
    if (query) {
      return `${invoiceBaseRoute}/${invoiceId}/detail${buildQuery(query)}`;
    } else return `${invoiceBaseRoute}/${invoiceId}/detail`;
  },

  invoiceReturn: (invoiceId: string, query?: { [key: string]: any }) => {
    if (query) {
      return `${invoiceBaseRoute}/${invoiceId}/return-detail${buildQuery(
        query,
      )}`;
    } else return `${invoiceBaseRoute}/${invoiceId}/return-detail`;
  },
};

export default invoiceRoutes;
