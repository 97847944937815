import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "src/config/env.config";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { IAPIResponse } from "src/interfaces/api-response.interfaces";
import { IHowToKnowUsItem } from "src/interfaces/common.interfaces";
import { handleLocalStorage } from "src/utils/common";

// COMMON APIS...
export const commonApi = createApi({
  reducerPath: "memberAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_CONFIG.API_URL}/`,
    credentials: "include",
    prepareHeaders: async (headers) => {
      const AuthKey = handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY);
      if (AuthKey) {
        headers.set("Authorization", AuthKey);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    howToKnowUsOptions: builder.mutation<IAPIResponse<IHowToKnowUsItem[]>, any>(
      {
        query: (args) => {
          const params = {
            limit_page_length: 100,
          };

          return {
            url: "resource/How To Know Us",
            method: "GET",
            params,
          };
        },
      },
    ),

    getBranchList: builder.mutation<
      IAPIResponse<[{ name: string }]>,
      { [key: string]: any }
    >({
      query: (args) => {
        const params = {
          ...args,
          limit_page_length: 100,
        };

        return {
          url: "resource/Branch",
          method: "GET",
          params,
        };
      },
    }),
  }),
});

export const { useHowToKnowUsOptionsMutation, useGetBranchListMutation } =
  commonApi;

// TYPE DECLERATION...
