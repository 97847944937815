import { Button, ButtonProps } from "@mui/material";
import { styled } from "@mui/material/styles";

export const StyledAddPaymentButton = styled(Button)<ButtonProps>(
  ({ theme, variant }) => ({
    padding: "12px 29.72px",
    fontSize: "16px",
    fontWeight: 700,
    background: "#E26F71",
    color: "#fff",
    borderColor: "transparent",
    "&:hover": {
      background: "#E26F71",
      borderColor: "transparent",
    },
  }),
);
