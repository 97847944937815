import { DialogContentText } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import DefaultLoaderContainer from "src/components/loader";
import memberRoutes from "src/constants/routes/member.routes";
import { ISupplierProductListItem } from "src/interfaces/suppliers/product.interfaces";
import { useDeleteInvoiceByIdMutation } from "src/redux/api/saleAndInvoiceApi";
import {
  IGetProductListPayload,
  IGetProductListPayloadOptional,
  useGetProductCategoriesMutation,
  useGetProductListMutation,
} from "src/redux/api/suppliersAndProductsApi";
import {
  setHasInvoiceItem,
  setIsInvoiceDrawerOpen,
} from "src/redux/features/invoiceSlice";
import {
  IMemberState,
  setSelectedInvoiceId,
} from "src/redux/features/memberSlice";
import { StyledHeaderBackButton } from "src/styles/HeaderBackButton";
import ConfirmInvoiceDeleteDialog from "src/components/invoice/ConfirmInvoiceDeleteDialog";
import InvoiceDrawer from "src/components/invoice/InvoiceDrawer";
import ProductSelectionStepBreadcrumb from "src/components/member-product-selection-steps/ProductSelectStepBreadcrumb";
import ProductDetailModal from "src/components/product/ProductDetailModal1";
import ProductItem from "src/components/product/ProductItem";
import RootLayout from "src/layout/Layout";
import {
  Box,
  Button,
  ChevronLeft,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MuiTextField,
  Typography,
} from "src/ui-library";
import { buildValidObject } from "src/utils/common";

const SelectProductStep = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let [searchParams, setSearchParams] = useSearchParams();
  const paramSupplierId = searchParams.get("supplierId");

  // LOCAL STATES...
  const [isMemberDialogOpen, setIsMemberDialogOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState<string>();
  const [selectedCategory, setSelectedCategory] = useState<ISelectedCategory>({
    item_group_name: "全部",
    name: "",
  });
  const [isProductDetailModalOpen, setIsProductDetailModalOpen] =
    useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedProductCode, setSelectedProductCode] = useState("");
  const [productList, setProductList] = useState<
    Array<ISupplierProductListItem>
  >([]);
  const [pagination, setPagination] = useState<IProductPagination>({
    page: 1,
    pageSize: 10,
    hasMore: false,
  });
  const [isInvoiceEditing, setIsInvoiceEditing] = useState(false);

  // REDUX FUNCTIONS...
  const { selectedMember, selectedInvoiceId } = useSelector(
    (state: any) => state.memberState as IMemberState,
  );
  const { selectedSupplierId, hasInvoiceItem, isInvoiceDrawerOpen } =
    useSelector((state: any) => state.invoiceState);
  const [
    getProductCategories,
    { data: categoriesData, isLoading: isCategoryLoading },
  ] = useGetProductCategoriesMutation();

  const supplierId = paramSupplierId || selectedSupplierId;

  const [
    getProductList,
    {
      isLoading: isProductListLoading,
      data: productData,
      isSuccess: productLoadSuccess,
      isError: isProductLoadError,
    },
  ] = useGetProductListMutation();

  const [
    deleteInvoiceById,
    {
      isLoading: isDeleteInvoiceLoading,
      isSuccess: isDeleteInvoiceSuccess,
      isError: isDeleteInvoiceError,
      error: deleteInvoiceError,
    },
  ] = useDeleteInvoiceByIdMutation();

  useEffect(() => {
    if (supplierId) {
      getProductCategories({ supplier_name: String(supplierId) });
    }
  }, [supplierId]);

  // PRODUCT SEARCH
  useEffect(() => {
    setProductList([]);
    const timer = setTimeout(() => {
      getProductList(makeProductPayload({ page: 1 }));
    }, 1000);

    return () => clearTimeout(timer);
  }, [searchKeyword]);

  // SAVING NEW DATA...
  useEffect(() => {
    if (productData) {
      setPagination({
        page: productData.page,
        pageSize: productData.pageSize,
        total: productData.total,
        hasMore:
          productData.page * productData.pageSize < Number(productData.total),
      });

      setProductList((prevState) => {
        if (prevState) {
          const oldState = [...prevState];

          // check if data already exist or not, if exist replace with new data...
          productData.data.forEach((val) => {
            const ind = oldState.findIndex((v) => v.name === val.name);
            if (ind >= 0) oldState.splice(ind, 1, val);
            else oldState.push(val);
          });

          return oldState;
        } else {
          return [...productData.data];
        }
      });
    }
  }, [productLoadSuccess, isProductLoadError]);

  useEffect(() => {
    if (isDeleteInvoiceSuccess) {
      dispatch(setHasInvoiceItem(false));
      dispatch(setSelectedInvoiceId(""));
      navigate(memberRoutes.productSelectionStep.selectSupplier);
    }
    if (isDeleteInvoiceError) {
      console.error(`Error deleting invoice: ${deleteInvoiceError}`);
    }
  }, [isDeleteInvoiceLoading]);

  const handleDeleteModalToggle = () => {
    setIsDeleteModalOpen(!isDeleteModalOpen);
  };

  // PREVENTING RELOAD...
  useEffect(() => {
    const handleTabReloadOrClose = (e: BeforeUnloadEvent) => {
      e.preventDefault();
      if (window.confirm("銷售單內擁有商品，你確定要返回選擇供應商頁面嗎")) {
        // delete invoice
        // handleDeleteInvoice();
        console.log("confirm reload or close");
      } else {
        console.log("cancel reload or close");

        window.history.pushState(null, "null", window.location.pathname);
      }
    };

    // Attach the event listener when the component mounts
    window.addEventListener("beforeunload", handleTabReloadOrClose);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("beforeunload", handleTabReloadOrClose);
    };
  }, []);

  const onBackButtonEvent = (e: any) => {
    e.preventDefault();
    if (hasInvoiceItem) {
      if (window.confirm("銷售單內擁有商品，你確定要返回選擇供應商頁面嗎")) {
        // delete invoice
        handleDeleteInvoice();
      } else {
        window.history.pushState(null, "null", window.location.pathname);
      }
    }
  };

  useEffect(() => {
    if (hasInvoiceItem) {
      window.history.pushState(null, "null", window.location.pathname);
      window.addEventListener("popstate", onBackButtonEvent);
      return () => {
        window.removeEventListener("popstate", onBackButtonEvent);
      };
    }
  }, [hasInvoiceItem]);

  const makeProductPayload = (data?: IGetProductListPayloadOptional) => {
    let obj = {
      page: pagination.page,
      page_size: pagination.pageSize,
      supplier_name: String(supplierId),
      item_group_name: selectedCategory.name,
      // searchQuery: searchKeyword,
      query: searchKeyword ? searchKeyword : undefined,
    } as IGetProductListPayload;

    if (data) {
      obj = { ...obj, ...data };
    }
    return buildValidObject(obj);
  };

  const handleBackNavigation = () => {
    if (hasInvoiceItem) {
      handleDeleteModalToggle();
    } else {
      navigate(-1);
    }
  };

  const handleSelectProduct = (product: ISupplierProductListItem) => {
    setSelectedProductCode(product.name);
    setIsInvoiceEditing(false);

    if (selectedMember && selectedMember.name) {
      setIsProductDetailModalOpen(true);
    } else {
      setIsMemberDialogOpen(true);
    }
  };

  // AUTO LOAD PAGINATED DATA ON SCROLL...
  const observer = useRef<IntersectionObserver | null>(null);
  const lastProductElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isProductListLoading || !node) return;

      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && pagination.hasMore) {
          setPagination((prevState) => {
            return { ...prevState, page: prevState.page + 1 };
          });
          getProductList(makeProductPayload({ page: pagination.page + 1 }));
        }
      });

      observer.current.observe(node);
    },
    [isProductListLoading, pagination.hasMore],
  );

  const handleInvoiceDrawerToggled = () => {
    if (selectedInvoiceId) {
      dispatch(setIsInvoiceDrawerOpen(!isInvoiceDrawerOpen));
    } else {
      window.alert("Invoice is not created");
    }
  };

  const handleGoBack = (url: string) => {
    setIsMemberDialogOpen(false);
    navigate(url, { replace: true });
  };

  const handleCategorySelection = (data: ISelectedCategory) => {
    // resetting states...
    setProductList([]);
    const paginateData = {
      ...pagination,
      page: 1,
      hasMore: false,
      total: undefined,
    };
    setPagination(paginateData);

    setSelectedCategory(data);
    getProductList(
      makeProductPayload({
        item_group_name: data.name,
        page: paginateData.page,
        page_size: paginateData.pageSize,
      }),
    );
  };

  const handleProductDetailModalToggle = (val?: boolean) => {
    setIsProductDetailModalOpen(
      String(val) ? Boolean(val) : !isProductDetailModalOpen,
    );
  };

  const handleDeleteInvoice = () => {
    deleteInvoiceById({ invoiceId: selectedInvoiceId });
  };

  return (
    <RootLayout>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <StyledHeaderBackButton
          variant="outlined"
          startIcon={<ChevronLeft />}
          onClick={handleBackNavigation}
        >
          返回
        </StyledHeaderBackButton>

        <Box>
          <ProductSelectionStepBreadcrumb active={2} />
        </Box>

        <Button
          variant="contained"
          onClick={handleInvoiceDrawerToggled}
          disabled={selectedInvoiceId ? false : true}
          sx={{ px: 3.125, py: 1.25 }}
        >
          銷售單
        </Button>
      </Box>

      <DefaultLoaderContainer isLoading={isCategoryLoading}>
        <ul className="product-categories">
          {categoriesData?.data?.map((category, index: number) => (
            <li
              key={`category-item-${index}`}
              className={
                selectedCategory.item_group_name === category.item_group_name
                  ? "active"
                  : ""
              }
              onClick={() =>
                handleCategorySelection({
                  item_group_name: category.item_group_name,
                  name: category.name,
                })
              }
            >
              {category.item_group_name}
            </li>
          ))}
        </ul>

        <Box className="page__content product__list__page">
          <MuiTextField
            fullWidth
            placeholder="搜尋產品名稱或產品代碼"
            value={searchKeyword}
            onChange={(e) => {
              setPagination((prev) => {
                return { ...prev, page: 1, hasMore: false };
              });
              setSearchKeyword(e.target.value);
            }}
          />

          <Box>
            <Box className="page__product-list-wrapper">
              {productList.map((product, index) => {
                if (productList.length === index + 1) {
                  return (
                    <Box
                      key={`product-item-${index}`}
                      ref={lastProductElementRef}
                    >
                      <ProductItem
                        product={product}
                        handleSelectProduct={handleSelectProduct}
                      />
                    </Box>
                  );
                } else {
                  return (
                    <Box key={`product-item-${index}`}>
                      <ProductItem
                        product={product}
                        handleSelectProduct={handleSelectProduct}
                      />
                    </Box>
                  );
                }
              })}
            </Box>

            {isProductListLoading && (
              <Typography mt={2} variant="h6" fontWeight={700}>
                載入中...
              </Typography>
            )}

            {!isProductListLoading && !productList.length && (
              <Box>沒有資料</Box>
            )}
          </Box>
        </Box>
      </DefaultLoaderContainer>

      <ProductDetailModal
        isModalOpen={isProductDetailModalOpen}
        handleModalClose={() => {
          handleProductDetailModalToggle(false);
          setIsInvoiceEditing(false);
        }}
        selectedProductCode={selectedProductCode}
        isEditing={isInvoiceEditing}
      />

      <InvoiceDrawer
        isDrawerOpen={isInvoiceDrawerOpen}
        toggleDrawer={handleInvoiceDrawerToggled}
        handleEditInvoiceItem={(itemData) => {
          setIsInvoiceEditing(true);
          setSelectedProductCode(itemData.parent_item_code ?? itemData.name);
          handleProductDetailModalToggle(true);
        }}
      />

      <ConfirmInvoiceDeleteDialog
        isModalOpen={isDeleteModalOpen}
        title="銷售單內擁有商品，你確定要返回選擇供應商頁面嗎?"
        content="**如按確定的話，系統會自動清空銷售單內的商品"
        handleModalClose={handleDeleteModalToggle}
        handleDelete={handleDeleteInvoice}
      />

      <Dialog
        open={isMemberDialogOpen}
        aria-labelledby="member-selection-dialog"
      >
        <DialogTitle id="member-selection-dialog-title">
          Member Is Not Selected
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="member-selection-dialog-description">
            Without selecting Member we cannot select products. <br />
            So, Please select Member First
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => handleGoBack("/")}>
            Go To Member Selection
          </Button>
        </DialogActions>
      </Dialog>
    </RootLayout>
  );
};

export default SelectProductStep;

interface ISelectedCategory {
  name: string;
  item_group_name: string;
}

interface IProductPagination {
  page: number;
  pageSize: number;
  total?: number;
  hasMore: boolean;
}
