const memberBRoute = "/member";
const productSelectionStep = `${memberBRoute}/product-selection-steps`;

const memberRoutes = {
  base: memberBRoute,
  createMember: `${memberBRoute}/create`,
  editMember: (id: string) => `${memberBRoute}/${id}/edit`,

  // PRODUCT SELECTION STEPS...
  productSelectionStep: {
    selectSupplier: `${productSelectionStep}/select-supplier`,
    selectProduct: (supplierId: string) =>
      `${productSelectionStep}/select-product?supplierId=${supplierId}`,
    couponInfo: `${productSelectionStep}/coupon-info`,
    shippingInfo: `${productSelectionStep}/shipping-info`,
    paymentInfo: `${productSelectionStep}/payment-info`,
    checkInfo: `${productSelectionStep}/check-info`,
  },

  //   // Orders Routes...
  //   order: {
  //     base: order,
  //     addNew: `${order}/add-new`,
  //     dynamicId: (id: string) => `${order}/${id}`,

  //     orderItem: {
  //       base: orderItem,
  //       addNew: `${orderItem}/add-new`,
  //       dynamicId: (id: string) => `${orderItem}/${id}`,
  //     },
  //   },

  //   // Payment Routes...
  //   payment: {
  //     base: payment,
  //     addNew: `${payment}/add-new`,
  //     dynamicId: (id: string) => `${payment}/${id}`,
  //   },
};

export default memberRoutes;
