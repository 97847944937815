import { Box, Dialog, Typography, Button } from "@mui/material";

// ============================ || INVOICE TRANSFER CONFIRM DIALOG COMPONENT || ====================================//
const InvoiceTransferConfirmDialog = (
  props: InvoiceTransferConfirmDialogProps,
) => {
  const { handleModalClose, open, sourceInvoiceId, targetInvoiceId } = props;

  return (
    <Dialog
      aria-labelledby="invoice-transfer-confirm-dialog"
      maxWidth="sm"
      open={open}
      fullWidth
    >
      <Box p={5}>
        <Typography textAlign={"center"} fontSize={20} fontWeight={600}>
          你確定 舊單 ({sourceInvoiceId})轉單到 新單 ({targetInvoiceId})嗎?
        </Typography>

        <Box
          display={"flex"}
          justifyContent={"center"}
          columnGap={2.5}
          color="grey.500"
          mt={"30px"}
        >
          <Box color="gray.500">
            <Button
              variant="outlined"
              color="inherit"
              onClick={() => handleModalClose(false)}
              sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            >
              返回
            </Button>
          </Box>

          <Button
            variant="contained"
            onClick={() => handleModalClose(true)}
            sx={{ py: "10px", px: "15px", minWidth: 185, minHeight: 60 }}
            color="primary"
          >
            確定
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default InvoiceTransferConfirmDialog;

export interface InvoiceTransferConfirmDialogProps {
  handleModalClose: (isConfirm: boolean) => void;
  open: boolean;
  sourceInvoiceId: string;
  targetInvoiceId: string;
}
