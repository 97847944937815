import React, { FC } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import defaultTheme from "./default.theme";

const DefaultThemeProvider: FC<DefaultThemeProviderProps> = ({ children }) => {
  return (
    <ThemeProvider theme={defaultTheme}>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default DefaultThemeProvider;

// TYPE DECLERATION...
export interface DefaultThemeProviderProps {
  children: React.ReactElement | React.ReactElement[];
}
