import { styled } from "@mui/material/styles";
import Button, { ButtonProps } from "@mui/material/Button";

export const StyledHeaderBackButton = styled(Button)<ButtonProps>(
  ({ theme }) => ({
    padding: "14px 25px",
    color: "#5A6070",
    backgroundColor: theme.palette.common.white,
    borderColor: "#5A6070",

    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  }),
);
