import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ENV_CONFIG } from "src/config/env.config";
import { USER_RELATED_KEYS } from "src/constants/keys/local-storage.keys";
import { IAPIBasePaginatedReqQuery } from "src/interfaces/api-request.interfaces";
import {
  IAPIPaginatedRes,
  IAPIResponse,
} from "src/interfaces/api-response.interfaces";
import {
  IProductCategory,
  IProductDetail,
} from "src/interfaces/product.interfaces";
import { ISupplierList } from "src/interfaces/suppliers";
import { ISupplierProductsResponse } from "src/interfaces/suppliers/product.interfaces";
import { handleLocalStorage } from "src/utils/common";

// SUPPLIERS AND THEIR PRODUCTS APIS...
export const suppliersAndProductApi = createApi({
  reducerPath: "suppliersAndProductsApi",
  baseQuery: fetchBaseQuery({
    baseUrl: `${ENV_CONFIG.API_URL}/`,
    credentials: "include",
    prepareHeaders: async (headers) => {
      const AuthKey = handleLocalStorage.getValue(USER_RELATED_KEYS.AUTH_KEY);
      if (AuthKey) {
        headers.set("Authorization", AuthKey);
      }
      return headers;
    },
  }),

  endpoints: (builder) => ({
    getSuppliers: builder.mutation<
      IAPIPaginatedRes<ISupplierList[]>,
      IAPIBasePaginatedReqQuery
    >({
      query: (params) => {
        // const { query: keyword } = args;

        // const or_filters = [
        //   ["name", "like", `%${keyword}%`],
        //   ["supplier_name", "like", `%${keyword}%`],
        // ];
        // let params: any = {};

        // if (keyword) {
        //   params["query"] = keyword;
        // }

        return {
          url: "method/get_suppliers",
          method: "GET",
          params,
        };
      },
    }),

    getProductCategories: builder.mutation<
      IAPIResponse<Array<IProductCategory>>,
      IGetProductCategoriesPayload
    >({
      query: (params) => {
        return {
          url: "method/get_item_groups",
          params,
        };
      },
    }),

    getProducts: builder.mutation({
      query: (args) => {
        const { supplier, item_group, keyword, limit, start } = args;

        const filters = [
          ["supplier", "=", supplier],
          ["item_group", "=", item_group],
        ];
        const or_filters = [
          ["name", "like", `%${keyword}%`],
          ["item_name", "like", `%${keyword}%`],
        ];

        let params = {
          fields: '["*"]',
          filters: JSON.stringify(filters),
          or_filters: JSON.stringify(or_filters),
          limit_page_length: limit,
          limit_start: start || 0,
        };

        return {
          url: "resource/Item",
          params,
        };
      },
    }),

    getProductList: builder.mutation<
      ISupplierProductsResponse,
      IGetProductListPayload
    >({
      query: (params) => {
        return {
          url: "/method/get_supplier_items",
          params,
        };
      },
      // transformResponse: (response: any) => response,
    }),

    getProductDetail: builder.mutation<
      IAPIResponse<IProductDetail>,
      IGetProductDetailApiPayload
    >({
      query: (params) => {
        return {
          url: "method/get_item_with_attributes",
          params,
        };
      },
    }),
  }),
});

export const {
  useGetSuppliersMutation,
  useGetProductCategoriesMutation,
  useGetProductsMutation,
  useGetProductListMutation,
  useGetProductDetailMutation,
} = suppliersAndProductApi;

// TYPE DECLERATION...
export interface IGetProductListPayload {
  supplier_name: string;
  item_group_name?: string;
  page: number;
  page_size: number;
  query?: string;
}

export interface IGetProductListPayloadOptional
  extends Partial<IGetProductListPayload> {}

export interface IGetProductDetailApiPayload {
  item_name: string;
  invoice_name?: string;
}

interface IGetProductCategoriesPayload {
  supplier_name: string;
}
