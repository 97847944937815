import { configureStore } from "@reduxjs/toolkit";
import { authApi } from "./api/authApi";
import { userApi } from "./api/userApi";
import userReducer from "./features/userSlice";
import memberReducer from "./features/memberSlice";
import invoiceReducer from "./features/invoiceSlice";
import { memberApi } from "./api/memberApi";
import { salesAndInvoiceApi } from "./api/saleAndInvoiceApi";
import { suppliersAndProductApi } from "./api/suppliersAndProductsApi";

export const store = configureStore({
  reducer: {
    [authApi.reducerPath]: authApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [memberApi.reducerPath]: memberApi.reducer,
    [salesAndInvoiceApi.reducerPath]: salesAndInvoiceApi.reducer,
    [suppliersAndProductApi.reducerPath]: suppliersAndProductApi.reducer,

    // states...
    userState: userReducer,
    memberState: memberReducer,
    invoiceState: invoiceReducer,
  },

  devTools: process.env.NODE_ENV === "development",

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({}).concat([
      authApi.middleware,
      userApi.middleware,
      memberApi.middleware,
      salesAndInvoiceApi.middleware,
      suppliersAndProductApi.middleware,
    ]),
});
