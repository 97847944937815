import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const defaultTheme = createTheme({
  palette: {
    // primary: {
    //   main: "#556cd6",
    // },
    secondary: {
      main: "#45A492",
    },
    // error: {
    //   main: red.A400,
    // },
    text: {
      primary: "#333",
    },
  },
  typography: {
    fontFamily: "'Noto Sans', sans-serif",
  },
});

export default defaultTheme;
