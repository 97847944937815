import { Box, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { useEffect } from "react";
import { SalesPersonFields } from "src/pages/member/member-product-selection-steps/step3-coupon-info";
import { CustSelect } from "../../form-components/FormSelect";
import { useGetSalePersonListMutation } from "src/redux/api/saleAndInvoiceApi";

const SelectSalePerson = (props: SelectSalePersonProps) => {
  const { salesPersonFields, handleSalesPersonChange } = props;

  // REDUX THINGS...
  const [getSalePersonList, { data: salePersonRes }] =
    useGetSalePersonListMutation();

  // handle initial call...
  useEffect(() => {
    getSalePersonList({});
  }, []);

  return (
    <Box aria-label="sale-person-info">
      <Stack
        direction="row"
        spacing={1}
        py="20.5px"
        px="30px"
        borderRadius="5px 5px 0 0"
        bgcolor="#5A6070"
        width="fit-content"
        color="#fff"
        alignItems="center"
      >
        <Box display="flex" justifyContent="center" alignItems="center">
          <img
            src="/icons/sale-girl.svg"
            alt="sale-girl"
            width={24}
            style={{ objectFit: "contain" }}
          />
        </Box>
        <Typography fontSize={18} fontWeight={700}>
          銷售員信息
        </Typography>
      </Stack>

      <Box
        padding="20px 20px 30px 20px"
        borderRadius="0 5px 5px 5px"
        border="1px solid #E0E0E0"
        bgcolor="#fff"
      >
        <Grid container spacing={5}>
          <Grid item xs={12} md={6}>
            <CustSelect
              label="主銷售員*"
              name="primary_sales_person"
              placeholder="請選擇"
              value={salesPersonFields.primary_sales_person}
              onChange={(e: any) =>
                handleSalesPersonChange("primary_sales_person", e.target.value)
              }
              wrapperStackProps={{
                sx: {
                  "& .select_label": {
                    fontSize: 12,
                    color: "#333",
                    fontWeight: 700,
                  },
                },
              }}
            >
              {salePersonRes &&
                salePersonRes.data.map((sp, index: number) => {
                  return (
                    <MenuItem
                      key={`birthday-month-${index}`}
                      value={sp.sales_person_name}
                    >
                      {sp.sales_person_name}
                    </MenuItem>
                  );
                })}
            </CustSelect>
          </Grid>

          <Grid item xs={12} md={6}>
            <CustSelect
              label="其他銷售員*"
              name="other_sales_person"
              placeholder="請選擇"
              value={salesPersonFields.other_sales_person}
              onChange={(e: any) =>
                handleSalesPersonChange("other_sales_person", e.target.value)
              }
              wrapperStackProps={{
                sx: {
                  "& .select_label": {
                    fontSize: 12,
                    color: "#333",
                    fontWeight: 700,
                  },
                },
              }}
            >
              {salePersonRes &&
                salePersonRes.data.map((sp, index: number) => {
                  return (
                    <MenuItem
                      key={`birthday-month-${index}`}
                      value={sp.sales_person_name}
                    >
                      {sp.sales_person_name}
                    </MenuItem>
                  );
                })}
            </CustSelect>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SelectSalePerson;

export interface SelectSalePersonProps {
  salesPersonFields: SalesPersonFields;
  handleSalesPersonChange: (
    name: keyof SalesPersonFields,
    value: string,
  ) => void;
  // selectedSalePersons: Array<ISelectedSalePersonInInvoice>;
}
